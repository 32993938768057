/* eslint-disable */
/*
 * File: ConsentFrom.jsx
 * Project: Machine Trust Platform
 * File Created: Wednesday, 26th May 2021 12:18:11 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Tuesday, 26th October 2021 11:13:00 am
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React, { useState, useEffect } from 'react';
import { useDispatch, batch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import { User } from '../types';

import { updateUserConsent, logout } from '../redux/actions';
import { NuEnergyBase64Logo } from '../components/Icons';
import { USER } from '../constants';

const ConsentFrom = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [acceptEnabled, setApprovedEnabled] = useState(false);
  const [checkbox, setCheckbox] = useState(false);

  // Used later to check if the consent form has been completed atm does nothing
  // eslint-disable-next-line
  const [completedConsentFrom, _] = useState(true);

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheckbox(e.target.checked);
  };
  const acceptConsent = () => {
    console.log('1');    dispatch(updateUserConsent(USER.CONSENT_FORM.ACCEPTED));
  };

  const declineConsent = () => {
    batch(() => {
      dispatch(updateUserConsent(USER.CONSENT_FORM.DECLINED));
      dispatch(logout());
    });
  };

  useEffect(() => {
    setApprovedEnabled(checkbox && completedConsentFrom);
  }, [checkbox, completedConsentFrom]);

  return (
    <div className="consent">
      <div className="consent__header">
        <NuEnergyBase64Logo width={102} height={30} />

        {/* <h1>
          {t("terms.and.conditions.label")}
        </h1> */}
      </div>

      <div className="consent__content">
        <p>{t('follow.link.terms.conditions.message')}</p>
        <a href="https://mtp-software-agreement.s3.ca-central-1.amazonaws.com/SW+NuEnergy+agreement.pdf" target="_blank" rel="noreferrer">
          {t('terms.and.conditions.label')}
        </a>
      </div>
      <form className="consent__footer">
        <div className="form-group form-check">
          <input type="checkbox" className="form-check-input" defaultChecked={checkbox} onChange={handleCheckbox} id="exampleCheck1" />
          <label className="form-check-label" htmlFor="exampleCheck1">
            {t('confirm.read.understand.agree.msg')}
          </label>
        </div>

        <div className="consent__btns">
          <button type="button" className="btn btn-primary shadow-br" disabled={!acceptEnabled} onClick={acceptConsent}>
            {t('accept.label')}
          </button>

          <button type="button" className="consent__decline btn btn-secondary shadow-br" onClick={declineConsent}>
            {t('decline.label')}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ConsentFrom;
