/* eslint-disable camelcase */

/*
 * File: AIGSScoresContainer.jsx
 * Project: mtp-webui
 * File Created: Friday, 23rd April 2021 1:40:14 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Monday, 13th September 2021 9:18:48 am
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import TabbedContainer from './TabbedContainer';
import { getColorMap } from '../../utils/visualizations';
import AsterChart from '../charts/AsterChart';
import { SCORECARD } from '../../constants';
import ComingSoon from './ComingSoon';
import Benchmark from '../charts/Benchmark';

/**
 *
 * @param {Object} SelfAssessmentData - object containing
 * @returns
 */
const ScorecardScoresContainer = (props) => {
  const { scorecard, scoresInfo } = props;
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);

  const mostRecentScorecardScore =
    scorecard && scoresInfo && scoresInfo.list
      ? scoresInfo.list.filter((s) => s.state === SCORECARD.SCORE.STATE.APPROVED)[0] || null // make sure the scorecard has answers (later change this to Complete)
      : null;

  const data =
    mostRecentScorecardScore && mostRecentScorecardScore.requirements
      ? mostRecentScorecardScore.requirements.map((requirement_score) => ({
          name: requirement_score.requirement.name,
          score: requirement_score.score >= 0 ? requirement_score.score : 0,
          weight: requirement_score.weight,
        }))
      : null;
  const overallScore = mostRecentScorecardScore ? mostRecentScorecardScore.score : null;
  const requirements = mostRecentScorecardScore && mostRecentScorecardScore.requirements ? mostRecentScorecardScore.requirements.map((r) => r.requirement.name) : null;

  const type = scorecard && scorecard.type ? scorecard.type.toLowerCase() : '';

  const content = [
    {
      tab: t('self.assessment.label'),
      jsx: <SelfAssessmentContent data={data} overallScore={overallScore} requirements={requirements} scorecardUuid={scorecard ? scorecard.uuid : undefined} scoreUuid={mostRecentScorecardScore ? mostRecentScorecardScore.uuid : undefined} type={type} loading={loading} />,
    },
  ];
  // if(mostRecentScorecardScore) {
  //   content.push(       {
  //     tab: t('recommendations.label'),
  //     jsx: <Recommendations {...recommendationsData}/>

  //   })
  // }
  content.push({
    tab: t('benchmark.label'),
    jsx: <Benchmark type={scorecard.type} score={Math.round(overallScore)} />,
  });
  content.push({
    tab: t('reviewer.label'),
    jsx: <ComingSoon description="" />,
  });

  useEffect(() => {
    if (scoresInfo && scoresInfo.lastFetchTime !== -1) {
      setLoading(false);
    }
  }, [scoresInfo, scorecard]);

  return <TabbedContainer content={content} />;
};

export default ScorecardScoresContainer;

const SelfAssessmentContent = (props) => {
  const { data, overallScore, requirements, type, loading, scorecardUuid, scoreUuid } = props;
  const { t } = useTranslation();
  const { path } = useRouteMatch();

  const colorMap = requirements ? getColorMap(requirements) : null;

  /* eslint-disable prefer-template */
  const linkToResults = `${path}${type === 'aims' ? '/' + type : ''}/questionnaire/results?scorecard=${scorecardUuid}&score=${scoreUuid}`;

  return (
    <>
      <div className="scorecard-self-assessment">
        <span className="score-header">{`${type.toUpperCase()} ${t('score.label')}`}</span>
        {/* eslint-disable no-nested-ternary  */}
        {loading ? (
          <Spinner size="lg" />
        ) : data && overallScore && requirements ? (
          <>
            <div className="scorecard-self-assessment__chart">
              <AsterChart width={200} height={200} dataset={data} overallScore={overallScore} categories={requirements} />
            </div>
            <div className="scorecard-self-assessment__legend">
              <ul>
                {data.map((r) => (
                  <li key={r.name} className={colorMap[r.name][1]}>
                    <div className="r-label">
                      <div className="r-header">{r.name}</div>
                      <span>{`${Math.round(r.score)}%`}</span>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="scorecard-self-assessment__footer">
                <Link className="btn btn-primary" to={linkToResults}>
                  {t('review.results.label')}
                </Link>
              </div>
            </div>
          </>
        ) : (
          <div className="scorecard-self-assessment__empty">{t(`please.complete.${type}.message`)}</div>
        )}
      </div>
    </>
  );
};
