export const getNameOfMonthFromTimeStamp = (timestamp) => {
  const month = timestamp.getMonth();
  switch (month) {
    case 0:
      return 'January';
    case 1:
      return 'February';
    case 2:
      return 'March';
    case 3:
      return 'April';
    case 4:
      return 'May';
    case 5:
      return 'June';
    case 6:
      return 'July';
    case 7:
      return 'August';
    case 8:
      return 'September';
    case 9:
      return 'October';
    case 10:
      return 'November';
    default:
      return 'December';
  }
};

export const createDateLabel = (date) => {
  return `${date.getFullYear()}-${getNameOfMonthFromTimeStamp(date).substring(0, 3)}-${date.getDate()}`;
};

export const createDateTimeLabel = (date) => {
  // date = date - date.getTimezoneOffset();
  return `${getNameOfMonthFromTimeStamp(date).substring(0, 3)} ${date.getDate()} ${date.getHours()}:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`;
};

/**
 * 
 * @param interval 
 * @return {Object} 

 */
export const getLineChartInterval = (interval) => {
  if (interval === 'All') return { startDateTime: null, endDateTime: null };
  const startDateTime = new Date();
  const endDateTime = new Date();
  // week intervals
  if (interval && interval.charAt(1) === 'W') {
    endDateTime.setDate(endDateTime.getDate() - 7 * parseInt(interval.charAt(0), 10));
  } else {
    // month intervals
    endDateTime.setMonth(startDateTime.getMonth() - parseInt(interval.charAt(0), 10));
    if (endDateTime.getMonth() === startDateTime.getMonth()) endDateTime.setDate(0);
  }

  // Zero Hours and Minutes
  endDateTime.setHours(0, 0, 0);
  endDateTime.setMilliseconds(0);

  // console.log(`Start: ${startDateTime} \n End: ${endDateTime}`)

  // endDateTime = toYYYYMMDDHHMMSS(endDateTime)
  // startDateTime = toYYYYMMDDHHMMSS(startDateTime)

  return { startDateTime, endDateTime };
};

export const toYYYYMMDDHHMMSS = (date) => {
  const pad = (number, length) => {
    let str = `${number}`;
    while (str.length < length) {
      str = `0${str}`;
    }
    return str;
  };

  const yyyy = date.getFullYear().toString();
  const MM = pad(date.getMonth() + 1, 2);
  const dd = pad(date.getDate(), 2);
  const hh = pad(date.getHours(), 2);
  const mm = pad(date.getMinutes(), 2);
  const ss = pad(date.getSeconds(), 2);

  return yyyy + MM + dd + hh + mm + ss;
};
