/* eslint-disable */
const roleOrder = (role) => {
  switch (role) {
    case 'admin':
      return 1;
    case 'executive':
      return 2;
    case 'developer':
      return 3;
    case 'scientist':
      return 4;
    case 'integrator':
      return 5;
    default:
      return 6;
  }
};

export { roleOrder };
