/**
 * File: index.js
 * Project: mtp-webui
 * File Created: Wednesday, 5th May 2021 10:32:30 am
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Thursday, 13th May 2021 7:35:36 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai>)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React, { useMemo } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Navigation from './navigation';

import AIGS from './AIGS.route';
import Project from './Project.route';
import Tutorials from '../components/Tutorials';
import Standards from './Standards.route';
import Tools from './Tools.route';
import MTI from './MTI.route';
import type { RootState } from '../redux/store';
import { OrganizationTier } from '../types';

const Routes = () => {
  const location = useLocation();
  const tier = useSelector<RootState>((state) => state.user?.organization?.tier) as OrganizationTier;

  const nav = useMemo(() => new Navigation(tier), [tier]);

  return (
    <>
      {location.pathname === '/' ? <Redirect to="/aigs" /> : null}

      <Tutorials />
      <nav.Bar />

      <div className="page-wrapper container-fluid">
        <div className="row">
          <nav.Drawer />
          <main role="main" id="main" className="col main">
            <nav.Services />
            <Switch>
              <Route path={Navigation.Path.AIGS} component={AIGS} />
              <Route path={Navigation.Path.PROJECTS} component={Project} />
              <Route path={Navigation.Path.STANDARDS} component={Standards} />
              <Route path={Navigation.Path.TOOLS} component={Tools} />
              <Route path={Navigation.Path.MTI} component={MTI} />
            </Switch>
          </main>
        </div>
      </div>
    </>
  );
};

export default Routes;
