import React from 'react';
import { useDispatch } from 'react-redux';
import { useTimer } from 'react-timer-hook';
import { useTranslation } from 'react-i18next';
import { cancelForcedLogout } from '../../redux/actions';
import { INACTIVITY_TIMEOUT } from '../../constants';

const IdleLogout = (props) => {
  const dispatch = useDispatch();
  const { expiryTimestamp } = props;
  const { t } = useTranslation();

  const { seconds, minutes } = useTimer({
    expiryTimestamp,
  });

  return (
    <div className="idle-logout-background">
      <div className="card">
        <div className="card-body">
          <p>
            {t('session.inactivity.warning', {
              minutes: INACTIVITY_TIMEOUT / 1000 / 60,
            })}
          </p>
          <p>
            {t('session.logout.countdown.label', {
              countdown: `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`,
            })}
          </p>
          <button type="button" className="btn btn-md" onClick={() => dispatch(cancelForcedLogout())}>
            {t('continue.label')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default IdleLogout;
