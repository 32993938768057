import React from 'react';
import PropTypes from 'prop-types';

type CircleIconProps = {
  iconClass: string;
  className?: string;
  reverse?: boolean;
};

const CircleIcon = ({ iconClass, className = '', reverse = false }: CircleIconProps): JSX.Element => {
  return (
    <div className={`${reverse ? 'circle-icon-reverse' : 'circle-icon'} ${className}`}>
      <i className={iconClass} />
    </div>
  );
};

CircleIcon.propTypes = {
  iconClass: PropTypes.string,
  className: PropTypes.string,
  reverse: PropTypes.bool,
};
CircleIcon.defaultProps = {
  iconClass: '',
  className: '',
  reverse: false,
};

export default CircleIcon;
