/*
 * File: TabedContainer.jsx
 * Project: mtp-webui
 * File Created: Friday, 23rd April 2021 12:10:14 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Saturday, 28th August 2021 5:20:52 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
import React, { useState } from 'react';
import { useSprings, animated } from '@react-spring/web';
import { useMeasure } from '../../hooks';

// type Tab = {
//     tab: string,
//     jsx: React.ReactNode,
// }

// type TabbedContainerProps = {
//   content: Tab[]
// }

const TabbedContainer = ({ content }) => {
  const tabs = content.filter((c) => c && c.tab);

  const [activeTab, setActiveTab] = useState(tabs[0].tab);

  // Used to make sure te container fits all tabs
  const [bind, { width: tabsWidth }] = useMeasure();

  const [props, api] = useSprings(tabs.length, (i) => ({
    opacity: tabs[i] && tabs[i].tab === activeTab ? 1 : 0,
    position: tabs[i] && tabs[i].tab === activeTab ? 'static' : 'absolute',
    display: tabs[i] && tabs[i].tab === activeTab ? 'block' : 'none',
  }));

  api.start((i) => {
    return {
      opacity: tabs[i] && tabs[i].tab === activeTab ? 1 : 0,
      position: tabs[i] && tabs[i].tab === activeTab ? 'static' : 'absolute',
      display: tabs[i] && tabs[i].tab === activeTab ? 'block' : 'none',
    };
  });

  const handleTabSelection = (e, data) => {
    setActiveTab(data);
    e.preventDefault();
  };

  const handleKeyDown = (e, index, data) => {
    if (e.keycode === index) setActiveTab(data);
    e.preventDefault();
  };

  return (
    <div className="tabs-content-container" style={{ width: tabsWidth + 16 }}>
      {/* eslint-disable react/jsx-props-no-spreading */}
      <ul className="tabs" {...bind}>
        {tabs.map((c, i) => (
          <li key={c.tab} className={`${activeTab === c.tab ? 'active' : ''}`}>
            <a role="button" key={c.tab} tabIndex={0} onClick={(e) => handleTabSelection(e, c.tab)} onKeyDown={(e) => handleKeyDown(e, i, c.tab)}>
              {c.tab}
            </a>
          </li>
        ))}
      </ul>
      <div className="tabs-content">
        {props.map((styles, i) => (
          <animated.div className="content-display" key={tabs[i].tab} style={styles}>
            {tabs[i].jsx}
          </animated.div>
        ))}
      </div>
    </div>
  );
};

export default TabbedContainer;
