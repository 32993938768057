/*
 * File: AIA.jsx
 * Project: Machine Trust Platform
 * File Created: Tuesday, 27th July 2021 12:56:01 pm
 * Author: Rafal Rybak (rafal.rybak@nuenergy.ai)
 * -----
 * Last Modified: Wednesday, 25th August 2021 2:35:18 pm
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
import React from 'react';
import ConfigureMessage from '../../components/container/ConfigureMessage';

const ISO = () => {
  return <ConfigureMessage />;
};

export default ISO;
