/*
 * File: WikiDemo.jsx
 * Project: Machine Trust Platform
 * File Created: Tuesday, 27th July 2021 1:45:30 pm
 * Author: Rafal Rybak (rafal.rybak@nuenergy.ai)
 * -----
 * Last Modified: Friday, 10th September 2021 9:15:39 am
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
import React from 'react';
// import DemoContainer from '../../components/container/DemoContainer';
import ConfigureMessage from '../../components/container/ConfigureMessage';

const WikiDemo = () => {
  return (
    <ConfigureMessage />
    // <DemoContainer>
    //   <a href="https://www.nuenergy.ai/technology-transparency-survey/" target="_blank" rel="noopener noreferrer">
    //     Trust Wiki Demo
    //   </a>
    // </DemoContainer>
  );
};

export default WikiDemo;
