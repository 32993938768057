/*
 * File: Tools.route.jsx
 * Project: Machine Trust Platform
 * File Created: Tuesday, 27th July 2021 12:49:41 pm
 * Author: Rafal Rybak (rafal.rybak@nuenergy.ai)
 * -----
 * Last Modified: Friday, 20th August 2021 12:00:57 pm
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Tools } from '../pages';

const ToolsRoute = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`} exact component={Tools.TrustTools} />
      <Route path={`${path}/Fairness360`} exact component={Tools.Fairness360} />
      <Route path={`${path}/Aequitas`} exact component={Tools.Aequitas} />
      <Route path={`${path}/Fairlearn`} exact component={Tools.Fairlearn} />
      <Route path={`${path}/Lime`} exact component={Tools.Lime} />
      <Route path={`${path}/Whatif`} exact component={Tools.Whatif} />
    </Switch>
  );
};

export default ToolsRoute;
