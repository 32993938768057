/*
 * File: Canvas.jsx
 * Project: Machine Trust Platform
 * File Created: Saturday, 31st July 2021 8:47:35 am
 * Author: Rafal Rybak (rafal.rybak@nuenergy.ai)
 * -----
 * Last Modified: Saturday, 28th August 2021 10:45:24 pm
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * Last Modified: Saturday, 28th August 2021 10:45:24 pm
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Survey, Model, StylesManager } from 'survey-react';
import { SurveyPDF } from 'survey-pdf';
import { CircleIcon, NuEnergyBase64Logo } from '../../components/Icons';
import { getNameOfMonthFromTimeStamp } from '../../utils/timeUtils';
import { getCanvasAnswers, cacheCanvasAnswers } from '../../utils/localStorage/surveyLocalStorage';

const Canvas = () => {
  const { t } = useTranslation();

  return (
    <div className="scorecard-results survey-container" data-tut="canvas">
      <div className="scorecard-results__nuenergy-logo">
        <NuEnergyBase64Logo width={102} height={30} />
      </div>
      <div id="scorecard-title" className="scorecard-results__title">
        <CircleIcon iconClass="fas fa-project-diagram" reverse />
        <h1>{t('canvas.questionnaire.title')}</h1>
      </div>
      <CanvasContainer />
    </div>
  );
};

const CanvasContainer = () => {
  const { t } = useTranslation();

  StylesManager.applyTheme('bootstrap');

  const onSavePDFClicked = () => {
    // Where do these values come from? A4 paper size in mm.
    const pdfWidth = surveyModel.pdfWidth || 210;
    const pdfHeight = surveyModel.pdfHeight || 297;

    const date = new Date();
    const filename = `Canvas-Questionnaire-${getNameOfMonthFromTimeStamp(date).substring(0, 3)}-${date.getDate()}.pdf`;

    const options = {
      fontSize: 14,
      margins: {
        left: 12,
        right: 12,
        top: 12,
        bot: 12,
      },
      format: [pdfWidth, pdfHeight],
    };

    const surveyPDF = new SurveyPDF(surveyJSON, options);
    surveyPDF.data = surveyModel.data;

    // Here we render the filename in the margin at the bottom. Until the license fee is
    // paid the margin at the top is taken by the commercial message.
    //
    // There are examples on the SurveyJS website showing how to render logos in that space:
    // https://surveyjs.io/Examples/Pdf-Export?id=survey-pdf-header&platform=Reactjs&theme=modern#content-js
    surveyPDF.onRenderFooter.add((_, canvas) => {
      canvas.drawText({
        text: filename,
        fontSize: 10,
      });
    });

    surveyPDF.save(filename);
  };

  const onSurveyAfterPageRender = () => {
    surveyModel.data = getCanvasAnswers();
  };

  const onSurveyValueChanged = (model) => {
    if (Object.keys(model.data).length > 0) {
      cacheCanvasAnswers(model.data);
    }
  };

  // The survey json source contains links to (three at them moment) banner images that are
  // illustrative in nature. The easiest solution would be to link to public versions of
  // these images published at NuEnergy.ai production site.
  //
  // However, at the moment, we are linking to the versions of these images hosted by the
  // webserver delivering the application. These are dynamic URLs so we need to replace
  // some placeholder elements stored in the json itself. There are two options:
  //
  // 1. Add an element of type 'html' and then add a href link. This is nice is some ways
  // as it also allows us to set the title/alt attributes on the images which show up as
  // the user hovers over the images. Here is some code that does this (the placeholders
  // themselves would have to be added):
  //
  // const textTrustCategories = t('canvas.img.trust-categories');
  // const textGeneratingQuestions = t('canvas.img.generating-questions');
  // const textAppliedToolsAndMeasurements = t('canvas.img.applied-tools-and-measurements');
  // surveyJSON.elements[5].html = `<img src="${process.env.PUBLIC_URL}/images/canvas-trust-categories.png" title="${textTrustCategories}" alt="${textTrustCategories}" width="600" />`;
  // surveyJSON.elements[8].html = `<img src="${process.env.PUBLIC_URL}/images/canvas-generating-questions.png" title="${textGeneratingQuestions}" alt="${textGeneratingQuestions}" width="600" />`;
  // surveyJSON.elements[10].html = `<img src="${process.env.PUBLIC_URL}/images/canvas-applied-tools-and-measurements.png" title="${textAppliedToolsAndMeasurements}" alt="${textAppliedToolsAndMeasurements}" width="600" />`;
  //
  // By the way, the reason to add both title and alt attributes is because there's a
  // long-standing bug where alt text does not show up in WebKit-based browsers under
  // certain circumstances (not enough space allocated for it?).
  //
  // 2. But adding the images using the 'html' type does not play nice with the 'save pdf'
  // functionality. Hence we use the 'image' element type as placeholder. It works the same
  // way and seems to work well when exported to pdf. No way (that I have found) to set the
  // title/alt attributes however.

  surveyJSON.elements[5].imageLink = `${process.env.PUBLIC_URL}/images/canvas-trust-categories.png`;
  surveyJSON.elements[8].imageLink = `${process.env.PUBLIC_URL}/images/canvas-generating-questions.png`;
  surveyJSON.elements[10].imageLink = `${process.env.PUBLIC_URL}/images/canvas-applied-tools-and-measurements.png`;

  const surveyModel = new Model(surveyJSON);
  surveyModel.showNavigationButtons = false;
  surveyModel.isRequired = true;

  useEffect(() => {
    onSurveyValueChanged(surveyModel);
  }, []);

  return (
    <>
      {/*
        At the moment the data is only stored in localStorage and printed to PDF.
        Add handlers for 'onComplete' and 'onCurrentPageChanged' as needed.
        Also, showNavigationButtons may need to be set to true.
       */}
      <Survey model={surveyModel} onAfterRenderPage={onSurveyAfterPageRender} onValueChanged={onSurveyValueChanged} />

      <div className="panel-footer card-footer">
        <div className="sv-footer__right right-survey-footer">
          <button id="surveySavePDF" className="btn survey-btn" type="button" onClick={onSavePDFClicked} data-tut="save-canvas">
            {t('pdf.label')}
          </button>
        </div>
      </div>
    </>
  );
};

let surveyJSON = {
  elements: [
    {
      type: 'html',
      html: '<p>The canvas below is a high-level overview and sample of an AI Governance Framework development process. It is intended to help members of your organization who attempt to complete the canvas to understand the development process of an AI-Governance framework and to begin thinking about:</p> <li>Why AI Governance is important to your organization,</li> <li>Who are the key stakeholders concerned with your organization’s AI Governance,</li> <li>What is most important to your stakeholders regarding the categories of “Trust” as they relate to the development and use of AI</li> <li>What questions need to be answered in order to provide trustworthy AI</li> <li>How you can you measure, manage and monitor the trustworthiness of your AI</li>',
      renderAs: 'image',
    },
    {
      type: 'comment',
      name: 'mission-vision',
      title: 'What is your Organization’s Mission/Vision:',
    },
    {
      type: 'multipletext',
      name: 'core-values',
      title: 'Please list your organization’s core values:',
      rowCount: '7',
      items: [
        {
          name: '1',
          title: 'Core Value 1:',
        },
        {
          name: '2',
          title: 'Core Value 2:',
        },
        {
          name: '3',
          title: 'Core Value 3:',
        },
        {
          name: '4',
          title: 'Core Value 4:',
        },
        {
          name: '5',
          title: 'Core Value 5:',
        },
        {
          name: '6',
          title: 'Core Value 6:',
        },
        {
          name: '7',
          title: 'Core Value 7:',
        },
      ],
    },
    {
      type: 'comment',
      name: 'priority-trust-categories',
      title: 'Considering your organization’s values, please identify priority AI Governance Trust Categories:',
      description: 'You may add your own categories and order them from most to least important. For example, here is a (non-exhaustive) list to consider: Quality, Performance, Security, Bias, Privacy, Ethics, Transparency, Stigmatization, Explainability, Safety, Inclusiveness',
    },
    {
      type: 'multipletext',
      name: 'priority-trust-categories-examples',
      title: 'Focus on your top 3 to 5 Categories and list the key factors or concepts that each category encompasses (see example below):',
      rowCount: '10',
      items: [
        {
          name: '1a',
          title: 'Trust Category 1:',
        },
        {
          name: '1b',
          title: 'Key factors:',
        },
        {
          name: '2a',
          title: 'Trust Category 2:',
        },
        {
          name: '2b',
          title: 'Key factors:',
        },
        {
          name: '3a',
          title: 'Trust Category 3:',
        },
        {
          name: '3b',
          title: 'Key factors:',
        },
        {
          name: '4a',
          title: 'Trust Category 4:',
        },
        {
          name: '4b',
          title: 'Key factors:',
        },
        {
          name: '5a',
          title: 'Trust Category 5:',
        },
        {
          name: '5b',
          title: 'Key factors:',
        },
      ],
    },
    {
      type: 'image',
      name: 'canvas-trust-categories',
      imageLink: '',
      imageWidth: '640px',
      imageHeight: '360px',
    },
    {
      type: 'multipletext',
      name: 'example-projects-multi',
      title: 'Provide two examples (projects or products) of AI for which your organization is using, or developing, and/or building AI-potential datasets:',
      rowCount: 14,
      items: [
        {
          name: 'a',
          title: 'Example 1 name:',
        },
        {
          name: 'b',
          title: 'Stage in AI lifecycle:',
        },
        {
          name: 'c',
          title: 'Data Type:',
        },
        {
          name: 'd',
          title: 'Training Data Source:',
        },
        {
          name: 'e',
          title: 'Target Data or Market:',
        },
        {
          name: 'f',
          title: 'Type of AI:',
        },
        {
          name: 'g',
          title: 'Impact Level (Scale low 1 to 4 high (see AIA))',
        },
        {
          name: 'h',
          title: 'Example 2 name:',
        },
        {
          name: 'i',
          title: 'Stage in AI lifecycle:',
        },
        {
          name: 'j',
          title: 'Data Type:',
        },
        {
          name: 'k',
          title: 'Training Data Source:',
        },
        {
          name: 'l',
          title: 'Target Data or Market:',
        },
        {
          name: 'm',
          title: 'Type of AI:',
        },
        {
          name: 'n',
          title: 'Impact Level (Scale low 1 to 4 high (see AIA))',
        },
      ],
    },

    {
      type: 'multipletext',
      name: 'category-questions',
      title: 'For each of your top 3 categories, develop three questions per category:',
      description: '(noting which stakeholder point-of-view (p.o.v.) the questions would originate from):',
      rowCount: 13,
      items: [
        {
          name: 'pov',
          title: 'Priority Stakeholder p.o.v.:',
        },
        {
          name: 'c1',
          title: 'Category 1:',
        },
        {
          name: 'c1q1',
          title: 'Question 1:',
        },
        {
          name: 'c1q2',
          title: 'Question 2:',
        },
        {
          name: 'c1q3',
          title: 'Question 3:',
        },
        {
          name: 'c2',
          title: 'Category 2:',
        },
        {
          name: 'c2q1',
          title: 'Question 1:',
        },
        {
          name: 'c2q2',
          title: 'Question 2:',
        },
        {
          name: 'c2q3',
          title: 'Question 3:',
        },
        {
          name: 'c3',
          title: 'Category 3:',
        },
        {
          name: 'c3q1',
          title: 'Question 1:',
        },
        {
          name: 'c3q2',
          title: 'Question 2:',
        },
        {
          name: 'c3q3',
          title: 'Question 3:',
        },
      ],
    },
    {
      type: 'image',
      name: 'canvas-generating-questions',
      imageLink: '',
      imageWidth: '640px',
      imageHeight: '360px',
    },
    {
      type: 'comment',
      name: 'measurement-methods',
      title: 'Measurement methods, including Impact Level:',
      description: 'What policies, processes, procedures, tools or techniques would you develop or use to measure and manage your level of compliance to the questions relating to the trustworthiness categories that you selected?',
    },
    {
      type: 'image',
      name: 'canvas-applied-tools-and-measurements',
      imageLink: '',
      imageWidth: '640px',
      imageHeight: '360px',
    },
    {
      type: 'multipletext',
      name: 'reporting-considerations-multi',
      title: 'Reporting and monitoring considerations:',
      rowCount: 3,
      items: [
        {
          name: 'what',
          title: 'What would you measure and report on from the monitoring of your AI?',
        },
        {
          name: 'who',
          title: 'To whom would you provide the AI monitoring report?',
        },
        {
          name: 'when',
          title: 'How frequently would you provide your AI monitoring results?',
        },
      ],
    },
    {
      type: 'comment',
      name: 'next-steps',
      title: 'What next steps would you recommend for your company in the process of developing its AI Governance Framework:',
    },
  ],
};

export default Canvas;
