/*
 * File: AIMSScorecardSurvey.jsx
 * Project: mtp-webui
 * File Created: Wednesday, 5th May 2021 2:15:44 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Thursday, 16th September 2021 10:07:16 am
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAIGSScorecard,
  // setSelectedScorecard,
  getAllAIGSQuestions,
  getAllAIGSRequirements,
  // getScorecardAnswers,
} from '../../redux/actions';

import { ScorecardQuestionnaire } from '../../components/scorecard';

import type { Scorecard } from '../../types';
import type { RootState } from '../../redux/store';
import type { RequirementReducerState } from '../../redux/reducers/ScorecardRequirementsReducer';
import type { QuestionReducerState } from '../../redux/reducers/ScorecardQuestionsReducer';

const Questionnaire = (): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const scorecard = (useSelector<RootState>((state) => state.scorecards.selected) as Scorecard) || undefined;
  const requirementsState = useSelector<RootState>((state) => state.scorecardRequirements) as RequirementReducerState;
  const questionsState = useSelector<RootState>((state) => state.scorecardQuestions) as QuestionReducerState;

  const requirementList = requirementsState.list;
  const questionList = questionsState.list;

  useEffect(() => {
    // Get all Scorecards this is what is cause the bug that displays AIGS when a project is first created instead of AIMS
    if (!scorecard) dispatch(getAIGSScorecard());
    if (requirementsState.lastFetchTime < 0) dispatch(getAllAIGSRequirements());
    if (questionsState.lastFetchTime < 0) dispatch(getAllAIGSQuestions());
  }, [scorecard, requirementsState, questionsState, dispatch]);

  return <>{scorecard && <ScorecardQuestionnaire title={t(`${scorecard.type.toLowerCase()}.label`)} scorecard={scorecard} requirementList={requirementList} questionList={questionList} />}</>;
};

export default Questionnaire;
