import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { logout } from '../../redux/actions';
// import './Error.css';

const Error = () => {
  const displayError = useSelector((state) => state.displayError);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div className="Error d-flex align-items-center justify-content-center">
      <div className="card md-shadow-4dp">
        <div className="card-body d-flex flex-column align-items-start">
          <h5 className="card-title">{t('error.label')}</h5>
          <p className="card-text">{t(displayError.msgId, displayError.msgArgs)}</p>
          <p className="card-text">{t('contact.admin.error')}</p>
          <a href="/#" className="btn btn-md align-self-end" onClick={() => dispatch(logout())}>
            {t('ok.label')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Error;
