/* eslint-disable */
/**
 * File: AIAStandards.js
 * Project: mtp-webui
 * File Created: Thursday, 22nd April 2021 1:16:00 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Thursday, 22nd April 2021 1:47:09 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai>)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { AlgorithmicAssessmentImpact } from '../../components/container';

const AIAStandards = () => {
  return <AlgorithmicAssessmentImpact isStandard />;
};

export default AIAStandards;
