/*
 * File: Drift.tsx
 * Project: Machine Trust Platform
 * File Created: Tuesday, 22nd June 2021 9:23:21 am
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Tuesday, 22nd June 2021 9:24:26 am
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React from 'react';
import PropTypes from 'prop-types';
import type { CycleProps } from './CycleTypes';

const Drift = ({ color = '#20A8D8', size = 50 }: CycleProps): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox={`0 0 ${size} ${size}`}>
      <path
        fill={color}
        d="M45 19.854h5v5.417h-5c-3.45 0-6.85-.948-10-2.709-6.25 3.521-13.75 3.521-20 0-3.15 1.76-6.575 2.709-10 2.709H0v-5.417h5c3.475 0 6.95-1.273 10-3.603 6.1 4.632 13.9 4.632 20 0 3.05 2.33 6.525 3.603 10 3.603zm0-16.252h5V9.02h-5c-3.45 0-6.85-.948-10-2.708-6.25 3.521-13.75 3.521-20 0-3.15 1.76-6.575 2.708-10 2.708H0V3.602h5c3.475 0 6.95-1.273 10-3.602 6.1 4.632 13.9 4.632 20 0 3.05 2.33 6.525 3.602 10 3.602zm0 32.503h5v5.417h-5c-3.45 0-6.85-.948-10-2.709-6.25 3.521-13.75 3.521-20 0-3.15 1.76-6.575 2.709-10 2.709H0v-5.417h5c3.475 0 6.95-1.273 10-3.602 6.1 4.631 13.9 4.631 20 0 3.05 2.329 6.525 3.602 10 3.602z"
      />
    </svg>
  );
};

Drift.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

Drift.defaultProps = {
  color: '#20A8D8',
  size: 50,
};

export default Drift;
