/*
 * File: ProjectCycle.tsx
 * Project: mtp-webui
 * File Created: Thursday, 13th May 2021 9:39:56 am
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Tuesday, 22nd June 2021 9:28:56 am
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React from 'react';
import PropTypes from 'prop-types';
import Cycle from '../Icons/Cycle';
import { PROJECT } from '../../constants';

type ProjectCycleProps = {
  cycle: number;
};

const ProjectCycle = ({ cycle = 0 }: ProjectCycleProps): JSX.Element => {
  return <div>{getCycle(cycle)}</div>;
};

const getCycle = (cycle: number) => {
  switch (cycle) {
    case PROJECT.CYCLE.DEVELOPMENT:
      return <Cycle.Development />;
    case PROJECT.CYCLE.DATA:
      return <Cycle.Data />;
    case PROJECT.CYCLE.DRIFT:
      return <Cycle.Drift />;
    case PROJECT.CYCLE.DEPLOY:
    default:
      return <Cycle.Deployment />;
  }
};
ProjectCycle.propTypes = {
  cycle: PropTypes.number,
};

ProjectCycle.defaultProps = {
  cycle: 0,
};

export default ProjectCycle;
