/*
 * File: indes.tsx
 * Project: Machine Trust Platform
 * File Created: Saturday, 28th August 2021 4:43:08 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Tuesday, 31st August 2021 3:11:13 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { useSpring, a } from '@react-spring/web';
import DrawerLinks from './DrawerLinks';
import { RootState } from '../../../../redux/store';
import { DrawerMap } from '../../types';
// import { AIGS_DRAWER, AIMS_DRAWER, TOOLS_DRAWER, STANDARDS_DRAWER, MTI_DRAWER } from './navigation-map';

interface Props {
  routes: DrawerMap;
}

const NavDrawer = ({ routes }: Props) => {
  const open = useSelector<RootState>((state) => state.uiState.isNavDrawerOpen);

  const spring = useSpring({
    from: { x: '0%', position: 'absolute' },
    to: async (next) => {
      await next(open ? { position: 'relative' } : { x: '-120%' });
      await next(open ? { x: '0%' } : { position: 'absolute' });
    },
  });

  return (
    <a.nav id="navDrawer" style={{ x: spring.x, position: spring.position as any }} className="nav-drawer">
      <Switch>
        {Object.entries(routes).map(([key, value]) => (
          <Route key={key} path={key}>
            <DrawerLinks basePath={key} linkList={value} />
          </Route>
        ))}
      </Switch>
    </a.nav>
  );
};

export default NavDrawer;
