/*
 * File: AIMS.route.jsx
 * Project: mtp-webui
 * File Created: Tuesday, 11th May 2021 4:47:36 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Monday, 21st June 2021 11:33:14 am
 * Modified By: Tarek Sanger (tarek@nuenergy.ai>)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
// import { useDispatch } from 'react-redux'
import { Scorecard } from '../pages';
import QuestionnaireRoute from './Questionnaire.route';

const AIMS = () => {
  const { path } = useRouteMatch();
  // const dispatch = useDispatch()

  return (
    <Switch>
      <Route path={`${path}/questionnaire`} component={QuestionnaireRoute} />
      <Route path={`${path}/scores`}>
        <Scorecard.Scores path={path} />
      </Route>
    </Switch>
  );
};

export default AIMS;
