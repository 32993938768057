/*
 * File: AIMSScores.jsx
 * Project: mtp-webui
 * File Created: Wednesday, 5th May 2021 2:32:36 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Tuesday, 22nd June 2021 5:49:37 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ScorecardScoresTable } from '../../components/tables';
import type { RootState } from '../../redux/store';
import type { ScoreReducerState } from '../../redux/reducers/ScorecardScoresReducer';
import type { Scorecard } from '../../types';

interface Props {
  path: string;
}
const ScorecardScores = ({ path }: Props): JSX.Element => {
  // const project = useSelector(state => state.projects.selected) || null
  const scorecard = (useSelector<RootState>((state) => state.scorecards.selected) as Scorecard) || undefined;

  const scorecardScoreState = (useSelector<RootState>((state) => state.scorecardScores) as ScoreReducerState) || undefined;
  const scorecardScores = scorecard && scorecardScoreState && scorecardScoreState[scorecard.uuid] && scorecardScoreState[scorecard.uuid].list ? scorecardScoreState[scorecard.uuid].list : undefined;

  return (
    <div className="container">
      <ScorecardScoresTable path={path} scorecardUuid={scorecard ? scorecard.uuid : null} scores={scorecardScores} />
    </div>
  );
};

ScorecardScores.propTypes = {
  path: PropTypes.string.isRequired,
};

export default ScorecardScores;
