/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import ConditionalWrapper from '../container/ConditionalWrapper';
import { AlgorithmicImpactAssessment } from '../../types';

type AIAChartProps = {
  assessment?: AlgorithmicImpactAssessment;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  link?: any;
};

const AIAChart = ({ assessment = undefined, link = undefined }: AIAChartProps): JSX.Element => {
  const { t } = useTranslation();
  const [data, setData] = useState<Array<{
    value: number;
    color: string;
    name: string;
  }> | null>(null);
  const height = 100;
  const width = 75;

  useEffect(() => {
    if (assessment) {
      let { mitigation_score, total_score, raw_risk_score } = assessment;
      mitigation_score = mitigation_score || 0;
      total_score = total_score || 0;
      raw_risk_score = raw_risk_score || 0;

      setData([
        {
          value: raw_risk_score,
          color: '#e83e8c',
          name: t('impact.score.label'),
        },
        {
          value: mitigation_score,
          color: '#20a8d8',
          name: t('mitigation.score.label'),
        },
        { value: total_score, color: '#4dbd74', name: t('total.score.label') },
      ]);
    }
  }, [assessment, t]);

  useEffect(() => {
    if (data) {
      drawChart(height, width, data);
    }
  }, [data, height, width]);

  return (
    <ConditionalWrapper
      condition={link !== undefined}
      wrapper={(children: React.ReactNode) => (
        <Link style={{ textDecoration: 'none', height: 'fit-content' }} to={link}>
          {children}
        </Link>
      )}>
      <>
        {assessment ? (
          <div className="aia-chart">
            {/* Government Logo */}
            {/* <div className='aia-chart__gov-logo'>
        <img src="https://www.canada.ca/etc/designs/canada/cdts/gcweb/v4_0_30/assets/sig-blk-en.svg" alt='Government of Canada' />
      </div> */}
            <img className="canadian-flag" width="50px" src={`${process.env.PUBLIC_URL}/images/Flag_of_Canada.svg`} alt="Canada" />
            {/* Legend */}
            <div className="legend">
              {data && (
                <>
                  <ul>
                    {data.map((d) => (
                      <li key={d.name} className={`aia-legend-${d.name.replace(' ', '-').toLocaleLowerCase()}`}>
                        {d.name}
                      </li>
                    ))}
                  </ul>
                  <img src={`${process.env.PUBLIC_URL}/images/aia.png`} alt="Algorithmic Impact Assessment" />
                </>
              )}
            </div>

            {/* Middle section (Level) */}
            <div className="level-container">
              <div className="level-wrapper">
                <div className={`aia-level ${levelAlert(assessment.level)}`}>
                  <span>{assessment.level}</span>
                </div>
                <span className={`level-label ${levelAlert(assessment.level)}`}>{t('level.label')}</span>
              </div>
            </div>
            {/* Bar Chart Container */}
            <div id="aia-bar-chart-container" className="aia-bar-chart-container">
              <div id="aia-bar-chart" className="aia-bar-chart" />
            </div>
          </div>
        ) : (
          <div className="aia-chart__no-data container md-shadow-4dp">
            {/* <img src={`${process.env.PUBLIC_URL}/images/aia.png`} alt='Algorithmic Impact Assessment' /> */}
            <span>{t('no.compliance.assessment.data.message')}</span>
          </div>
        )}
      </>
    </ConditionalWrapper>
  );
};

const drawChart = (height: number, width: number, data: { value: number; color: string; name: string }[]) => {
  d3.select('#aia-bar-chart').selectAll('svg').remove();
  const svg = d3.select('#aia-bar-chart').append('svg').attr('width', width).attr('height', height);

  const defs = svg.append('defs');

  // black drop shadow
  const filter = defs.append('filter').attr('width', '200%').attr('height', '140%').attr('id', 'drop-shadow');

  filter.append('feGaussianBlur').attr('in', 'SourceAlpha').attr('stdDeviation', 2).attr('result', 'blur');
  filter.append('feOffset').attr('in', 'blur').attr('dx', 2).attr('dy', 2).attr('result', 'offsetBlur');
  filter.append('feFlood').attr('in', 'offsetBlur').attr('flood-color', '#3d3d3d').attr('flood-opacity', '0.5').attr('result', 'offsetColor');
  filter.append('feComposite').attr('in', 'offsetColor').attr('in2', 'offsetBlur').attr('operator', 'in').attr('result', 'offsetBlur');

  const feMerge = filter.append('feMerge');

  feMerge.append('feMergeNode').attr('in', 'offsetBlur');
  feMerge.append('feMergeNode').attr('in', 'SourceGraphic');

  const yScale = d3.scaleLinear().range([height, 3]).domain([0, 100]);

  const selection = svg.selectAll('rect').data(data);

  selection
    .enter()
    .append('rect')
    .attr('class', 'aia-bar-chart-bar')
    .attr('rx', 5) // border radius
    .attr('ry', 5)
    .attr('x', (d: any, i: number) => i * (width / 3 + 1))
    .attr('y', (d: { value: number }) => yScale(d.value + 1))
    .attr('width', width / 4)
    .attr('height', (d: { value: number }) => height - yScale(d.value + 1))
    .attr('fill', (d: { color: any }) => d.color) // color
    .style('filter', 'url(#drop-shadow)');
  // .attr("filter", "url(#md-shadow)")
  // .style("filter", "url(#md-shadow)");

  svg
    .selectAll('text')
    .data(data)
    .enter()
    .append('text')
    .attr('x', (d: any, i: number) => i * (width / 3 + 1) + 10)
    .attr('y', (d: { value: any }) => yScale(d.value) - 5)
    .text((d: { value: any }) => String(d.value))
    .attr('text-anchor', 'middle')
    .attr('font-size', '15px')
    .style('fill', (d: { color: any }) => d.color);

  // selection
  //   .exit()
  //   .attr("y", (d) => height)
  //   .attr("height", 0)
  //   .transition().duration(300)
  // .remove()
};

const levelAlert = (lvl: any) => {
  switch (lvl) {
    case 1: // alert-success
      return 'aia-level-one';

    case 2: // alert-info
      return 'aia-level-two';

    case 3: // alert-warning
      return 'aia-level-three';

    case 4: // alert-danger
      return 'aia-level-four';

    default:
      return '';
  }
};

AIAChart.propTypes = {
  assessment: PropTypes.shape({
    uuid: PropTypes.string,
    version: PropTypes.string,
    raw_risk_score: PropTypes.number,
    mitigation_score: PropTypes.number,
    total_score: PropTypes.number,
    level: PropTypes.number,
    answer: PropTypes.string,
    created: PropTypes.string,
    modified: PropTypes.string,
  }),
  //  eslint disable react/forbid-prop-types
  link: PropTypes.string,
};

AIAChart.defaultProps = {
  assessment: undefined,
  link: undefined,
};

export default AIAChart;
