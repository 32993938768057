/* eslint-disable */
import { Reducer } from 'redux';
import { AUTH_LOGOUT, AUTH_SCHEDULE_FORCED_LOGOUT, RESET_INACTIVITY_TIMER, DISPLAY_ERROR_MESSAGE, APP_SET_ROLE_CONTEXT, APP_SHOW_ERROR_MESSAGES, TOGGLE_NAV_DRAWER, SET_TUTORIAL_STATE } from '../actions/action-types';
interface DisplayError {
  msgId?: string;
  msgArgs: any;
}
interface UIState {
  forcedLogoutTime?: any;
  inactivityTimer?: any;
  loading: number;
  displayError?: DisplayError;
  appRoleContext?: string;
  errorMessageCodes: any;
  isNavDrawerOpen: boolean;
  isTutorialOpen: boolean;
}
interface UIStateAction {
  type: string;
  forcedLogoutTime?: any;
  msgId?: any;
  msgArgs?: any;
  role?: string;
  inactivityTimer: any;
  errorMessageCodes: any;
  isTutorialOpen: boolean;
}

const initialState: UIState = {
  forcedLogoutTime: undefined,
  inactivityTimer: undefined,
  loading: 0,
  displayError: undefined,
  appRoleContext: undefined,
  errorMessageCodes: undefined,
  isNavDrawerOpen: true,
  isTutorialOpen: false,
};

const UIStateReducer: Reducer<UIState, UIStateAction> = (state = initialState, action) => {
  const { forcedLogoutTime, msgId, msgArgs, role, inactivityTimer, errorMessageCodes, isTutorialOpen } = action;

  const actionType = action.type  //.endsWith('_REQUEST') ? 'INCREMENT_LOADING_COUNTER' : action.type.endsWith('_SUCCESS') ? 'DECREMENT_LOADING_COUNTER' : action.type.endsWith('_FAILED') ? 'DECREMENT_LOADING_COUNTER' : action.type;

  switch (actionType) {
    case AUTH_LOGOUT:
      return { ...state, forcedLogoutTime: null };
    case AUTH_SCHEDULE_FORCED_LOGOUT:
      return { ...state, forcedLogoutTime };
    case 'INCREMENT_LOADING_COUNTER':
      return { ...state, loading: state.loading + 1 };
    case 'DECREMENT_LOADING_COUNTER':
      return {
        ...state,
        loading: state.loading - 1 < 0 ? 0 : state.loading - 1,
      };
    case DISPLAY_ERROR_MESSAGE:
      return { ...state, displayError: { msgId, msgArgs } };

    case APP_SET_ROLE_CONTEXT:
      return { ...state, appRoleContext: role };

    case RESET_INACTIVITY_TIMER:
      if (state.inactivityTimer != null) {
        clearTimeout(state.inactivityTimer);
      }
      return { ...state, inactivityTimer };

    case APP_SHOW_ERROR_MESSAGES:
      return { ...state, errorMessageCodes };

    case TOGGLE_NAV_DRAWER:
      return { ...state, isNavDrawerOpen: !state.isNavDrawerOpen };

    case SET_TUTORIAL_STATE:
      return {
        ...state,
        isTutorialOpen,
      };

    default:
      return state;
  }
};

export type { UIState };
export default UIStateReducer;
