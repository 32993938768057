import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { showErrorMessages } from '../../redux/actions';

const ErrorMessages = () => {
  const dispatch = useDispatch();
  const errorMessageCodes = useSelector((state) => state.uiState.errorMessageCodes);
  const { t } = useTranslation();

  const errorMessages = errorMessageCodes.map((emc) => <p key={emc[0]}>{t(...emc)}</p>);

  return (
    <div className="idle-logout-background">
      <div className="card">
        <div className="card-body">
          {errorMessages}
          <button type="button" className="btn btn-md" onClick={() => dispatch(showErrorMessages(null))}>
            {t('continue.label')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorMessages;
