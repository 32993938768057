/* eslint-disable */

/**
 * Assigns colors to a list of categories (strings) for visualization purposes
 * @param categories an array of strings representing categories for color assignments
 * @return {Object} an object with keys corresponding to each of the string categories passed in;
 *                  where, the value of each category key is an array whose first element is the
 *                  hex string value of the assigned color, and the second element is a class name
 *                  associated with the color
 */

export const getColorMap = (categories) => {
  const colors = [
    ['#20a8d8', 'color-map-blue'],
    ['#6610f2', 'color-map-indigo'],
    ['#e83e8c', 'color-map-pink'],
    ['#f86c6b', 'color-map-red'],
    ['#f8cb00', 'color-map-orange'],
    ['#4dbd74', 'color-map-green'],
    ['#ffc107', 'color-map-yellow'],
    ['#6f42c1', 'color-map-purple'],
    ['#20c997', 'color-map-teal'],
  ];
  const map = {};
  categories.sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' })).forEach((category, i) => (map[category] = colors[i]));
  return map;
};

export const colorHex2RGB = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};
