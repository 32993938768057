/*
 * File: ScorecardContainer.tsx
 * Project: Machine Trust Platform
 * File Created: Wednesday, 4th August 2021 1:46:30 pm
 * Author: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Last Modified: Friday, 27th August 2021 10:21:05 am
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
import React from 'react';
import { CircleIcon, NuEnergyBase64Logo } from '../Icons';

interface ScorecardContainerProps {
  title: string;
  children: React.ReactNode;
}

const ScorecardContainer = ({ title = '', children }: ScorecardContainerProps) => {
  return (
    <div id="nuenergy-questionnaire" className="scorecard-results survey-container" data-tut="questionnaire-results">
      <div className="scorecard-results__nuenergy-logo">
        <NuEnergyBase64Logo width={102} height={30} />
      </div>
      <div id="scorecard-title" className="scorecard-results__title">
        <CircleIcon iconClass="fas fa-project-diagram" reverse />
        <h1>{title}</h1>
      </div>
      <div className="scorecard-results__container container">{children}</div>
    </div>
  );
};

export default ScorecardContainer;
