/*
 * File: Questionnaire
 * Project: mtp-webui
 * File Created: Friday, 9th April 2021 1:01:13 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Tuesday, 26th October 2021 11:08:32 am
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * Last Modified: Tuesday, 26th October 2021 11:08:32 am
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Model, Survey, StylesManager } from 'survey-react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getAnswers, cacheAnswers, flushCachedAnswers } from '../../../utils/localStorage/surveyLocalStorage';

import { updateScorecardScore, submitScorecardScore } from '../../../redux/actions';
import { SCORECARD } from '../../../constants';


const SurveyContainer = ({ scorecard, json, currentPage, setCurrentPage, hasAnswers, setHasAnswers }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { path } = useRouteMatch();

  const [completeDisable, setCompleteDisable] = useState(true);

  const scorecardUuid = scorecard ? scorecard.uuid : '';

  // Get the selected score only if it is in the draft state
  const scorecardScoreRecoredsState = useSelector((state) => state.scorecardScores) || undefined;
  const scorecardScoreState = scorecardScoreRecoredsState && scorecardScoreRecoredsState[scorecard.uuid] ? scorecardScoreRecoredsState[scorecard.uuid] : undefined;
  const selectedDraft = scorecardScoreState && scorecardScoreState.selected && scorecardScoreState.selected.state === SCORECARD.SCORE.STATE.DRAFT ? scorecardScoreState.selected : undefined;

  StylesManager.applyTheme('bootstrap');

  const surveyModel = new Model(json);

  surveyModel.showNavigationButtons = false;
  surveyModel.isRequired = true;
  surveyModel.data = getAnswers(scorecardUuid);
  surveyModel.currentPageNo = currentPage;

  const handleSaveDraft = (model) => {
    let answers = model && Object.keys(model.data).length !== 0 ? model.data : undefined;
    answers = !answers && scorecard ? getAnswers(scorecard.uuid) : answers;
    if (answers) {
      if (selectedDraft) {
        dispatch(updateScorecardScore(scorecard.uuid, { uuid: selectedDraft.uuid, answers }));
      } else {
        dispatch(submitScorecardScore(scorecard, answers, true));
      }
    }
  };

  /**
   * Handles the onComplete of the survey
   * and submits the answers
   */
  const onComplete = (model) => {
    if (Object.keys(model.data).length !== 0) {
      dispatch(submitScorecardScore(scorecard, model.data)).then((response) => {
        flushCachedAnswers(scorecard.uuid);
        history.push(`${path}/results?scorecard=${scorecardUuid}&score=${response.score.uuid}`);
      });
    }
  };

  /**
   * Called only on page change
   * @param {*} model
   */
  const doOnCurrentPageChanged = (model) => {
    const surveyComplete = document.getElementById('surveyComplete');
    if (surveyComplete) setCompleteDisable(!(Object.keys(model.data).length > 0 && model.isLastPage));

    const surveyPrev = document.getElementById('surveyPrev');
    if (surveyPrev) surveyPrev.style.display = !model.isFirstPage ? 'inline' : 'none';

    const surveyNext = document.getElementById('surveyNext');
    if (surveyNext) surveyNext.style.display = !model.isLastPage ? 'inline' : 'none';

    // const surveyPageNo = document.getElementById('surveyPageNo');
    // if (surveyPageNo) surveyPageNo.value = model.currentPageNo;
  };

  /**
   * Called when a question Value has been changed.
   * @param {*} model
   */
  const doOnValueChanged = (model) => {
    const surveyComplete = document.getElementById('surveyComplete');
    if (surveyComplete) setCompleteDisable(!(Object.keys(model.data).length > 0 && model.isLastPage));

    const surveyDraft = document.getElementById('surveyDraft');
    if (surveyDraft) surveyDraft.style.display = Object.keys(model.data).length > 0 ? 'inline' : 'none';

    if (Object.keys(model.data).length > 0) {
      cacheAnswers(scorecardUuid, model.data);
      if (!hasAnswers) setHasAnswers(true);
    }
  };

  const handlePageRender = () => {
    surveyModel.data = getAnswers(scorecardUuid);
    // model.data = getAnswers(scorecardUuid);
  };

  useEffect(() => {
    doOnValueChanged(surveyModel);
    doOnCurrentPageChanged(surveyModel);
  }, []);

  useEffect(() => {
    if (selectedDraft) {
      surveyModel.data = getAnswers(scorecardUuid);
    }
  }, [selectedDraft]);

  return (
    <>
      <Survey id="questionnaire" model={surveyModel} onComplete={onComplete} onAfterRenderPage={handlePageRender} onCurrentPageChanged={doOnCurrentPageChanged} onValueChanged={doOnValueChanged} />

      <div className="panel-footer card-footer">
        <div className="sv-footer__left ">
          <button
            id="surveyPrev"
            className="btn survey-btn"
            type="button"
            onClick={(e) => {
              surveyModel.prevPage();
              setCurrentPage(currentPage - 1);
              e.preventDefault();
            }}>
            {t('previous.label')}
          </button>
        </div>
        <div className="sv-footer__right right-survey-footer">
          <button id="surveyDraft" className="btn survey-btn" type="button" onClick={() => handleSaveDraft(surveyModel)}>
            {t('save.label')}
          </button>

          <button
            id="surveyNext"
            className="btn survey-btn"
            type="button"
            onClick={() => {
              surveyModel.nextPage();
              setCurrentPage(currentPage + 1);
            }}>
            {t('next.label')}
          </button>
          <button id="surveyComplete" className="btn survey-btn" type="button" disabled={completeDisable} onClick={() => surveyModel.completeLastPage()}>
            {t('complete.label')}
          </button>
        </div>
      </div>
    </>
  );
};

export default SurveyContainer;
