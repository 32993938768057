/* eslint-disable */
import { AUTH_AUTHENTICATE_USER_REQUEST, AUTH_AUTHENTICATE_USER_SUCCESS, AUTH_AUTHENTICATE_USER_FAILED, AUTH_EXCHANGE_CODE_FOR_TOKENS_SUCCESS, AUTH_EXCHANGE_CODE_FOR_TOKENS_FAILED, AUTH_REFRESH_TOKENS_SUCCESS, AUTH_LOGOUT, AUTH_RETRIEVE_CACHED_TOKENS_SUCCESS } from '../redux/actions/action-types';

import {
  // Auth Actions
  exchangeCodeForTokens,
  retrieveCachedTokens,
  refreshTokens,
  scheduleForcedLogout,

  // App Actions
  resetInactivityTimer,
} from '../redux/actions';

import * as auth from '../utils/localStorage/authLocalStorage';
import { JWT_REFRESH_BUFFER_MILLISECONDS } from '../constants';
import config from '../config.json';

/* ------ Bring Back for MTI ---- */
// import * as MtpMainApi from '../openapi';
// import * as MtiCoreApi from '../openapi-core';

import jwt from 'jsonwebtoken';
import $ from 'jquery';

const jwksClient = require('jwks-client');
let _jwksClient = null;

const authenticateUser = (store) => {
  return (next) => {
    return (action) => {
      (async () => {
        switch (action.type) {
          case AUTH_AUTHENTICATE_USER_REQUEST:
            await _authenticateUser(store, action.authCallback);
            break;
          case AUTH_EXCHANGE_CODE_FOR_TOKENS_SUCCESS:
          case AUTH_RETRIEVE_CACHED_TOKENS_SUCCESS:
            auth.cacheTokens(action.authTokens);
            auth.flushCachedAuthState();
            break;
          case AUTH_EXCHANGE_CODE_FOR_TOKENS_FAILED:
            auth.flushCachedAuthState();
            break;
          case AUTH_REFRESH_TOKENS_SUCCESS:
            try {
              await auth.cacheTokens(action.authTokens);
              await _validateTokens(store);
              _scheduleNextTokenRefresh(store);
            } catch (error) {
              auth.flushCachedTokens();
              _redirectToIDP(config);
            }
            break;
          case AUTH_LOGOUT:
            auth.flushCachedTokens();
            _redirectLogoutToIDP(config);
            break;
          default:
            break;
        }
      })();
      return next(action);
    };
  };
};

export default authenticateUser;

const _authenticateUser = async ({ getState, dispatch }, authCallback) => {
  // Make sure the JWKS Client is initialized
  _jwksClient = _jwksClient
    ? _jwksClient
    : jwksClient({
        strictSsl: false,
        cache: true,
        rateLimit: true,
        jwksUri: process.env.REACT_APP_JWKS_URI,
      });

  let authTokens = getState().authTokens;
  if (!authTokens.id_token || !authTokens.access_token || !authTokens.refresh_token) {
    // Try and get cached authTokens from cookies
    await dispatch(retrieveCachedTokens());
  }

  authTokens = getState().authTokens;
  if (!authTokens.id_token || !authTokens.access_token || !authTokens.refresh_token) {
    // See if we can exchange an authentication code for some tokens
    const params = new URLSearchParams(window.location.search);
    const code = params.has('code') ? params.get('code') : null;
    const state = params.has('state') ? params.get('state') : null;

    const authState = auth.getCachedAuthState();
    if (code && state === authState) {
      await dispatch(exchangeCodeForTokens(code));
    }
  }

  authTokens = getState().authTokens;
  if (!authTokens.id_token || !authTokens.access_token || !authTokens.refresh_token) {
    // We still have no tokens, so force the user to authenticate against the IDP
    await dispatch({
      type: AUTH_AUTHENTICATE_USER_FAILED,
      error: 'Need to Authenticate User against IDP',
    });
    _redirectToIDP(config);
    return;
  }

  try {
    await _validateTokens({ getState, dispatch });
    await dispatch({ type: AUTH_AUTHENTICATE_USER_SUCCESS });
    _scheduleNextTokenRefresh({ getState, dispatch });
    // _startInactivityTimer({ getState, dispatch });
    if (authCallback) {
      authCallback(getState);
    }
  } catch (error) {
    await dispatch({ type: AUTH_AUTHENTICATE_USER_FAILED, error });
    auth.flushCachedTokens();
    _redirectToIDP(config);
  }
};

const _redirectToIDP = (config) => {
  window.location =
    `https://${config.auth_domain}/oauth2/authorize?` +
    `client_id=${process.env.REACT_APP_CLIENT_ID}&` +
    `redirect_uri=${window.location.protocol}//${window.location.hostname}&` +
    `response_type=${process.env.REACT_APP_RESPONSE_TYPE}&` +
    `scope=${process.env.REACT_APP_SCOPE}&` +
    `state=${auth.generateAuthState()}`;
};

const _validateToken = async (token) => {
  return new Promise((resolve, reject) => {
    if (token) {
      jwt.verify(
        token,
        (header, callback) => _jwksClient.getSigningKey(header.kid, (err, key) => callback(null, key.publicKey || key.rsaPublicKey)),
        (error, decoded) => {
          if (error) {
            reject(error);
          } else {
            resolve(decoded);
          }
        },
      );
    } else {
      reject('Null Token');
    }
  });
};

const _scheduleNextTokenRefresh = ({ getState, dispatch }) => {
  const idTokenExp = jwt.decode(getState().authTokens.id_token).exp * 1000;
  const accessTokenExp = jwt.decode(getState().authTokens.access_token).exp * 1000;
  const refreshFromNow = (idTokenExp <= accessTokenExp ? idTokenExp : accessTokenExp) - new Date().getTime() - JWT_REFRESH_BUFFER_MILLISECONDS;
  setTimeout(
    () => {
      dispatch(refreshTokens());
    },
    refreshFromNow > 0 ? refreshFromNow : 1,
  );
};

const _validateTokens = async ({ getState, dispatch }) => {
  await _validateToken(getState().authTokens.id_token);
  await _validateToken(getState().authTokens.access_token);
};

const _startInactivityTimer = ({ getState, dispatch }) => {
  const inactivityReset = () => {
    // dispatch(
    //   resetInactivityTimer(() => {
    //     $(document).off();
    //     dispatch(scheduleForcedLogout());
    //     _startPendingLogoutCountdown({ dispatch, getState });
    //   }),
    // );
  };

  inactivityReset();
};

const _startPendingLogoutCountdown = ({ getState, dispatch }) => {
  let intervalId = null;

  intervalId = setInterval(() => {
    const { forcedLogoutTime } = getState().uiState;
    const timeRemaining = forcedLogoutTime !== null ? forcedLogoutTime - new Date().getTime() : null;
    if (timeRemaining === null) {
      clearInterval(intervalId);
      // _startInactivityTimer({ getState, dispatch });
    } else if (timeRemaining <= 0) {
      clearInterval(intervalId);
      dispatch({ type: AUTH_LOGOUT });
    }
  }, 1000);
};

const _redirectLogoutToIDP = (config) => {
  window.location.href = `https://${config.auth_domain}/logout?` + `response_type=code&` + `client_id=${process.env.REACT_APP_CLIENT_ID}&` + `redirect_uri=${window.location.protocol}//${window.location.hostname}&` + `scope=${process.env.REACT_APP_SCOPE}&` + `state=${auth.generateAuthState()}`;
};
