/* eslint-disable */
/**
 * File: AIGSSurveyResults.js
 * Project: mtp-webui
 * File Created: Monday, 1st March 2021 10:47:30 am
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Tuesday, 11th May 2021 4:14:33 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai>)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllScorecardScores } from '../../redux/actions';
import { DownloadPDF } from '../../components/buttons';

import { ScorecardContainer, ScorecardResults } from '../../components/scorecard';
import { useQuery } from '../../hooks';
import { SCORECARD } from '../../constants';

const QuestionnaireResults = () => {
  const dispatch = useDispatch();

  // Get the scorecard uuid from the url Param
  //const { scorecardUuid } = useParams();

  // Get the score uuid form the query
  // This can be null the scorecardUuid cannot be
  const query = useQuery();
  const scoreUuid = query ? query.get('score') : undefined;
  const scorecardUuid = query ? query.get('scorecard') : undefined;

  // Results
  const ScorecardScoreRecords = useSelector((state) => state.scorecardScores) || undefined;
  const scorecardScoreState = ScorecardScoreRecords[scorecardUuid] ? ScorecardScoreRecords[scorecardUuid] : undefined;
  const scoreList = scorecardScoreState ? scorecardScoreState.list?.filter((r) => r.state !== SCORECARD.SCORE.STATE.DRAFT) : undefined;

  let results = undefined;
  if (scoreList && scoreList.length > 0) {
    if (scoreUuid) {
      results = scoreList.find((s) => s.uuid === scoreUuid) || undefined;
    } else {
      results = scoreList[0];
    }
  }

  // Scorecard
  const scorecardList = useSelector((state) => state.scorecards.list) || undefined;
  const scorecard = scorecardList && scorecardUuid ? scorecardList?.find((s) => s.uuid === scorecardUuid) : undefined;

  useEffect(() => {
    if (!results) dispatch(getAllScorecardScores(scorecardUuid));
  }, [scorecardUuid, results, dispatch]);

  if (results && scorecard)
    return (
      <>
        <ScorecardContainer title="Scorecard Results">
          <ScorecardResults results={results} />
        </ScorecardContainer>
        <DownloadPDF title={`${scorecard.title}-results`} elementID="nuenergy-questionnaire" />
      </>
    );

  /* TODO: show not found */
  return <></>;
};

export default QuestionnaireResults;
