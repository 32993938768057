/* eslint-disable camelcase */
import type { Reducer } from 'redux';
import { AUTH_EXCHANGE_CODE_FOR_TOKENS_SUCCESS, AUTH_RETRIEVE_CACHED_TOKENS_SUCCESS, AUTH_REFRESH_TOKENS_SUCCESS, AUTH_LOGOUT } from '../actions/action-types';

interface AuthState {
  id_token?: string;
  access_token?: string;
  refresh_token?: string;
}

interface AuthAction {
  type: string;
  authTokens: {
    id_token?: string;
    access_token?: string;
    refresh_token?: string;
  };
}

const initialState: AuthState = {
  id_token: undefined,
  access_token: undefined,
  refresh_token: undefined,
};

const AuthTokensReducer: Reducer<AuthState, AuthAction> = (state = initialState, action) => {
  const { authTokens } = action;
  switch (action.type) {
    case AUTH_EXCHANGE_CODE_FOR_TOKENS_SUCCESS:
    case AUTH_REFRESH_TOKENS_SUCCESS:
    case AUTH_RETRIEVE_CACHED_TOKENS_SUCCESS:
      return { ...state, ...authTokens };

    case AUTH_LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export type { AuthState };
export default AuthTokensReducer;
