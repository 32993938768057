/*
 * File: ScorecardRequirementReducer.tsx
 * Project: Machine Trust Platform
 * File Created: Monday, 21st June 2021 12:07:12 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Monday, 21st June 2021 12:07:14 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai>)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
import type { Reducer } from 'redux';
import type { Requirement } from '../../types';
import {
  // AIGS Requirement
  AIGS_REQUIREMENT_GET_ALL_SUCCESS,
  AIGS_REQUIREMENT_ADD_SUCCESS,
  AIGS_REQUIREMENT_GET_SUCCESS,
  AIGS_REQUIREMENT_DELETE_SUCCESS,
  AIGS_REQUIREMENT_UPDATE_SUCCESS,
  AIGS_REQUIREMENT_SET_SELECTED,
} from '../actions/action-types';

interface RequirementReducerState {
  list: Requirement[]; // TODO Define Requirements Object
  selected?: string;
  lastFetchTime: number;
  userLastModTime: number;
}

interface RequirementReducerAction {
  type: string;
  requirementList?: Requirement[];
  requirement?: Requirement;
  requirementUuid?: string;
}

const requirementsInitialState: RequirementReducerState = {
  list: [],
  selected: undefined,
  lastFetchTime: -1,
  userLastModTime: -1,
};

/** AI Governance Scorecard Requirement Reducer
 * This handles the actual requirements separate from the Scorecard.
 */
const ScorecardRequirementReducer: Reducer<RequirementReducerState, RequirementReducerAction> = (state = requirementsInitialState, action) => {
  const { requirementList, requirement, requirementUuid } = action;

  switch (action.type) {
    case AIGS_REQUIREMENT_GET_ALL_SUCCESS:
      return {
        ...state,
        list: requirementList || [],
        lastFetchTime: new Date().getTime(),
      };
    case AIGS_REQUIREMENT_ADD_SUCCESS:
      return {
        ...state,
        list: [...state.list, requirement as Requirement],
        userLastModTime: new Date().getTime(),
        selected: requirement?.uuid,
      };

    case AIGS_REQUIREMENT_UPDATE_SUCCESS:
    case AIGS_REQUIREMENT_GET_SUCCESS:
      return {
        ...state,
        list: state.list.map((r) => (r.uuid === (requirement as Requirement).uuid ? (requirement as Requirement) : r)),
        userLastModTime: new Date().getTime(),
      };
    case AIGS_REQUIREMENT_DELETE_SUCCESS:
      return {
        ...state,
        list: state.list?.filter((r) => r.uuid !== (requirementUuid as string)),
        userLastModTime: new Date().getTime(),
        selected: state.selected === requirementUuid ? undefined : state.selected,
      };

    case AIGS_REQUIREMENT_SET_SELECTED:
      return {
        ...state,
        selected: requirementUuid,
      };

    default:
      return state;
  }
};

export type { RequirementReducerState };
export default ScorecardRequirementReducer;
