import React from 'react';
// import {
// selectProject,
// getAIGSRequirement,
// getAllAIGSRequirements,
// getAllScorecards,
// getAllAIGSQuestions,
// } from "../redux/actions";
// import Dashboard from "../components/MTIDashboard";
// import {ScorecardAsterChart} from '../components/charts'
// import { useDispatch, useSelector, batch } from "react-redux";

// import DashboardNavButton from "../components/buttons/DashboardNavButton"

const Home = () => {
  // const dispatch = useDispatch();

  // const projects = useSelector((state) => state.projects.list) || null
  // let selectedProject = useSelector((state) => state.projects.selected) || null

  // // if there is a selected project get the model
  // // TODO : use selected model
  // let models = useSelector((state) => state.models) || null
  // let model = selectedProject && models ? models[selectedProject] : null
  // model = model && model.selected ? model.selected : null

  // // useEffect(()=> {
  // //   dispatch(getAllAIGSQuestions())
  // // }, [])

  // // Getting all models once projects have been set
  // useEffect(() => {
  //   // if there are projects get all model and set the selected project
  //   if (projects.length > 0 && !model && !selectedProject) {
  //     batch(() => {
  //       let project = projects[0];
  //       dispatch(selectProject(project.uuid))
  //     })
  //   }

  //   // Clean up selectedProject on
  //   return () => {
  //     dispatch(selectProject(null))
  //   }
  // }, [projects])

  return (
    <div className="container-fluid">
      {/* <DashboardNavButton title={'Test'} /> */}

      {/* <Dashboard/> */}
    </div>
  );
};

export default Home;

/* <div className='mtp-welcome container-fluid'>
<img src="/images/logo.png" alt='NuEnergy' />
<div className='mtp-welcome__msg'>
  <h2>Welcome to the Machine Trust Platform</h2>
</div>
</div> */

// // Handle Scorecards
// const scorecards = useSelector(state => state.scorecards.list) || null
// // TODO: Show scorecards that have been answered

// useEffect(()=> {
//   if(!scorecards) dispatch(getAllScorecards())
// }, [scorecards])

//   return (
//     <div className='container-fluid'>
//       {scorecards ? scorecards.map((s, index) => <div className='col-md-4' key={index}><ScorecardAsterChart scorecard={s}/></div>) : <h1>Show Welcome</h1>}
//     </div>
//   );
