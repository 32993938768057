/**
 * File: Results.js
 * Project: mtp-webui
 * File Created: Tuesday, 2nd March 2021 2:03:03 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Wednesday, 17th March 2021 3:36:52 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai>)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProgressBar } from 'react-bootstrap';

const ScorecardResults = ({ results }) => {
  const { t } = useTranslation();

  let totalOfRequirementWeights = 0;

  if (results && results.requirements) {
    results.requirements.forEach((r) => {
      totalOfRequirementWeights += r.weight;
    });
  }

  return (
    <>
      <div className="scorecard-results__scorecard-title">
        <h2 className="overall-score" data-tut="questionnaire-results-score">
          {results ? `${Math.round(results.score)}%` : ''}{' '}
        </h2>
      </div>
      <div className="scorecard-results__requirement-scores" data-tut="questionnaire-results-requirement-score">
        {results && results.requirements
          ? results.requirements.map((r) => (
              <div className="requirement-score" key={r.requirement.name}>
                {/* Requirement Heading */}
                <div className="requirement-progress__label">
                  <h4>{r.requirement.name}</h4>
                  <span>{`${t('weight.label')}: ${Math.round((r.weight / totalOfRequirementWeights) * 100)}%`}</span>
                </div>
                {/* Requirement Score */}
                <div className="requirement-progress__bar">{r.score !== -1 ? <ProgressBar now={Math.round(r.score)} label={`${Math.round(r.score)}%`} /> : 'N/A'}</div>
              </div>
            ))
          : null}
      </div>

      <div className="scorecard-results__requirement_question-scores" data-tut="questionnaire-results-requirement-question-score">
        {/* Requirement Results */}
        {results && results.requirements
          ? // TODO: Double check that r.requirement.uuid has a value
            results.requirements.map((r) => (
              <div key={r.requirement.uuid} className="requirement">
                <h3 className="requirement__header">{r.requirement.name}</h3>

                {/*  Question Results */}
                <ul className="requirement__question-list">
                  {r.question_results.map((question) => (
                    <li className="requirement-question-result" key={question.uuid}>
                      <QuestionInfo question={question} r={r} />
                      {question.sub_questions ? (
                        <div className="">
                          {question.sub_questions.map((sq) => (
                            <QuestionInfo key={sq.uuid} question={sq} isSubQuestion />
                          ))}
                        </div>
                      ) : null}
                    </li>
                  ))}
                </ul>
              </div>
            ))
          : null}
      </div>
    </>
  );
};

export default ScorecardResults;

const QuestionInfo = (props) => {
  const { question, r, isSubQuestion = false } = props;
  const { t } = useTranslation();

  return (
    <>
      <div className="requirement-question-result__header">
        <h5 id="result-question">{question.question}</h5>
        {!isSubQuestion ? <span className="header-question-score">{question.answer_value != null && question.answer_value !== -1 ? `[${t('points.label')}: +${Math.round((question.weight * question.answer_value * 100) / r.maximum_of_weighted_score)}]` : ''}</span> : null}
      </div>

      <div className="requirement-question-result__responses">
        <span className="header-question-score">{question.answer}</span>
        {question.explanation ? (
          <div className="question-explanation">
            <h6 className="response-header">{`${t('explanation.label')}:`}</h6>
            <p>{question.explanation}</p>
          </div>
        ) : null}

        {question.mitigations ? (
          <div className="question-mitigation__container">
            <h6 className="response-header">{`${t('recommendations.label')}:`}</h6>
            <ul className="question-mitigation__list">
              {question.mitigations.map((m) => (
                <li key={m}>{m}</li>
              ))}
            </ul>
          </div>
        ) : null}
      </div>
    </>
  );
};
