/**
 * File: index.js
 * Project: mtp-webui
 * File Created: Tuesday, 11th May 2021 4:35:22 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Wednesday, 12th May 2021 5:33:56 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai>)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
// import React from 'react'

// const Scores = React.lazy(() => import('./Scores'));
// const Questionnaire = React.lazy(() => import('./Questionnaire'));
// const QuestionnaireResults = React.lazy(() => import('./QuestionnaireResults'))

import Scores from './Scores';
import Questionnaire from './Questionnaire';
import QuestionnaireResults from './QuestionnaireResults';

const Scorecard = {
  Scores,
  Questionnaire,
  QuestionnaireResults,
};

export default Scorecard;
