/* eslint-disable */
import { Reducer } from 'redux';
import jwt from 'jsonwebtoken';
import { AUTH_EXCHANGE_CODE_FOR_TOKENS_SUCCESS, AUTH_RETRIEVE_CACHED_TOKENS_SUCCESS, AUTH_REFRESH_TOKENS_SUCCESS, AUTH_LOGOUT, AUTH_GET_USER_ORGANIZATION_SUCCESS, UPDATE_USER_SUCCESS, UPDATE_USER_CONSENT_SUCCESS } from '../actions/action-types';
import { roleOrder } from '../../utils/rolesUtil';
import type { User, Organization, OrganizationTier } from '../../types';

interface UserState {
  uuid?: string;
  username?: string;
  familyName?: string;
  givenName?: string;
  email?: string;
  organization?: Organization;
  welcomed?: boolean;
  roles: string[] | [];
  privacyTermsConditions: {
    state: number;
    dateSigned?: Date;
  };
}

interface UserAction {
  user?: User;
  authTokens?: any;
  privacyTermsConditions: {
    state: number;
    dateSigned?: Date;
  };
  type: string;
}

const initialState: UserState = {
  username: undefined,
  familyName: undefined,
  givenName: undefined,
  email: undefined,
  uuid: undefined,
  organization: undefined,
  welcomed: true,
  roles: [],
  privacyTermsConditions: {
    state: 0,
    dateSigned: undefined,
  },
};

const UserReducer: Reducer<UserState, UserAction> = (state = initialState, action) => {
  const { user, authTokens, privacyTermsConditions } = action;
  switch (action.type) {
    case AUTH_EXCHANGE_CODE_FOR_TOKENS_SUCCESS:
    case AUTH_REFRESH_TOKENS_SUCCESS:
    case AUTH_RETRIEVE_CACHED_TOKENS_SUCCESS:
      const idToken = jwt.decode(authTokens.id_token) as jwt.JwtPayload;

      // Default Role
      let roles: string[] = idToken['mtp:roles']
        .split(',')
        .map((role: string) => role.trim())
        .filter((role: string) => role.length > 0)
        .sort((a: string, b: string) => roleOrder(a) - roleOrder(b));
      // TODO: Fix this later
      if (roles.length < 1) roles = ['admin'];

      return {
        ...state,
        username: idToken['cognito:username'],
        familyName: idToken.family_name,
        givenName: idToken.given_name,
        email: idToken.email,
        uuid: idToken.sub,
        roles,
      };

    case AUTH_GET_USER_ORGANIZATION_SUCCESS:
    case UPDATE_USER_SUCCESS:
      return { ...state, ...user };

    case UPDATE_USER_CONSENT_SUCCESS:
      return {
        ...state,
        privacyTermsConditions: privacyTermsConditions,
      };

    case AUTH_LOGOUT:
      return { ...initialState };

    default:
      return state;
  }
};

export type { UserState };
export default UserReducer;
