import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ConfirmAction = (props) => {
  const { config, hideCancel = false } = props;
  const { t } = useTranslation();
  const [configState, setConfigState] = useState(config);

  useEffect(() => {
    setConfigState(config);
  }, [config]);

  return (
    <div className={`confirm-action-background ${configState ? '' : 'd-none'}`}>
      <div className="card md-shadow-4dp">
        <div className="card-body">
          {configState && configState.actionPrincipal ? <span className="confirm-action-principal">{configState.actionPrincipal}</span> : <></>}
          {configState && configState.messageCodes ? configState.messageCodes.map((emc) => <p key={emc}>{t(...emc)}</p>) : <></>}
        </div>
        <div className="card-footer">
          {hideCancel ? null : (
            <button type="button" className={`btn btn-md ${configState && configState.action instanceof Function ? '' : 'd-none'}`} onClick={() => setConfigState(null)}>
              {t('cancel.label')}
            </button>
          )}
          <button
            type="button"
            className={`btn btn-md ${configState && configState.actionButtonClass ? configState.actionButtonClass : ''}`}
            onClick={(e) => {
              if (configState && configState.action instanceof Function) configState.action();
              setConfigState(null);
              e.preventDefault();
            }}>
            {configState && configState.actionButtonLabelCode ? t(configState.actionButtonLabelCode) : t('continue.label')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmAction;
