/* eslint-disable */
import {
  // Projects
  PROJECTS_GET_ALL_FAILED,
  PROJECTS_ADD_FAILED,
  PROJECTS_DELETE_FAILED,
  PROJECTS_UPDATE_FAILED,
  // PROJECTS_DEPLOY_FAILED,

  // Project Trust Category
  PROJECT_TRUST_CATEGORY_ADD_FAILED,
  PROJECT_TRUST_CATEGORY_GET_ALL_FAILED,
  PROJECT_TRUST_CATEGORY_DELETE_FAILED,
  PROJECT_TRUST_CATEGORY_UPDATE_FAILED,

  // Project Trust Questions
  PROJECT_TRUST_QUESTIONS_GET_ALL_FAILED,
  PROJECT_TRUST_QUESTIONS_ADD_FAILED,
  PROJECT_TRUST_QUESTIONS_DELETE_FAILED,
  PROJECT_TRUST_QUESTIONS_UPDATE_FAILED,

  // Project Models
  MODELS_GET_ALL_FAILED,
  MODELS_ADD_FAILED,
  MODELS_DELETE_FAILED,
  MODELS_UPDATE_FAILED,
  MODEL_ONLY_ONE_ERROR,
  MODEL_SELECT_ONE_ERROR,

  // MTI Scores
  MTI_SCORES_GET_FAILED,

  // Trust Categories
  TRUST_CATEGORIES_GET_FAILED,
  TRUST_CATEGORIES_ADD_FAILED,
  TRUST_CATEGORIES_DELETE_FAILED,

  // Trust Questions
  TRUST_QUESTIONS_GET_FAILED,
  TRUST_QUESTIONS_PUBLISH_FAILED,

  // Alert Setup
  PROJECT_TRUST_QUESTION_ALERT_UPDATE_FAILED,
  PROJECT_TRUST_QUESTION_ALERT_GET_FAILED,

  // Tool Analysis
  PROJECT_TRUST_QUESTION_TOOL_ANALYSIS_GET_FAILED,

  // Project Compliance Assessments
  COMPLIANCE_ASSESSMENT_GET_FAILED,
  COMPLIANCE_ASSESSMENT_ADD_FAILED,
  COMPLIANCE_ASSESSMENT_UPDATE_FAILED,
  COMPLIANCE_ASSESSMENT_ALREADY_PUBLISHED_ERROR,
  //
  MODEL_TRUST_QUESTIONS_SURVEY_COMPLETE_FAILED,

  // Trust Tool Sandbox
  TRUST_TOOL_SANDBOX_NO_CONFIGURATION_NAME_ERROR,
  TRUST_TOOL_SANDBOX_DELETE_INSTANCE_FAILED,
} from '../redux/actions/action-types';

import { showErrorMessages } from '../redux/actions';

// TODO: update en.json file

const ErrorHandlingMiddleware = ({ dispatch }) => {
  // TODO: Create some method for catching all action types that end with Error.. not just the errors found here
  // Also Clean this up

  return (next) => {
    return (action) => {
      const errorMessageCodes = [];

      switch (action.type) {
        // Projects Error Handling
        case PROJECTS_GET_ALL_FAILED:
          errorMessageCodes.push(['item.get.all.error', { item: '$t(project.label)' }]);
          errorMessageCodes.push(['unexpected.error.reason', {}]);
          break;
        case PROJECTS_ADD_FAILED:
          // errorMessageCodes.push(["item.add.error", { item: '$t(project.label)' }]);
          // if (action.error.code === 1) errorMessageCodes.push(["item.not.created.trust.questions.not.published.error.reason", { item: '$t(project.label)' }])
          // else if (action.error.status === 409) errorMessageCodes.push(["item.name.already.exists.error.reason", { item: '$t(project.label)' }]);
          // else errorMessageCodes.push(["unexpected.error.reason", {}]);
          break;
        case PROJECTS_DELETE_FAILED:
          // errorMessageCodes.push(["item.delete.error", { item: '$t(project.label)' }]);
          // errorMessageCodes.push(["unexpected.error.reason", {}]);
          break;
        case PROJECTS_UPDATE_FAILED:
          // errorMessageCodes.push(["item.update.error", { item: '$t(project.label)' }]);
          // errorMessageCodes.push(["unexpected.error.reason", {}]);
          break;

        // Trust Categories & Project Trust Categories
        case TRUST_CATEGORIES_GET_FAILED:
        case PROJECT_TRUST_CATEGORY_GET_ALL_FAILED:
          errorMessageCodes.push(['item.get.all.error', { item: '$t(category.label)' }]);
          errorMessageCodes.push(['unexpected.error.reason', {}]);
          break;

        case TRUST_CATEGORIES_ADD_FAILED:
        case PROJECT_TRUST_CATEGORY_ADD_FAILED:
          errorMessageCodes.push(['item.add.error', { item: '$t(category.label)' }]);
          errorMessageCodes.push(['unexpected.error.reason', {}]);
          break;

        case TRUST_CATEGORIES_DELETE_FAILED:
        case PROJECT_TRUST_CATEGORY_DELETE_FAILED:
          errorMessageCodes.push(['item.delete.error', {}]);
          errorMessageCodes.push(['unexpected.error.reason', { item: '$t(category.label)' }]);
          break;

        case PROJECT_TRUST_CATEGORY_UPDATE_FAILED:
          errorMessageCodes.push(['item.update.error', { item: '$t(category.label)' }]);
          errorMessageCodes.push(['unexpected.error.reason', {}]);
          break;

        // Project Trust Questions
        case TRUST_QUESTIONS_GET_FAILED:
        case PROJECT_TRUST_QUESTIONS_GET_ALL_FAILED:
          errorMessageCodes.push(['item.get.all.error', { item: '$t(question.label)' }]);
          errorMessageCodes.push(['unexpected.error.reason', {}]);
          break;
        case PROJECT_TRUST_QUESTIONS_ADD_FAILED:
          errorMessageCodes.push(['item.add.error', { item: '$t(question.label)' }]);
          errorMessageCodes.push(['unexpected.error.reason', {}]);
          break;

        case PROJECT_TRUST_QUESTIONS_DELETE_FAILED:
          errorMessageCodes.push(['item.delete.error', { item: '$t(question.label)' }]);
          errorMessageCodes.push(['unexpected.error.reason', {}]);
          break;

        case PROJECT_TRUST_QUESTIONS_UPDATE_FAILED:
          errorMessageCodes.push(['item.update.error', { item: '$t(question.label)' }]);
          errorMessageCodes.push(['unexpected.error.reason', {}]);
          break;

        // Models
        case MODELS_GET_ALL_FAILED:
          errorMessageCodes.push(['item.get.all.error', { item: '$t(model.label)' }]);
          errorMessageCodes.push(['unexpected.error.reason', {}]);
          break;
        case MODELS_ADD_FAILED:
          errorMessageCodes.push(['item.add.error', { item: '$t(model.label)' }]);
          if (action.error.code === 1) errorMessageCodes.push(['item.not.created.trust.questions.not.published.error.reason', { item: '$t(model.label)' }]);
          else if (action.error.status === 409) errorMessageCodes.push(['item.name.already.exists.error.reason', { item: '$t(model.label)' }]);
          else errorMessageCodes.push(['unexpected.error.reason', {}]);
          break;
        case MODELS_DELETE_FAILED:
          errorMessageCodes.push(['item.delete.error', { item: '$t(model.label)' }]);
          errorMessageCodes.push(['unexpected.error.reason', {}]);
          break;
        case MODELS_UPDATE_FAILED:
          errorMessageCodes.push(['item.update.error', { item: '$t(model.label)' }]);
          errorMessageCodes.push(['unexpected.error.reason', {}]);
          break;
        case MODEL_ONLY_ONE_ERROR:
          errorMessageCodes.push(['item.add.error', { item: '$t(model.label)' }]);
          errorMessageCodes.push(['model.only.one.error.reason', {}]);
          break;
        case MODEL_SELECT_ONE_ERROR:
          errorMessageCodes.push(['cannot.preform.action.error', {}]);
          errorMessageCodes.push(['item.select.one.error.reason', { item: '$t(model.label)' }]);
          break;

        // MTI Scores
        case MTI_SCORES_GET_FAILED:
          errorMessageCodes.push(['item.get.error', { item: '$t(mti.score.label)' }]);
          errorMessageCodes.push(['unexpected.error.reason', {}]);
          break;

        // Trust Questions
        case TRUST_QUESTIONS_PUBLISH_FAILED:
          errorMessageCodes.push(['trust.questions.publish.error', {}]);
          errorMessageCodes.push(['unexpected.error.reason', {}]);
          break;

        // Alert Setup
        case PROJECT_TRUST_QUESTION_ALERT_GET_FAILED:
          errorMessageCodes.push(['item.get.error', { item: '$t(alert.label)' }]);
          errorMessageCodes.push(['unexpected.error.reason', {}]);
          break;
        case PROJECT_TRUST_QUESTION_ALERT_UPDATE_FAILED:
          errorMessageCodes.push(['alert.update.error', { item: '$t(alert.label)' }]);
          errorMessageCodes.push(['unexpected.error.reason', {}]);
          break;

        // Tool Analysis Setup
        case PROJECT_TRUST_QUESTION_TOOL_ANALYSIS_GET_FAILED:
          errorMessageCodes.push(['item.get.error', { item: '$t(tool.analysis.label)' }]);
          errorMessageCodes.push(['unexpected.error.reason', {}]);
          break;

        // Project Compliance Assessments
        case COMPLIANCE_ASSESSMENT_GET_FAILED:
          errorMessageCodes.push(['item.get.error', { item: '$t(compliance.assessment.label)' }]);
          errorMessageCodes.push(['unexpected.error.reason', {}]);
          break;
        case COMPLIANCE_ASSESSMENT_ADD_FAILED:
          errorMessageCodes.push(['item.add.error', { item: '$t(compliance.assessment.label)' }]);
          errorMessageCodes.push(['unexpected.error.reason', {}]);
          break;
        case COMPLIANCE_ASSESSMENT_UPDATE_FAILED:
          errorMessageCodes.push(['item.update.error', { item: '$t(compliance.assessment.label)' }]);
          errorMessageCodes.push(['unexpected.error.reason', {}]);
          break;
        case COMPLIANCE_ASSESSMENT_ALREADY_PUBLISHED_ERROR: //
          errorMessageCodes.push(['error.label', {}]);
          errorMessageCodes.push(['compliance.assessment.already.published', {}]);
          break;

        // Model Trust Questions Survey
        case MODEL_TRUST_QUESTIONS_SURVEY_COMPLETE_FAILED: //
          errorMessageCodes.push(['model.trust.questions.survey.complete.error', {}]);
          errorMessageCodes.push(['unexpected.error.reason', {}]);
          break;

        case TRUST_TOOL_SANDBOX_NO_CONFIGURATION_NAME_ERROR: //
          errorMessageCodes.push(['missing.required.field.error', {}]);
          errorMessageCodes.push(['please.enter.configuration.name.message', {}]);
          break;

        case TRUST_TOOL_SANDBOX_DELETE_INSTANCE_FAILED:
          errorMessageCodes.push(['item.delete.error', { item: '$t(configuration.label)' }]);
          if (action.error.code === 1) errorMessageCodes.push(['configuration.is.mapped.reason', {}]);
          else errorMessageCodes.push(['unexpected.error.reason', {}]);
          break;

        default:
          break;
      }

      if (errorMessageCodes.length) {
        dispatch(showErrorMessages(errorMessageCodes));
      }
      return next(action);
    };
  };
};

export default ErrorHandlingMiddleware;
