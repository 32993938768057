/* eslint-disable */
/*
 * File: ProjectDashboard.jsx
 * Project: mtp-webui
 * File Created: Tuesday, 4th May 2021 3:55:07 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Tuesday, 26th October 2021 11:13:09 am
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { animated, useSpring } from '@react-spring/web';
import { setSelectedScorecard, addProject, getAllScorecardScores } from '../../redux/actions';
import { ProjectDropdownSelector } from '../../components/dropdown';
import { ScorecardScoresContainer } from '../../components/container';
import { AIAChart } from '../../components/charts';
import * as urlUtils from '../../utils/url';

const ProjectDashboard = () => {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const project = useSelector((state) => state.projects.selected) || null;
  const scorecardsLastFetchTime = useSelector((state) => state.scorecards.lastFetchTime) || null;
  const scorecard = useSelector((state) => state.scorecards.selected) || null;

  let scoresInfo = useSelector((state) => state.scorecardScores) || null;
  scoresInfo = scoresInfo && scorecard ? scoresInfo[scorecard.uuid] : null;

  useEffect(() => {
    if (project && !scorecard) dispatch(setSelectedScorecard(project.scorecardUuid));
  }, [scorecard, project, scorecardsLastFetchTime, dispatch]);

  useEffect(() => {
    //TODO: Fix this, the action gets called twice
    // Check if the project has a scorecard if it does make sure that the selected scorecard is the correct one
    // If it is not then we set the selected scorecard using the projects scorecard uuid
    if (project && project.scorecardUuid && scorecard && project.scorecardUuid !== scorecard.uuid) {
      dispatch(setSelectedScorecard(project.scorecardUuid));
    }
  }, [dispatch, project, scorecard]);

  useEffect(() => {
    // Get the scorecard scores
    if (scorecard && scorecard.uuid && !scoresInfo) dispatch(getAllScorecardScores(scorecard.uuid));
  }, [scorecard, dispatch, scoresInfo]);

  const aia = project && project.aia ? project.aia : null;

  return (
    <div className="container-fluid">
      {/* <div> */}
      <ProjectOptions />

      <div className="dashboard">
        {scorecard ? <ScorecardScoresContainer scorecard={scorecard} scoresInfo={scoresInfo} /> : null}
        {aia ? <AIAChart assessment={aia} link={`${path}/algorithmic-impact-assessment`} /> : null}
      </div>
    </div>
  );
};

export default ProjectDashboard;

const ProjectOptions = ({ project }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const params = urlUtils.searchStr2Obj(location.search);

  const { x } = useSpring({
    from: { x: 0 },
    x: error ? 1 : 0,
    reset: true,
  });

  const inputID = 'add-project-name-input';

  const [showAddProject, setShowAddProject] = useState(false);
  const [name, setName] = useState('');

  const handleKeyDown = (e) => {
    if (error) setError(false);

    if (e.key === 'Enter') {
      dispatch(addProject({ name: name })).then((r) => {
        if (r.errorCode === 1) {
          setError(true);
        } else if (r.project) {
          setName('');
          setShowAddProject(false);

          // Change the selected project to the newly created project
          history.push(
            `${location.pathname}${urlUtils.searchObj2Str({
              ...params,
              ...{ project: r.project.uuid },
            })}`,
          );
        }
      });
    }
  };

  const handleChange = (e) => {
    setName(e.target.value);
    if (error) {
      setError(false);
    }
  };

  const handleCancel = (e) => {
    setShowAddProject(false);
  };

  const handleBlur = (e) => {
    if (!e.relatedTarget || (e.relatedTarget && e.relatedTarget.id !== inputID)) {
      setShowAddProject(false);
    }
    e.preventDefault();
  };

  return (
    <div className="project-dropdown-options">
      <div className="project-controls">
        <div className="btn-group project-btns">
          <button className="btn btn-secondary" title={t('add.item.label', { item: '$t(project.label)' })} onClick={() => setShowAddProject(!showAddProject)} onBlur={handleBlur}>
            <i className="fas fa-plus"></i>
          </button>
          <ProjectDropdownSelector
          // disabled={showAddProject}
          />
        </div>
        {/* <ProjectCycle cycle={3} /> */}
      </div>

      {/* </div> */}
      <div id={`add-project-item`} className={`add-project-item ${showAddProject ? '' : 'd-none'}`}>
        <animated.input
          type="text"
          style={{
            transform: x
              .to({
                range: [0, 0.15, 0.25, 0.35, 0.55, 0.65, 0.75, 0.85, 1],
                output: [0, -15, 0, 15, 0, -15, 0, 15, 0],
              })
              .to((x) => `translate3d(${x}px, 0px, 0px)`),
          }}
          className={`form-control shadow-br ${error ? 'invalid' : ''}`}
          id={inputID}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          value={name}
          placeholder={t('item.name.label', { item: '$t(project.label)' })}
          onBlur={handleCancel}></animated.input>
      </div>
    </div>
  );
};
