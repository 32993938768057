import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
// import { SUPPORTED_ROLES } from '../constants';
import { Welcome, LoadingPage, ErrorMessages, IdleLogout } from '../components/overlays';

import { USER } from '../constants';
import ConsentForm from '../pages/ConsentForm';
import { setApplicationRoleContext } from '../redux/actions';
import Routes from '../routes';

import type { RootState } from '../redux/store';
import type { UIState } from '../redux/reducers/UIStateReducer';
import type { UserState } from '../redux/reducers/UserReducer';

const Layout = (): JSX.Element => {
  const dispatch = useDispatch();
  const { forcedLogoutTime, displayError, errorMessageCodes, appRoleContext } = useSelector<RootState>((state) => state.uiState) as UIState;
  const user = useSelector<RootState>((state) => state.user) as UserState;
  const privacyTermsConditionsState = user.privacyTermsConditions.state;

  const [acceptedConsent, setAcceptedConsent] = useState<boolean>(privacyTermsConditionsState === USER.CONSENT_FORM.ACCEPTED);

  useEffect(() => {
    setAcceptedConsent(privacyTermsConditionsState === USER.CONSENT_FORM.ACCEPTED);
  }, [privacyTermsConditionsState]);

  useEffect(() => {
    if (!appRoleContext) dispatch(setApplicationRoleContext(user.roles[0]));
  }, [appRoleContext, dispatch, user.roles]);

  return (
    <div className="App">
      <LoadingPage />
      {user && !user.welcomed && <Welcome />}
      {forcedLogoutTime && <IdleLogout expiryTimestamp={forcedLogoutTime} />}
      {errorMessageCodes && <ErrorMessages />}
      {displayError && <Redirect to="/error" />}

      {!acceptedConsent ? <ConsentForm /> : <Routes />}
    </div>
  );
};

export default Layout;
