import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import Auth from './pages/Auth';
import './styles/index.css';
import './styles/index.scss';

require('./i18n');

const App = (): JSX.Element => {
  return (
    <Provider store={store}>
      <Suspense fallback={<div className="auth-background" />}>
        <Auth />
      </Suspense>
    </Provider>
  );
};

export default App;
