/**
 * File: HelpButton.js
 * Project: Machine Trust Platform
 * File Created: Sunday, 13th June 2021 4:35:55 pm
 * Author: Rafal Rybak (rafal.rybak@nuenergy.ai)
 * -----
 * Last Modified: Sunday, 13th June 2021 4:35:55 pm
 * Modified By: Rafal Rybak (rafal.rybak@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

type HelpButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  outerClassName?: string;
};

const HelpButton = ({ onClick = () => null, outerClassName = '' }: HelpButtonProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={outerClassName}>
      <button type="button" className="circle-icon-reverse md-shadow-4dp btn icon-btn" title={t('servicedrawer.help.label')} onClick={onClick}>
        <i className="fas fa-info-circle" />
      </button>
    </div>
  );
};

HelpButton.propTypes = {
  onClick: PropTypes.func,
  outerClassName: PropTypes.string,
};
HelpButton.defaultProps = {
  onClick: () => null,
  outerClassName: '',
};

export default HelpButton;
