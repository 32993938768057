import {
  // Projects
  // PROJECTS_GET_ALL_FAILED,
  MODEL_TRUST_QUESTIONS_SURVEY_COMPLETE_SUCCESS,
  CUSTOM_POPUP_MESSAGE,
  AIGS_SCORECARD_SCORE_SUBMIT_SUCCESS,
  AIGS_SCORECARD_SCORE_SUBMIT_FAILED,
  AIGS_SCORECARD_SCORE_UPDATE_SUC,
  AIGS_SCORECARD_SCORE_UPDATE_FAIL,
} from '../redux/actions/action-types';

/* eslint-disable */
import { showErrorMessages } from '../redux/actions';

// TODO: update en.json file

const PopupMessageMiddleware = ({ dispatch }) => {
  return (next) => {
    return (action) => {
      const { label, body } = action;

      const popupMessageCodes = [];
      switch (action.type) {
        case MODEL_TRUST_QUESTIONS_SURVEY_COMPLETE_SUCCESS:
          popupMessageCodes.push(['success.label']);
          popupMessageCodes.push(['survey.complete.successfully.label']);
          break;
        case CUSTOM_POPUP_MESSAGE:
          popupMessageCodes.push(label);
          popupMessageCodes.push(body);
          break;
        case AIGS_SCORECARD_SCORE_UPDATE_SUC:
        case AIGS_SCORECARD_SCORE_SUBMIT_SUCCESS:
          popupMessageCodes.push(['save.success.label']);
          popupMessageCodes.push(['questionnaire.save.success.message']);
          break;
        case AIGS_SCORECARD_SCORE_UPDATE_FAIL:
        case AIGS_SCORECARD_SCORE_SUBMIT_FAILED:
          popupMessageCodes.push(['save.fail.label']);
          popupMessageCodes.push(['questionnaire.save.fail.message']);
          break;
        default:
          break;
      }
      if (popupMessageCodes.length) {
        dispatch(showErrorMessages(popupMessageCodes));
      }
      return next(action);
    };
  };
};

export default PopupMessageMiddleware;
