/* eslint-disable */
import {
  CONFIG_GET_REQUEST,
  CONFIG_GET_SUCCESS,
  // CONFIG_GET_FAILED,
  DISPLAY_ERROR_MESSAGE,
  APP_SET_ROLE_CONTEXT,
  RESET_INACTIVITY_TIMER,
  APP_SHOW_ERROR_MESSAGES,
  TOGGLE_NAV_DRAWER,
  CUSTOM_POPUP_MESSAGE,
  SET_TUTORIAL_STATE,
} from './action-types';

import {
  // APP_CONFIG_URI,
  INACTIVITY_TIMEOUT,
} from '../../constants';
import config from '../../config.json';

export const displayErrorMessage = (msgId, msgArgs) => {
  return { type: DISPLAY_ERROR_MESSAGE, ...{ msgId, msgArgs } };
};

export const setApplicationRoleContext = (role) => {
  return { type: APP_SET_ROLE_CONTEXT, ...{ role } };
};

export const resetInactivityTimer = (onTimeout, timeout = INACTIVITY_TIMEOUT) => {
  return {
    type: RESET_INACTIVITY_TIMER,
    inactivityTimer: setTimeout(onTimeout, timeout),
  };
};

export const showErrorMessages = (errorMessageCodes) => {
  return { type: APP_SHOW_ERROR_MESSAGES, errorMessageCodes };
};

export const toggleNavDrawer = () => {
  return { type: TOGGLE_NAV_DRAWER };
};

export const setTutorialState = (state) => {
  return { type: SET_TUTORIAL_STATE, isTutorialOpen: state };
};

export const customPopupMessage = (label, body) => {
  return { type: CUSTOM_POPUP_MESSAGE, label, body };
};
