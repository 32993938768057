/**
 * File: index.ts
 * Project: Machine Trust Platform
 * File Created: Friday, 14th May 2021 11:36:40 am
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Thursday, 20th May 2021 1:24:49 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai>)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React, { useState, useEffect } from 'react';
// import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';

import ScorecardContainer from '../ScorecardContainer';

import { flushCachedAnswers, getAnswers } from '../../../utils/localStorage/surveyLocalStorage';
import { SCORECARD } from '../../../constants';
import { getAllScorecardScores, setSelectedScorecardScore } from '../../../redux/actions';
import type { RootState } from '../../../redux/store';
import type { ScoreReducerState, ScoreState } from '../../../redux/reducers/ScorecardScoresReducer';

import buildScorecardSurvey from '../../../utils/surveyBuilder';
// import { DownloadPDF } from '../../buttons';

import SurveyContainer from './SurveyContainer';

import SurveyControlButtons from './SurveyControlButtons';

import type { Scorecard, Requirement, Question, Score } from '../../../types';

interface ScorecardQuestionnaireType {
  title: string;
  scorecard: Scorecard;
  requirementList?: Requirement[];
  questionList?: Question[];
}

const ScorecardQuestionnaire = ({ title = '', scorecard, requirementList = undefined, questionList = undefined }: ScorecardQuestionnaireType) => {
  // const { t } = useTranslation();
  const dispatch = useDispatch();

  const loading = useSelector<RootState>((state) => state.uiState.loading);

  const [isLoading, setIsLoading] = useState(true)

  // Json object containing the Questionnaire Content
  const [json, setJson] = useState(undefined);

  // Boolean Value to monitor weather a question has been answered or not (used for the top control buttons)
  const [hasAnswers, setHasAnswers] = useState(getAnswers(scorecard ? scorecard.uuid : undefined) !== undefined);
  const [currentPage, setCurrentPage] = useState<number>(0); // Tracks the current page of the survey so that the page does not reset on renders

  // Draft info
  // const [selectedDraft, setSelectedDraft] = useState<Score | undefined>(undefined);

  // The list of all the drafts for the draft dropdown
  const scorecardScoreRecoredsState: ScoreReducerState | undefined = (useSelector<RootState>((state) => state.scorecardScores) as ScoreReducerState) || undefined;
  const scorecardScoreState: ScoreState | undefined = scorecardScoreRecoredsState && scorecardScoreRecoredsState[scorecard.uuid] ? scorecardScoreRecoredsState[scorecard.uuid] : undefined;
  const scorecardDraftList: Score[] | undefined = scorecardScoreState && scorecardScoreState.list ? scorecardScoreState.list.filter((s) => s.state === SCORECARD.SCORE.STATE.DRAFT) : undefined;

  const selectedDraft = scorecardScoreState && scorecardScoreState.selected && scorecardScoreState.selected.state === SCORECARD.SCORE.STATE.DRAFT ? scorecardScoreState.selected : undefined;

  /**
   *
   */
  /* eslint-disable */
  const clearSurvey = (e: any) => {
    // flushCachedAnswers(scorecard.uuid)
    flushCachedAnswers(scorecard.uuid);
    setHasAnswers(false);
    setCurrentPage(0);
    dispatch(setSelectedScorecardScore(scorecard.uuid, undefined));
    e.preventDefault();
  };

  useEffect(() => {
    if (scorecard && !scorecardDraftList) dispatch(getAllScorecardScores(scorecard.uuid));
  }, [scorecard, dispatch, scorecardDraftList]);

  useEffect(() => {
    (async () => {
      if (scorecard && requirementList && questionList) {
        const newJson = await buildScorecardSurvey(scorecard, requirementList, questionList).then((survey) => survey);
        setJson(newJson);
      }
    })();
  }, [scorecard, requirementList, questionList]);

  useEffect(() => {
    if (selectedDraft) setHasAnswers(selectedDraft !== undefined);
  }, [selectedDraft]);

  useEffect(() => {
    if (json && scorecard && !loading) {
      setTimeout(() => setIsLoading(false), 1000)
    }
    
  }, [json, scorecard, loading])

  return (
    <>
      <SurveyControlButtons scorecard={scorecard} hasAnswers={hasAnswers} clearSurvey={clearSurvey} />

      <ScorecardContainer title={title}>
        {!isLoading && <SurveyContainer json={json} scorecard={scorecard} currentPage={currentPage} setCurrentPage={setCurrentPage} hasAnswers={hasAnswers} setHasAnswers={setHasAnswers} />}
        {isLoading &&
          <div className="d-flex justify-content-center">
            <Spinner animation="grow" />
          </div>
        }
      </ScorecardContainer>
      {/* <DownloadPDF title={`${scorecard.title}-results`} elementID="nuenergy-questionnaire" /> */}
    </>
  );
};

ScorecardQuestionnaire.propTypes = {
  title: PropTypes.string,
  /* eslint-disable */
  scorecard: PropTypes.object.isRequired,
  /* eslint-disable */
  requirementList: PropTypes.array,
  /* eslint-disable */
  questionList: PropTypes.array,
};
ScorecardQuestionnaire.defaultProps = {
  title: '',
  requirementList: undefined,
  questionList: undefined,
};

export default ScorecardQuestionnaire;
