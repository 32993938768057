/*
 * File: Development.tsx
 * Project: Machine Trust Platform
 * File Created: Tuesday, 22nd June 2021 9:19:05 am
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Tuesday, 22nd June 2021 9:25:54 am
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React from 'react';
import PropTypes from 'prop-types';
import type { CycleProps } from './CycleTypes';

const Development = ({ color = '#20A8D8', size = 50 }: CycleProps): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox={`0 0 ${size} ${size}`}>
      <path
        fill={color}
        d="M25 12.5v6.25l8.333-8.333L25 2.083v6.25C15.792 8.333 8.333 15.792 8.333 25c0 3.27.959 6.313 2.584 8.875l3.041-3.042A12.23 12.23 0 0112.5 25c0-6.896 5.604-12.5 12.5-12.5zm14.083 3.625l-3.041 3.042A12.539 12.539 0 0137.5 25c0 6.896-5.604 12.5-12.5 12.5v-6.25l-8.333 8.333L25 47.917v-6.25c9.208 0 16.667-7.459 16.667-16.667 0-3.27-.959-6.313-2.584-8.875z"
      />
      <path
        fill={color}
        d="M30.355 26.561c.027-.243.045-.497.045-.76s-.018-.517-.054-.76l1.521-1.288a.426.426 0 00.09-.497l-1.44-2.701c-.09-.176-.279-.234-.44-.176l-1.792.78a5.192 5.192 0 00-1.215-.76l-.27-2.068a.37.37 0 00-.36-.331h-2.88c-.18 0-.324.137-.351.331l-.27 2.068a5.33 5.33 0 00-1.215.76l-1.791-.78c-.162-.068-.351 0-.441.176l-1.44 2.7a.399.399 0 00.09.498l1.52 1.287a5.3 5.3 0 00-.062.76c0 .254.018.518.054.761l-1.521 1.288a.426.426 0 00-.09.497l1.44 2.7c.09.176.279.235.44.176l1.792-.78c.378.312.774.566 1.215.76l.27 2.068c.036.195.18.332.36.332h2.88c.18 0 .333-.137.351-.332l.27-2.067a5.326 5.326 0 001.215-.76l1.791.78c.162.068.351 0 .441-.176l1.44-2.701a.399.399 0 00-.09-.497l-1.503-1.288zM25 28.726c-1.485 0-2.7-1.316-2.7-2.925 0-1.61 1.215-2.925 2.7-2.925s2.7 1.316 2.7 2.925c0 1.609-1.215 2.925-2.7 2.925z"
      />
    </svg>
  );
};
Development.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
Development.defaultProps = {
  color: '#20A8D8',
  size: 50,
};

export default Development;
