import { v1 as uuidv1 } from 'uuid';

export const cacheTokens = (authTokens) => {
  const tokens = JSON.stringify(authTokens);
  window.localStorage.setItem('authTokens', tokens);
};

export const getCachedTokens = () => {
  const authTokens = window.localStorage.getItem('authTokens');
  if (!authTokens) return null;
  return JSON.parse(authTokens);
};

export const flushCachedTokens = () => {
  delete window.localStorage.authTokens;
};

export const generateAuthState = () => {
  const uuid = uuidv1();
  const location = window.location.href;
  const authState = btoa(JSON.stringify({ uuid, location }));
  window.localStorage.authState = authState;
  return authState;
};

export const getCachedAuthState = () => {
  const { authState } = window.localStorage;
  return authState || null;
};

export const flushCachedAuthState = () => {
  delete window.localStorage.authState;
};
