/*
 * File: Welcome.jsx
 * Project: Machine Trust Platform
 * File Created: Wednesday, 19th May 2021 3:25:26 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Thursday, 24th June 2021 5:00:15 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import {NuEnergyBase64Logo} from '../Icons'
import { useSpring, animated, config } from '@react-spring/web';
import { updateUser } from '../../redux/actions';
import type { RootState } from '../../redux/store';
import type { User } from '../../types';

const Welcome = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector<RootState>((state) => state.user) as User;
  const name = user.givenName.charAt(0).toUpperCase() + user.givenName.slice(1);

  const spring = useSpring({
    from: { y: '250%', opacity: 0 },
    to: async (next) => {
      await next({ y: '0', opacity: 1 });
    },
    delay: 1800,
    config: config.slow,
  });

  const springText = useSpring({
    from: { y: '0', opacity: 0 },
    to: async (next) => {
      await next({ y: '0', opacity: 1 });
    },
    delay: 2000,
    config: config.slow,
  });

  const springUser = useSpring({
    from: {
      y: '0',
      x: '0',
      opacity: 1,
      display: 'static',
    },
    to: async (next) => {
      await next({ opacity: 0 });
      await next({ display: 'none' });
    },
    config: config.slow,
    delay: 1000,
  });

  const welcomed = useCallback(() => {
    dispatch(updateUser({ welcomed: true }));
  }, [dispatch]);

  useEffect(() => {
    document.getElementById('welcome')?.focus();
    document.getElementById('welcome')?.addEventListener('keydown', welcomed);
    return () => {
      if (document.getElementById('welcome')) document.getElementById('welcome')?.removeEventListener('keydown', welcomed);
    };
  }, [welcomed]);

  return (
    <div className="welcome" id="welcome" role="button" onClick={welcomed} onTouchEnd={welcomed} onKeyDown={welcomed} tabIndex={-1}>
      <div className="welcome__container">
        {/* <div className="welcome__header"></div> */}
        <animated.h1 style={springUser}>{t('hello.message', { user: name })}</animated.h1>
        <animated.div style={springText}>
          <h1>{t('welcome.label')}</h1>
          {/* <h2>Artificial Intelligence (AI) is powerful. Trust in AI is critical.</h2> */}
          <h3>{t('welcome.message')}</h3>
        </animated.div>
        <animated.span style={springText}>{t('press.any.key.message')}</animated.span>
        <animated.img style={spring} src="/images/logo-nuenergy.png" />
      </div>
    </div>
  );
};

export default Welcome;
