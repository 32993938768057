/* eslint-disable */
/**
 * File: project-actions.js
 * Project: mtp-webui
 * File Created: Tuesday, 4th May 2021 3:32:27 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Wednesday, 7th July 2021 3:06:58 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import client from '../../utils/api/client-helper';
import { restfulAction, ACTION_TYPE } from './actions-api';
import { PROJECTS_SET_SELECTED, PROJECT_SUBMIT_ASSESSMENT_REQ, PROJECT_SUBMIT_ASSESSMENT_SUC, PROJECT_SUBMIT_ASSESSMENT_FAIL } from './action-types';

const { GET, GET_ALL, UPDATE, ADD, DELETE } = ACTION_TYPE;

const projectAction = ({ type, uuid = null, project = null }) => {
  const actionCallback = (data) => {
    return type === GET_ALL ? { projectList: data } : type === DELETE ? { projectUuid: uuid } : { project: data };
  };

  let actionConfig = {
    typeName: 'PROJECTS',
    type,
    subdir: `/project/${type === GET_ALL || type === ADD ? '' : uuid}`,
    uuid,
    obj: project,
    options: {
      objectName: 'project',
      callback: actionCallback,
    },
  };

  return restfulAction(actionConfig);
};

export const getAllProjects = () => projectAction({ type: GET_ALL });
export const addProject = (project) => projectAction({ type: ADD, project });
export const getProject = (projectUuid) => projectAction({ type: GET, uuid: projectUuid });
export const deleteProject = (projectUuid) => projectAction({ type: GET, uuid: projectUuid });
export const updateProject = (projectUuid, project) => projectAction({ type: UPDATE, uuid: projectUuid, project: project });
export const selectProject = (project) => ({
  type: PROJECTS_SET_SELECTED,
  project,
});

/**
 *  Submit Project AIA
 * @returns response data or Null
 */
export const submitProjectAIA = (project, assessment) => {
  return (dispatch, getState) => {
    dispatch({
      type: PROJECT_SUBMIT_ASSESSMENT_REQ,
      projectUuid: project.uuid,
    });

    const idToken = getState().authTokens.id_token;
    // const user = getState().user;
    let data_config = {
      idToken,
      // user,
      config: {
        method: 'POST',
      },
      data: assessment,
    };

    return client(`/project/${project.uuid}/aia`, data_config)
      .then((response) => {
        dispatch({
          type: PROJECT_SUBMIT_ASSESSMENT_SUC,
          project: response.data,
        });
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: PROJECT_SUBMIT_ASSESSMENT_FAIL,
          projectUuid: project.uuid,
        });
        return null;
      });
  };
};
