/* eslint-disable */
import axios from 'axios';
import settings from '../../config.json';

/**
 *  Works only when communicating to the django server
 * @param {string} subdir  the path for the api call
 * @param {object} user    the current user that will be used to verify the users permissions
 * @param {object} object  this contains the data sent with a post for example or the custom configurations
 */
const client = async (subdir, { user, idToken, data, config } = {}) => {
  return axios({
    url: `${settings.django_basepath}${subdir}`,
    method: data ? 'POST' : 'GET',
    data: data ? JSON.stringify(data) : undefined,
    headers: {
      // 'x-nuenergy': user ? (process.env.NODE_ENV === 'development' ? `${user.username}` : `${btoa(user.username)}`) : undefined,
      Authorization: `Bearer ${idToken}`,
      Accept: 'application/json',
      'content-type': 'application/json',
    },
    ...config,
  });
};

export default client;
