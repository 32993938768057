/**
 * File: type.ts
 * Project: Machine Trust Platform
 * File Created: Saturday, 28th August 2021 4:47:02 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Tuesday, 31st August 2021 11:52:49 am
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
import { OrganizationTier, Role } from '../../types';

// The MTPPath enum is to represent the first portion (www.example.com/MTPPath/more/stuff) of the url this usually
// indicates what service (Projects, Tools) the user is currently reviewing
// Example use case is to determine the content to be displayed in the drawer.
export enum MTPPath {
  AIGS = '/aigs',
  PROJECTS = '/projects',
  TOOLS = '/tools',
  STANDARDS = '/standards',
  MTI = '/mti',
}

export interface NavigationLink {
  label: string | Function;
  title: string; // This is what we see when we hover over the link
  icon: string;
  path: string;
  // Roles allowed to access this link
  roles?: Role[];
  // The Organization Tier allowed to Access this Link
  tier: OrganizationTier;
  // For the Tutorial selector
  dataTut?: string;
}
export interface ServiceLink extends NavigationLink {
  path: MTPPath;
}

export interface DrawerLink extends Omit<NavigationLink, 'icon'> {
  id?: string;

  // If the icon is undefined then text must be defined and vice versa
  // Text replaces the icon for example AIA
  icon?: string;
  text?: string | Function;
}

export type DrawerMap = { [path in MTPPath]: DrawerLink[] };
