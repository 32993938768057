/* eslint-disable */
/*
 * File: ScorecardScoresTable.jsx
 * Project: mtp-webui
 * File Created: Wednesday, 28th April 2021 3:57:43 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Thursday, 16th September 2021 4:18:45 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CDataTable } from '@coreui/react';
import { createDateTimeLabel } from '../../utils/timeUtils';
import { SCORECARD } from '../../constants';
// import { Dropdown } from 'react-bootstrap'
import Collapsible from '../animated/Collapsible';

import { updateScorecardScore, deleteScorecardScore } from '../../redux/actions';
import { useHistory } from 'react-router-dom';

const ScorecardScoresTable = (props) => {
  const { scorecardUuid = null, path = null, scores = null, tableClasses = '' } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // This list of details that are open
  const [details, setDetails] = useState([]);

  // The users current role
  const appRoleContext = useSelector((state) => state.uiState.appRoleContext);

  // Add up the total weight of each requirement used to calculate the percentage
  let totalRequirementWeight = scores && scores.length > 0 && scores[0] && scores[0].requirements ? scores[0].requirements.map((r) => r.weight).reduce((a, b) => a + b, 0) : 0;

  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };

  const fields = [
    {
      key: 'score',
      _style: { width: '10%', textAlign: 'center' },
      sorter: true,

    },
    { key: 'createdBy', _style: { width: '10%' } },
    { key: 'created', _style: { width: '10%' } },
    { key: 'state', _style: { width: '10%', textAlign: 'center' } },
    {
      key: 'show_details',
      label: '',
      _style: { width: '1%' },
      sorter: false,
      filter: false,
    },
    {
      key: 'delete',
      label: '',
      _style: { width: '1%' },
      sorter: false,
      filter: false,
    },
  ];

  const handleSelectScoreState = (score, state) => dispatch(updateScorecardScore(scorecardUuid, { uuid: score.uuid, state }));
  const handleDeleteScore = (score) => dispatch(deleteScorecardScore(scorecardUuid, score.uuid));

  return (
    <div className="scorecard-scores-table" data-tut="scores-table">
      <CDataTable
        items={scores}
        fields={fields}
        addTableClasses={`${tableClasses}`}
        columnFilter
        footer
        itemsPerPage={25}
        hover
        size="lg"
        sorter
        pagination
        scopedSlots={{
          score: (item, index) => <td className="text-center">{Math.round(item.score)}</td>,

          // Handles the state of the scorecard
          // TODO: Fix this so that if the score card is in pending the it is a button to approve the score
          state: (item, index) => (
            <td className=" d-flex justify-content-center">
              {['admin'].includes(appRoleContext) && item.state !== SCORECARD.SCORE.STATE.DRAFT ? (
                <div className="btn-group dropleft">
                  <button type="button" className="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {t(SCORECARD.SCORE.getState(item.state))}
                  </button>
                  <div className="dropdown-menu">
                    {Object.values(SCORECARD.SCORE.STATE).map((s, k) =>
                      s !== SCORECARD.SCORE.STATE.DRAFT ? (
                        <button className={`dropdown-item ${s === item.state ? 'active' : ''}`} key={k} disabled={s === SCORECARD.SCORE.STATE.DRAFT} onClick={() => handleSelectScoreState(item, s)}>
                          {t(SCORECARD.SCORE.getState(s))}
                        </button>
                      ) : null,
                    )}
                  </div>
                </div>
              ) : (
                t(SCORECARD.SCORE.getState(item.state))
              )}
            </td>
          ),
          created: (item, index) => <td>{createDateTimeLabel(new Date(item.created))}</td>,

          show_details: (item, index) => (
            <td>
              {item.state !== SCORECARD.SCORE.STATE.DRAFT ? (
                <button
                  type="button"
                  className="btn btn-secondary"
                  disabled={item.state === SCORECARD.SCORE.STATE.DRAFT}
                  onClick={() => {
                    toggleDetails(index);
                  }}>
                  {details.includes(index) ? 'Hide' : 'Review'}
                </button>
              ) : null}
            </td>
          ),
          details: (item, index) => (
            <Collapsible toggle={details.includes(index)}>
              <ScoreDetailsSection scorecardUuid={scorecardUuid} path={path} score={item} totalRequirementWeight={totalRequirementWeight} />
            </Collapsible>
          ),
          delete: (item, index) => (
            <td className="py-2">
              <button type="button" className="btn btn-danger" onClick={() => handleDeleteScore(item)}>
                <i className="fas fa-trash-alt"></i>
              </button>
            </td>
          ),
        }}></CDataTable>
    </div>
  );
};

export default ScorecardScoresTable;

const ScoreDetailsSection = ({ scorecardUuid, score, totalRequirementWeight, path }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleReviewResultsClick = (e) => {
    history.push(`${path}/questionnaire/results?scorecard=${scorecardUuid}&score=${score.uuid}`);
    e.preventDefault();
  };
  return (
    <div className="score-table-details-section">
      <div className="container">
        <div className="row">
          {score.requirements.map((r, i) => (
            <div key={i} className="requirement-details col-sm">
              <p className="req-name-weight">
                <span>{r.requirement.name}</span> <br />
                {`${t('weight.label')}: ${Math.round((r.weight / totalRequirementWeight) * 100)}%`}
              </p>
              <div className="req-score">
                <span>
                  {Math.round(r.score)} <br />
                </span>
                {t('score.label')}
              </div>
            </div>
          ))}
        </div>
        {path ? (
          <div className="row">
            <div className="req-btn-container">
              <button className="btn btn-primary" type="button" onClick={handleReviewResultsClick}>
                {t('review.results.label')}
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
