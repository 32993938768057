/* eslint-disable */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedScorecard, getAIGSScorecard, getAllScorecardScores, getScorecardBenchmark } from '../../redux/actions';
import { ScorecardScoresContainer } from '../../components/container';

const Dashboard = () => {
  const dispatch = useDispatch();

  // Here we're getting the scorecard twice - this is to make sure that the selected scorecard is properly set.
  const selectedScorecard = useSelector((state) => state.scorecards.selected) || null;
  const scorecard =
    useSelector((state) =>
      // Check if the scorecard has already been selected and ensure that its AIGS (avoid the filter if possible)
      state.scorecards.selected && state.scorecards.selected.type === 'AIGS'
        ? state.scorecards.selected
        : // The selected Scorecard was not what we're looking for so grab the AIGS scorecard
        state.scorecards.list && state.scorecards.list.length > 0
        ? state.scorecards.list.filter((s) => s.type === 'AIGS')[0] || null
        : null,
    ) || null;

  let scoresInfo = useSelector((state) => state.scorecardScores) || null;
  scoresInfo = scoresInfo && scorecard ? scoresInfo[scorecard.uuid] : null;

  const benchmark = useSelector((state) => state.getScorecardBenchmark);

  useEffect(() => {
    if (!scorecard) dispatch(getAIGSScorecard());
  }, [scorecard, dispatch]);

  // Make sure the selected Scorecard is the same as the scorecard
  useEffect(() => {
    if (scorecard && scorecard !== selectedScorecard) dispatch(setSelectedScorecard(scorecard));
  }, [scorecard, selectedScorecard, dispatch]);

  useEffect(() => {
    // Get the scorecard scores
    if (scorecard && !scoresInfo) dispatch(getAllScorecardScores(scorecard.uuid));
  }, [scorecard, dispatch, scoresInfo])

  return <div className="dashboard container-fluid">{scorecard ? <ScorecardScoresContainer scorecard={scorecard} scoresInfo={scoresInfo} benchmark={benchmark} /> : null}</div>;
};

export default Dashboard;
