/* eslint-disable */
/**
 * File: actions-api.js
 * Project: mtp-webui
 * File Created: Tuesday, 4th May 2021 3:19:43 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Tuesday, 31st August 2021 2:41:08 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import client from '../../utils/api/client-helper';
// import { useAppDispatch } from '../../hooks';
import { RootState, AppDispatch } from '../store';

const GET_ALL = 'GET_ALL';
const GET = 'GET';
const ADD = 'ADD';
const DELETE = 'DELETE';
const UPDATE = 'UPDATE';

export type ActionType = 'GET_ALL' | 'GET' | 'ADD' | 'DELETE' | 'UPDATE';

export const ACTION_TYPE = {
  GET_ALL,
  GET,
  ADD,
  DELETE,
  UPDATE,
};

/**
 * Regular causes the full page to show the loading
 * Short does not
 */
export interface ActionTails {
  request: 'REQUEST' | 'REQ';
  success: 'SUCCESS' | 'SUC';
  failed: 'FAILED' | 'FAIL';
}

interface tails {
  regular: ActionTails;
  short: ActionTails;
}

export const ACTION_TAILS: tails = {
  regular: {
    request: 'REQUEST',
    success: 'SUCCESS',
    failed: 'FAILED',
  },
  short: {
    request: 'REQ',
    success: 'SUC',
    failed: 'FAIL',
  },
};

interface RestfulActionParam {
  typeName: string;
  type: string;
  subdir: string;
  uuid?: string;
  obj?: any;
  options: {
    objectName: string;
    callback: Function;
    actionTails: ActionTails;
    userInfo?: {
      username?: string;
      uuid?: string;
      familyName?: string;
      givenName?: string;
      email?: string;
    };
    requestFailDispatch?: any;
  };
}

/**
 *
 * @param {string} typeName the action type name for example 'AI_GOVERNANCE_SCORECARD'
 * @param {string} type the action type example 'GET_ALL'
 * @param {string} subdir the url path
 * @param {string} objectName the name of the object example 'scorecard'
 * @param {function} callback a call back function that returns the payload variables this must accept the response.data object. It is used to define the variable names to be sent to the reducer
 * @param {string} uuid  the uuid for a given object example scorecardUuid
 * @param {*} obj the actual object being updated or added (do not include the uuid)
 */
export const restfulAction = ({ typeName, type, subdir, uuid = undefined, obj = undefined, options: { objectName, callback, actionTails = ACTION_TAILS.regular, userInfo = undefined, requestFailDispatch = undefined } }: RestfulActionParam) => {
  // Error handling
  if ((type === DELETE || type === UPDATE || type === GET) && uuid === undefined) throw Error(`${objectName}Uuid can not be null for action type ${type}`);
  else if ((type === ADD || type === UPDATE) && obj === undefined) throw Error(`${objectName} can not be null for action type ${type}`);

  return (dispatch: AppDispatch, getState: () => RootState) => {
    // Example of action type "AUTH_AUTHENTICATE_USER_REQUEST" or "AUTH_AUTHENTICATE_USER_REQ"
    dispatch({
      type: `${typeName}_${type}_${actionTails.request}`,
      ...requestFailDispatch,
    });

    const { user } = getState();

    const data = { ...obj };
    if (userInfo) {
      for (const [key, value] of Object.entries(userInfo)) {
        data[value as string] = user.hasOwnProperty(key) ? (user as any)[key] : undefined;
      }
    }

    // Specify Config and Data if the type is DELETE, ADD or UPDATE
    const dataConfig = type === DELETE ? { config: { method: DELETE } } : (type === UPDATE || type === ADD) && obj != null ? { data } : {};

    const idToken = getState().authTokens.id_token;

    const config = {
      idToken,
      ...dataConfig,
    };

    return client(subdir, config)
      .then((response) => {
        // Specify the payload data name for each type for readability in reducers
        const payload = callback(response.data);
        dispatch({
          type: `${typeName}_${type}_${actionTails.success}`,
          ...payload,
        });

        return payload;
      })
      .catch((error) => {
        console.error(error.response);
        dispatch({
          type: `${typeName}_${type}_${actionTails.failed}`,
          ...requestFailDispatch,
        });
        return error.response.data;
      });
  };
};
