/*
 * File: Scores.tutorial.jsx
 * Project: Machine Trust Platform
 * File Created: Monday, 14th June 2021 3:31:27 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Tuesday, 22nd June 2021 3:37:38 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React from 'react';
import CustomTour from '../CustomTour';
import Tutorial from '../TutorialContainer';
// import { useTranslation } from 'react-i18next'

const ScoresTutorial = (): JSX.Element => {
  const STEPS = [
    {
      selector: '[data-tut="scores-table"]',
      content: () => <Tutorial header="Score Management Table">Here you can review and manage your results - If you have any. This is also where you or your Admin will be able approve and pending scores. Approved Scores get displayed on the dashboard.</Tutorial>,
    },
  ];
  return <CustomTour steps={STEPS} />;
};

export default ScoresTutorial;
