/*
 * File: Questionnaire.tutorial.jsx
 * Project: Machine Trust Platform
 * File Created: Monday, 14th June 2021 3:31:02 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Tuesday, 22nd June 2021 3:36:43 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomTour from '../CustomTour';
import Tutorial from '../TutorialContainer';

const QuestionnaireTutorial = (): JSX.Element => {
  const STEPS = [
    {
      selector: '#nuenergy-questionnaire',

      content: () => <StepOne />,
    },
    {
      selector: '#nuenergy-questionnaire',
      content: 'Try selecting an answer to one of the questions and press the save on the bottom right hand corner. (The save will only appear once there is something to be saved)',
      action: () => {
        // Temporary fix for the Questionnaire control buttons being our of view
        const main = document.getElementById('main');
        if (main) main.scrollTop = 0;
      },
    },
    {
      selector: '[data-tut="control-buttons"]',
      content: () => <Tutorial header="Questionnaire Controls">Notice you can select the draft you just saved and you can clear your answers to start fresh.</Tutorial>,
      action: () => {
        // Temporary fix for the Questionnaire control buttons being our of view
        const main = document.getElementById('main');
        if (main) main.scrollTop = 0;
      },
    },
    {
      selector: '#aigs-scores-drawer-btn',
      content: () => <Tutorial header="Approving and Managing your Scores">The final step to populating the dashboard is approving your completed AIGS (AI Governance Scorecard) Results.</Tutorial>,
      action: () => {},
    },
  ];
  return <CustomTour steps={STEPS} update={document.getElementById('main')?.scrollTop} />;
};

export default QuestionnaireTutorial;

const StepOne = () => {
  const { t } = useTranslation();

  return <Tutorial header="AI Governance Scorecard (AIGS) Questionnaire ">{t('aigs.questionnaire.tutorial.step.1')}</Tutorial>;
};
