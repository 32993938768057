import { combineReducers } from 'redux';
import AuthTokensReducer from './AuthTokensReducer';
import UIStateReducer from './UIStateReducer';
import UserReducer from './UserReducer';

import ProjectReducer from './ProjectReducer';

import ScorecardReducer from './ScorecardReducer';

import ScorecardRequirementReducer from './ScorecardRequirementsReducer';
import ScorecardQuestionReducer from './ScorecardQuestionsReducer';
import ScorecardScores from './ScorecardScoresReducer';

import BenchmarkReducer from './BenchmarkReducer';

const rootReducer = combineReducers({
  authTokens: AuthTokensReducer,
  uiState: UIStateReducer,

  user: UserReducer,
  projects: ProjectReducer,
  scorecards: ScorecardReducer,
  scorecardScores: ScorecardScores,

  scorecardRequirements: ScorecardRequirementReducer,
  scorecardQuestions: ScorecardQuestionReducer,
  benchmark: BenchmarkReducer,
});
export default rootReducer;
