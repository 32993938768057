/**
 * File: index.js
 * Project: mtp-webui
 * File Created: Tuesday, 11th May 2021 5:09:19 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Wednesday, 12th May 2021 5:41:11 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai>)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import Dashboard from './Dashboard';
import AIA from './AIA';

const Project = {
  Dashboard,
  AIA,
};

export default Project;
