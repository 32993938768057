/*
 * File: Benchmark.tsx
 * Project: Machine Trust Platform
 * File Created: Wednesday, 1st September 2021 10:24:32 am
 * Author: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Last Modified: Wednesday, 8th September 2021 1:28:49 pm
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getScorecardBenchmark, clearBenchmark } from '../../redux/actions/benchmark-actions';

import type { ScorecardType, Score, Organization } from '../../types';
import type { RootState } from '../../redux/store';
import { Spinner } from 'react-bootstrap';

interface Props {
  score?: Score;
  type?: ScorecardType;
}

//TODO: Requirment Score

const Benchmark = ({ score, type }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const benchmarkScore = useSelector<RootState>((state) => state.benchmark.score); //gets the benchmark global state
  const organization = useSelector<RootState>((state) => state.user.organization) as Organization; //gets the organization global state
  const loading = useSelector<RootState>((state) => state.benchmark.loading);
  const lastFetchTime = useSelector<RootState>((state) => state.benchmark.lastFetchTime);

  //checks if the benchmark has a score or is empty
  const hasBenchmarkScore = lastFetchTime !== -1 && benchmarkScore;

  useEffect(() => {
    //gets the benchmark scores
    if (score && type && !benchmarkScore) {
      dispatch(getScorecardBenchmark(type));
    } else {
      return () => {
        //clears the score from other score card when switching pages
        dispatch(clearBenchmark());
      };
    }
  }, [dispatch, score, type, benchmarkScore]); // only renders when one of these change

  return (
    <>
      {/* loader shows when requesting for benchmark score once the request is successful then scores are displayed */}
      {loading && <Spinner className="benchmark-spinner" animation="border" />}
      {!loading && (
        <>
          {/* once loading is done and there are no scores then the no scores message is displayed */}
          {!hasBenchmarkScore && (
            <div className="container">
              <h3 className="header">{t('benchmark.header')}</h3>
              <div className="no-benchmark-score">{t('benchmark.no.score')}</div>
            </div>
          )}
          {/* once loading is done and there is a score then the benchmark score is displayed */}
          {hasBenchmarkScore && (
            <div className="container">
              <h3 className="header">{t('benchmark.header')}</h3>
              <div className="row">
                <div className="col-6">
                  <div className="organization-score">{score + '%'}</div>
                  <span className="organization-name">{organization.name}</span>
                </div>
                <div className="col-6">
                  <div className="organization-score">{benchmarkScore + '%'}</div>
                  <div>
                    <span className="organization-name">{t('benchmark.label')}</span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Benchmark;
