/**
 * File: Collapsible.js
 * Project: mtp-webui
 * File Created: Thursday, 18th February 2021 5:08:36 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Wednesday, 28th April 2021 12:07:23 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai>)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useMeasure, usePrevious } from '../../hooks';

const Collapsible = ({ toggle = false, children }) => {
  const [bind, { height: viewHeight }] = useMeasure();
  const previous = usePrevious(toggle);

  const { height, opacity } = useSpring({
    from: { height: 0, opacity: 0 },
    to: { height: toggle ? viewHeight : 0, opacity: toggle ? 1 : 0 },
  });

  return (
    <animated.div
      className="collapsible"
      style={{
        position: 'relative',
        opacity,
        height: toggle && previous === toggle ? 'auto' : height,
        zIndex: toggle ? 0 : -100,
      }}>
      {/* eslint-disable react/jsx-props-no-spreading */}
      <animated.div {...bind}>{children}</animated.div>
    </animated.div>
  );
};

export default Collapsible;
