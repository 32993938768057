/*
 * File: TrustTools.jsx
 * Project: Machine Trust Platform
 * File Created: Tuesday, 27th July 2021 1:32:03 pm
 * Author: Rafal Rybak (rafal.rybak@nuenergy.ai)
 * -----
 * Last Modified: Wednesday, 25th August 2021 2:31:07 pm
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
import React from 'react';
import ConfigureMessage from '../../components/container/ConfigureMessage';

const TrustTools = () => {
  return <ConfigureMessage />;
};

export default TrustTools;
