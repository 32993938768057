/*
 * File: index.tsx
 * Project: Machine Trust Platform
 * File Created: Saturday, 28th August 2021 4:35:36 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Tuesday, 31st August 2021 3:03:44 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
import React from 'react';
import { useDispatch } from 'react-redux';
import { HelpButton } from '../../../../components/buttons';
import { setTutorialState } from '../../../../redux/actions';
import ServiceButton from './ServiceNavButton';
import { ServiceLink } from '../../types';

interface Props {
  services: ServiceLink[];
}

const ServiceNav = ({ services }: Props) => {
  const dispatch = useDispatch();

  const handleHelpClick = () => {
    dispatch(setTutorialState(true));
  };

  return (
    <div className="service-nav">
      {services.map((s) => (
        <ServiceButton service={s} key={s.title} />
      ))}
      <HelpButton onClick={handleHelpClick} outerClassName="service-help-btn" />
    </div>
  );
};

export default ServiceNav;
