/*
 * File: MTI.route.jsx
 * Project: Machine Trust Platform
 * File Created: Tuesday, 27th July 2021 1:48:37 pm
 * Author: Rafal Rybak (rafal.rybak@nuenergy.ai)
 * -----
 * Last Modified: Wednesday, 28th July 2021 9:35:01 pm
 * Modified By: Rafal Rybak (rafal.rybak@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { MTI } from '../pages';

const MTIRoute = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`} exact component={MTI.MTIDemo} />
      <Route path={`${path}/wiki`} component={MTI.WikiDemo} />
    </Switch>
  );
};

export default MTIRoute;
