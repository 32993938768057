/* eslint-disable */
export const searchStr2Obj = (searchStr) => {
  return searchStr
    .replace(/^\?/, '')
    .split('&')
    .filter((p) => p.length > 0)
    .reduce((o, p) => {
      const pArray = p.split('=');
      o[pArray[0]] = pArray[1] ? pArray[1] : '';
      return o;
    }, {});
};

export const searchObj2Str = (searchObj) => {
  const str = Object.keys(searchObj)
    .map((p) => `${p}=${searchObj[p]}`)
    .join('&');
  return str.length > 0 ? `?${str}` : str;
};
