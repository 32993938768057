/*
 * File: ConfigureMessage.jsx
 * Project: Machine Trust Platform
 * File Created: Wednesday, 25th August 2021 2:20:58 pm
 * Author: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Last Modified: Friday, 27th August 2021 4:24:15 pm
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
import React from 'react';

// import { useDispatch } from 'react-redux';
import DemoContainer from './DemoContainer';

const contactSupport = {
  fontSize: '20px',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
};

const ConfigureMessage = () => {
  // const dispatch = useDispatch();
  return (
    <DemoContainer>
      <p>Configurable on request.</p>
      <a style={contactSupport} href="https://www.nuenergy.ai/contact/" className="btn btn-md align-self-end">
        <p>Contact support@nuenergy.ai</p>
      </a>
    </DemoContainer>
  );
};

export default ConfigureMessage;
