/**
 * File: ScorecardQuestionsReducer.ts
 * Project: Machine Trust Platform
 * File Created: Monday, 21st June 2021 12:13:43 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Thursday, 24th June 2021 3:58:50 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
import type { Reducer } from 'redux';
import type { Question } from '../../types';
import {
  // AIGS Question
  AIGS_QUESTION_GET_ALL_SUCCESS,
  AIGS_QUESTION_ADD_SUCCESS,
  AIGS_QUESTION_GET_SUCCESS,
  AIGS_QUESTION_DELETE_SUCCESS,
  AIGS_QUESTION_UPDATE_SUCCESS,
  AIGS_QUESTION_SET_SELECTED,
} from '../actions/action-types';

interface QuestionReducerState {
  list: Question[];
  selected?: string;
  lastFetchTime: number;
  userLastModTime: number;
}

type QuestionReducerAction = {
  type: string;
  questionList?: Question[];
  question?: Question;
  questionUuid?: string;
};

const questionsInitialState: QuestionReducerState = {
  list: [],
  selected: undefined,
  lastFetchTime: -1,
  userLastModTime: -1,
};

/** AI Governance Scorecard Question Reducer
 * This handles the actual questions separate from the Scorecard.
 */
const ScorecardQuestionReducer: Reducer<QuestionReducerState, QuestionReducerAction> = (state = questionsInitialState, action) => {
  const { questionList, question, questionUuid } = action;

  switch (action.type) {
    case AIGS_QUESTION_GET_ALL_SUCCESS:
      return {
        ...state,
        list: questionList || [],
        lastFetchTime: new Date().getTime(),
      };
    case AIGS_QUESTION_ADD_SUCCESS:
      return {
        ...state,
        list: [...state.list, question as Question],
        userLastModTime: new Date().getTime(),
        selected: question?.uuid,
      };

    case AIGS_QUESTION_UPDATE_SUCCESS:
    case AIGS_QUESTION_GET_SUCCESS:
      return {
        ...state,
        list: state.list.map((q) => (q.uuid === (question as Question).uuid ? (question as Question) : q)),
        userLastModTime: new Date().getTime(),
      };
    case AIGS_QUESTION_DELETE_SUCCESS:
      return {
        ...state,
        list: state.list.filter((q) => q.uuid !== questionUuid),
        userLastModTime: new Date().getTime(),
        selected: state.selected === questionUuid ? undefined : state.selected,
      };
    case AIGS_QUESTION_SET_SELECTED:
      return {
        ...state,
        selected: questionUuid,
      };

    default:
      return state;
  }
};

export type { QuestionReducerState };
export default ScorecardQuestionReducer;
