/**
 * File: index.ts
 * Project: Machine Trust Platform
 * File Created: Tuesday, 27th July 2021 1:43:41 pm
 * Author: Rafal Rybak (rafal.rybak@nuenergy.ai)
 * -----
 * Last Modified: Tuesday, 27th July 2021 1:43:41 pm
 * Modified By: Rafal Rybak (rafal.rybak@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
import MTIDemo from './MTIDemo';
import WikiDemo from './WikiDemo';

const MTI = {
  MTIDemo,
  WikiDemo,
};

export default MTI;
