/**
 * File: ProjectsReducer.js
 * Project: mtp-webui
 * File Created: Tuesday, 4th May 2021 3:45:03 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Friday, 9th July 2021 3:12:09 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
import { Reducer } from 'redux';
import type { Project } from '../../types';
import {
  PROJECTS_GET_ALL_SUCCESS,
  PROJECTS_ADD_SUCCESS,
  PROJECTS_UPDATE_SUCCESS,
  PROJECTS_DELETE_SUCCESS,
  // PROJECT_SUBMIT_ASSESSMENT_REQ,
  PROJECT_SUBMIT_ASSESSMENT_SUC,
  // PROJECT_SUBMIT_ASSESSMENT_FAIL,
  PROJECTS_SET_SELECTED,
} from '../actions/action-types';

/* 1. Project Reducer ===========================================
 * 
 * list             - list of all project objects
 * selected         - string of uuid
 * lastFetchTime    - 
 * userLastModTime  - 
_____________________________________________ */

interface ProjectState {
  list: Array<Project>;
  selected?: Project | null;
  lastFetchTime: number;
  userLastModTime: number;
}
interface ProjectAction {
  type: string;
  projectList: Array<Project>;
  project: Project;
  projectUuid: string;
}

const projectInitialState: ProjectState = {
  list: [],
  selected: undefined,
  lastFetchTime: -1,
  userLastModTime: -1,
};

const ProjectReducer: Reducer<ProjectState, ProjectAction> = (state: ProjectState = projectInitialState, action: ProjectAction) => {
  const { projectList, project, projectUuid } = action;

  switch (action.type) {
    case PROJECTS_GET_ALL_SUCCESS:
      return {
        ...state,
        list: projectList || [],
        lastFetchTime: new Date().getTime(),
      };
    case PROJECTS_ADD_SUCCESS:
      return {
        ...state,
        list: [...state.list, project],
        userLastModTime: new Date().getTime(),
        selected: project,
      };

    case PROJECTS_UPDATE_SUCCESS:
      return {
        ...state,
        list: state.list.map((p) => (project && p.uuid === project.uuid ? project : p)),
        selected: project,
        userLastModTime: new Date().getTime(),
      };
    case PROJECTS_DELETE_SUCCESS:
      return {
        ...state,
        list: state.list.filter((p) => p.uuid !== projectUuid),
        userLastModTime: new Date().getTime(),
        selected: state.selected && state.selected.uuid === projectUuid ? null : state.selected,
      };

    case PROJECTS_SET_SELECTED:
      return {
        ...state,
        selected: project,
      };

    case PROJECT_SUBMIT_ASSESSMENT_SUC:
      return {
        ...state,
        list: state.list.map((p) => (project && p.uuid === project.uuid ? project : p)),
        selected: project,
        userLastModTime: new Date().getTime(),
      };

    default:
      return state;
  }
};

export type { ProjectState as ProjectReducerState };
export default ProjectReducer;
