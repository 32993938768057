/*
 * File: AIMSQuestionnairtRoute.jsx
 * Project: mtp-webui
 * File Created: Tuesday, 11th May 2021 4:11:07 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Friday, 27th August 2021 11:21:22 am
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Scorecard } from '../pages';

const Questionnaire = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}`} exact component={Scorecard.Questionnaire} />
      <Route path={`${path}/results`} component={Scorecard.QuestionnaireResults} />
    </Switch>
  );
};

export default Questionnaire;
