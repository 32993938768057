/**
 * File: index.js
 * Project: mtp-webui
 * File Created: Tuesday, 11th May 2021 5:06:00 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Wednesday, 25th August 2021 12:18:02 pm
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import Dashboard from './Dashboard';
import Canvas from './Canvas';
import Measurement from './Measurement';

const AIGS = {
  Dashboard,
  Canvas,
  Measurement,
};

export default AIGS;
