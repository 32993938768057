import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { logout, toggleNavDrawer } from '../../../redux/actions';
// import { useSpring, animated } from 'r@react-spring/web'
// import Search from '../../components/animated/Search'

// import UserRoleSelect from './UserRoleSelect';

const NavBar = () => {
  const user = useSelector((state) => state.user);
  const appRoleContext = useSelector((state) => state.uiState.appRoleContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const toggleSidebar = () => {
    dispatch(toggleNavDrawer());
  };
  // const servicesStyles = useSpring({, height: open ? '100%':''})

  return (
    <header className="navbar navbar-expand-md fixed-top md-shadow-4dp">
      <a className="nav-burger" role="button" onClick={toggleSidebar} onKeyPress={() => null} tabIndex={0}>
        <i className="fa fa-bars" />
      </a>

      <a href="/" className="navbar-brand">
        <img src={`${process.env.PUBLIC_URL}/images/logo-nuenergy.png`} alt="NuEnergy" />
        {t('mtp.label')}
      </a>

      <div className="navbar-nav ml-auto">
        {/* <li className="nav-item">
                    <div className="navbar-btn-container">
                        <a role="button" title={'Blah blah blah'}>AIA</a>
                        <a href="/#" role="button"><i className="fas fa-search"></i></a>
                        <a href="/#" data-toggle="dropdown" role="button"><i className="far fa-bell"></i></a>
                        <a href="/#" role="button"><i className="fas fa-cog"></i></a>
                        <Search /> 
                    </div>
                </li> */}

        <div className="nav-item dropdown">
          <button className="nav-link account-dropdown btn btn-secondary dropdown-toggle" type="button" id="userMenuButton" data-toggle="dropdown" data-bs-toggle="dropdown" aria-expanded="false">
            {/* <div className="circle-icon">{(user.givenName && user.givenName.length > 0 ? user.givenName.charAt(0) : '') + (user.familyName && user.familyName.length > 0 ? user.familyName.charAt(0) : '')}</div> */}
            <div className="account-dropdown-name">{`${user.givenName && user.givenName.length > 0 ? user.givenName : ''}  ${user.familyName && user.familyName.length > 0 ? user.familyName.charAt(0) : ''}`}</div>
          </button>
          <ul className="dropdown-menu dropdown-menu-right dropdown-menu-lg-end md-shadow-8dp" aria-labelledby="userMenuButton">
            <li>
              {/* <h4 className="dropdown-header">{t(`perspective.label`)}:</h4> */}
              <h4 className="dropdown-header">{`${t('version.label')} ${process.env.REACT_APP_VERSION}`}</h4>
            </li>
            {/* {user.roles.length > 1 ? <UserRoleSelect /> : <span>{t(`role.${appRoleContext}`)}</span>} */}

            <li>
              <div className="dropdown-divider" />
            </li>
            <li>
              <a className="dropdown-item" href="/#" onClick={() => dispatch(logout())}>
                <i className="fas fa-sign-out-alt" />
                <span>Logout</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default NavBar;
