/**
 * File: surveyLocalStorage.js
 * Project: mtp-webui
 * File Created: Thursday, 15th April 2021 3:40:12 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Monday, 21st June 2021 11:47:49 am
 * Modified By: Tarek Sanger (tarek@nuenergy.ai>)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

const QUESTIONNAIRE_KEY = 'QuestionnaireAnswers';
const CANVAS_KEY = 'CanvasAnswers';

export const cacheAnswers = (scorecardUuid, answers) => {
  const stringify = JSON.stringify(answers);
  window.localStorage.setItem(`${scorecardUuid}${QUESTIONNAIRE_KEY}`, stringify);
};

export const flushCachedAnswers = (scorecardUuid) => {
  delete window.localStorage[`${scorecardUuid}${QUESTIONNAIRE_KEY}`];
};

export const getAnswers = (scorecardUuid) => {
  const answers = window.localStorage.getItem(`${scorecardUuid}${QUESTIONNAIRE_KEY}`);
  return answers ? JSON.parse(answers) : null;
};

export const cacheCanvasAnswers = (answers) => {
  const stringify = JSON.stringify(answers);
  window.localStorage.setItem(CANVAS_KEY, stringify);
};

export const flushCachedCanvasAnswers = () => {
  delete window.localStorage[CANVAS_KEY];
};

export const getCanvasAnswers = () => {
  const answers = window.localStorage.getItem(CANVAS_KEY);
  return answers ? JSON.parse(answers) : null;
};
