import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { AIGS, Scorecard } from '../pages';
import Questionnaire from './Questionnaire.route';
import { setSelectedScorecard } from '../redux/actions';

const AIGSRoute = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setSelectedScorecard(null));
    };
  }, [dispatch]);

  return (
    <>
      {/* {tutorialStage === 0 ? <Redirect to={`${path}/questionnaire`} /> : null} */}
      <Switch>
        <Route path={`${path}`} exact component={AIGS.Dashboard} />
        <Route path={`${path}/canvas`} component={AIGS.Canvas} />

        <Route path={`${path}/questionnaire`} component={Questionnaire} />
        <Route path={`${path}/scores`}>
          <Scorecard.Scores path={path} />
        </Route>
        <Route path={`${path}/measurement`} component={AIGS.Measurement} />
      </Switch>
    </>
  );
};

export default AIGSRoute;
