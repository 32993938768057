/**
 * File: index.ts
 * Project: Machine Trust Platform
 * File Created: Tuesday, 27th July 2021 1:25:43 pm
 * Author: Rafal Rybak (rafal.rybak@nuenergy.ai)
 * -----
 * Last Modified: Friday, 20th August 2021 12:06:35 pm
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
import TrustTools from './TrustTools';
import Fairness360 from './Fairness360';
import Aequitas from './Aequitas';
import Lime from './Lime';
import Fairlearn from './Fairlearn';
import Whatif from './Whatif';

const Tools = {
  TrustTools,
  Fairness360,
  Aequitas,
  Fairlearn,
  Lime,
  Whatif,
};

export default Tools;
