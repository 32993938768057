/**
 * File: ScorecardScoresReducer.ts
 * Project: Machine Trust Platform
 * File Created: Monday, 21st June 2021 12:14:15 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Thursday, 2nd September 2021 1:00:15 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import { Reducer } from 'redux';
import {
  // Scorecard Answers
  AIGS_SCORECARD_SCORE_SUBMIT_SUCCESS,

  // AIGS_SCORECARD_SCORE_GET_ALL_REQ,
  AIGS_SCORECARD_SCORE_GET_ALL_SUCCESS,
  // AIGS_SCORECARD_SCORE_GET_ALL_FAIL,
  AIGS_SCORECARD_SCORE_UPDATE_SUC,
  AIGS_SCORECARD_SCORE_UPDATE_REQ,
  AIGS_SCORECARD_SCORE_UPDATE_FAIL,
  AIGS_SCORECARD_SCORE_DELETE_SUC,
  AIGS_SCORECARD_SCORE_DELETE_REQ,
  AIGS_SCORECARD_SCORE_DELETE_FAIL,
  AIGS_SCORECARD_SCORE_SET_SELECTED,
} from '../actions/action-types';

import type { Score } from '../../types';

interface ScoreState {
  list?: Score[];
  selected?: Score;
  lastFetchTime?: number;
  userLastModTime?: number;
  loading?: boolean;
}

type ScorecardUuid = string;
type ScoreReducerState = Record<ScorecardUuid, ScoreState>;

interface ScoreReducerAction {
  type: string;
  score?: Score;
  scoreList?: Score[];
  scorecardUuid: string;
  scorecardScoreUuid?: string;
}

const scorecardScoresInitialState: ScoreReducerState = {
  // list: null,
  // selected: null,
  // lastFetchTime: -1,
  // userLastModTime: -1,
};

const ScorecardScoresReducer: Reducer<ScoreReducerState, ScoreReducerAction> = (state = scorecardScoresInitialState, action) => {
  const { score, scoreList, scorecardUuid, scorecardScoreUuid } = action;

  switch (action.type) {
    case AIGS_SCORECARD_SCORE_DELETE_REQ:
    case AIGS_SCORECARD_SCORE_UPDATE_REQ:
      return {
        ...state,
        [scorecardUuid]: {
          ...state[scorecardUuid],
          loading: true,
        },
      };

    case AIGS_SCORECARD_SCORE_DELETE_FAIL:
    case AIGS_SCORECARD_SCORE_UPDATE_FAIL:
      return {
        ...state,
        [scorecardUuid]: {
          ...state[scorecardUuid],
          loading: false,
        },
      };
    case AIGS_SCORECARD_SCORE_SUBMIT_SUCCESS:
      return {
        ...state,
        [scorecardUuid]: {
          ...state[scorecardUuid],
          list: state[scorecardUuid].list !== undefined && (state[scorecardUuid].list as Score[]).length > 0 ? [score as Score, ...(state[scorecardUuid].list as Score[])] : [score as Score],
          lastFetchTime: new Date().getTime(),
          loading: false,
          selected: score,
        },
      };
    case AIGS_SCORECARD_SCORE_GET_ALL_SUCCESS:
      return {
        ...state,
        [scorecardUuid]: {
          list: scoreList?.sort((a, b) => new Date(b.modified).getTime() - new Date(a.modified).getTime()),
          lastFetchTime: new Date().getTime(),
          loading: false,
        },
      };

    case AIGS_SCORECARD_SCORE_UPDATE_SUC:
      return {
        ...state,
        [scorecardUuid]: {
          list: state[scorecardUuid].list ? state[scorecardUuid].list?.map((s) => (s.uuid === (score as Score).uuid ? (score as Score) : s)).sort((a, b) => new Date(b.modified).getTime() - new Date(a.modified).getTime()) : [score as Score],
          lastFetchTime: new Date().getTime(),
          loading: false,
          selected: score,
        },
      };

    case AIGS_SCORECARD_SCORE_DELETE_SUC:
      return {
        ...state,
        [scorecardUuid]: {
          list: state[scorecardUuid].list?.filter((s) => s.uuid !== (scorecardScoreUuid as string)),
          lastFetchTime: new Date().getTime(),
          loading: false,
          selected: undefined,
        },
      };
    case AIGS_SCORECARD_SCORE_SET_SELECTED:
      return {
        ...state,
        [scorecardUuid]: {
          ...state[scorecardUuid],
          selected: score,
        },
      };

    default:
      return state;
  }
};

export type { ScoreReducerState, ScoreState };
export default ScorecardScoresReducer;
