/*
 * File: Deployment.tsx
 * Project: Machine Trust Platform
 * File Created: Tuesday, 22nd June 2021 9:18:23 am
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Tuesday, 22nd June 2021 9:35:57 am
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React from 'react';
import PropTypes from 'prop-types';
import type { CycleProps } from './CycleTypes';

const Deployment = ({ color = '#20A8D8', size = 50 }: CycleProps): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox={`0 0 ${size} ${size}`}>
      <path
        fill={color}
        d="M45.833 2.778H30.695a1.39 1.39 0 000 2.778h11.847l-12.25 12.5A1.39 1.39 0 0032.278 20L44.444 7.583v11.722a1.389 1.389 0 002.778 0V4.167a1.389 1.389 0 00-1.389-1.39zM16.028 14.903l-12.5 7.18a1.39 1.39 0 00-.695 1.209v15.277a1.389 1.389 0 00.695 1.209l12.5 7.153a1.39 1.39 0 001.389 0l12.5-7.153a1.39 1.39 0 00.694-1.209V23.292a1.39 1.39 0 00-.694-1.209l-12.5-7.18a1.389 1.389 0 00-1.39 0zm-.75 28.264L5.556 37.61v-12l9.722 5.556v12zm1.389-14L6.68 23.43l9.986-5.681 10.014 5.722-10.014 5.695zm11.11 8.458l-9.721 5.556V31.167l9.722-5.556v12.014z"
      />
    </svg>
  );
};

Deployment.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
Deployment.defaultProps = {
  color: '#20A8D8',
  size: 50,
};

export default Deployment;
