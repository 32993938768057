/*
 * File: Dashboard.tutorial.jsx
 * Project: Machine Trust Platform
 * File Created: Monday, 14th June 2021 3:30:29 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Thursday, 26th August 2021 12:38:51 pm
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomTour from '../CustomTour';
import Tutorial from '../TutorialContainer';

const DashboardTutorial = (): JSX.Element => {
  const STEPS = [
    {
      selector: '#projects-aims-questionnaire-drawer-btn',
      content: () => <StepOne />,
    },
  ];

  return <CustomTour steps={STEPS} />;
};

export default DashboardTutorial;

function StepOne() {
  const { t } = useTranslation();

  return <Tutorial header={t('AIMS.dashboard.tutorial.label')}>{t('AIMS.dashboard.tutorial.step.1')}</Tutorial>;
}
