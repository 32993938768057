import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * Icon Button
 * @param {string} iconClass  https://fontawesome.com/icons?d=gallery
 */
type IconButtonProps = {
  title: string;
  className?: string;
  iconClass: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  link?: string;
  reverse: boolean;
  disabled: boolean;
};

const IconButton = ({ title, className = '', iconClass, link = '', onClick = undefined, reverse = false, disabled = false }: IconButtonProps): JSX.Element => {
  if (!title) throw new Error('IconButton is missing mandatory title Property.');

  if (link && !disabled) {
    return (
      <Link to={link} title={title}>
        <div className={`${reverse ? 'circle-icon-reverse' : 'circle-icon'} md-shadow-4dp btn icon-btn  ${className}`}>
          <i className={iconClass} />
        </div>
      </Link>
    );
  }

  return (
    <button type="button" title={title} disabled={disabled} className={`${reverse ? 'circle-icon-reverse' : 'circle-icon'} md-shadow-4dp btn icon-btn ${className}`} onClick={onClick}>
      <i className={iconClass} />
    </button>
  );
};

IconButton.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  iconClass: PropTypes.string,
  onClick: PropTypes.func,
  link: PropTypes.string,
  reverse: PropTypes.bool,
  disabled: PropTypes.bool,
};
IconButton.defaultProps = {
  className: '',
  iconClass: '',
  onClick: () => null,
  link: '',
  reverse: false,
  disabled: false,
};

export default IconButton;
