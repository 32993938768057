/*
 * File: ProjectRoute.jsx
 * Project: mtp-webui
 * File Created: Tuesday, 4th May 2021 4:45:45 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Wednesday, 25th August 2021 2:39:44 pm
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProjects, getAllScorecards } from '../redux/actions';
import { Project } from '../pages';
import AIMSRoute from './AIMS.route';
import ConfigureMessage from '../components/container/ConfigureMessage';

const ProjectRoute = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const dispatch = useDispatch();
  const projectLastFetchTime = useSelector((state) => state.projects.lastFetchTime);
  const scorecardLastFetchTime = useSelector((state) => state.scorecards.lastFetchTime);

  const project = useSelector((state) => state.projects.selected) || null;

  useEffect(() => {
    if (projectLastFetchTime === -1) dispatch(getAllProjects());
  }, [dispatch, projectLastFetchTime]);

  useEffect(() => {
    if (scorecardLastFetchTime === -1) dispatch(getAllScorecards());
  }, [dispatch, scorecardLastFetchTime]);

  return (
    <>
      {project && location.pathname !== '/projects' ? (
        <div className="project-name-header">
          <h2>{project.name}</h2>
        </div>
      ) : null}
      <Switch>
        <Route path={`${path}`} exact component={Project.Dashboard} />
        <Route path={`${path}/aims`} component={AIMSRoute} />
        <Route path={`${path}/algorithmic-impact-assessment`} exact component={Project.AIA} />
        <Route path={`${path}/trust-tools`}>
          <ConfigureMessage />;
        </Route>
      </Switch>
    </>
  );
};

export default ProjectRoute;
