/* eslint-disable */
import client from '../../utils/api/client-helper';
import { restfulAction, ACTION_TYPE, ACTION_TAILS } from './actions-api';
import { AI_GOVERNANCE_SCORECARD_SET_SELECTED, AIGS_GET_REQ, AIGS_GET_SUC, AIGS_GET_FAIL, SCORECARD_SUBMIT_ASSESSMENT_REQ, SCORECARD_SUBMIT_ASSESSMENT_SUC, SCORECARD_SUBMIT_ASSESSMENT_FAIL, AIGS_SCORECARD_SCORE_SET_SELECTED } from './action-types';

const { GET, GET_ALL, UPDATE, ADD, DELETE } = ACTION_TYPE;

/* Table of Contents ----------------------------

    1. Scorecard
    2. Scorecard Requirement (data specific custom weight for example)
    3. Scorecard Question (data specific custom weight for example)
    4. AIGSRequirement (The actual requirement)
    5. AIGSQuestion (The Actual question)

---------------------------- */

/* 1. Scorecard  ===========================================
_____________________________________________*/
const scorecardAction = (type, uuid = null, scorecard = null) => {
  const actionCallback = (data) => {
    return type === GET_ALL ? { scorecardList: data } : type === DELETE ? { scorecardUuid: uuid } : { scorecard: data };
  };

  let actionConfig = {
    typeName: 'AI_GOVERNANCE_SCORECARD',
    type,
    subdir: `/ai_governance_scorecard/${type === GET_ALL || type === ADD ? '' : uuid}`,
    uuid,
    obj: scorecard,
    options: {
      objectName: 'scorecard',
      callback: actionCallback,
    },
  };
  return restfulAction(actionConfig);
};
export const getAllScorecards = () => scorecardAction(GET_ALL);
export const addScorecard = (scorecard) => scorecardAction(ADD, null, scorecard);
export const getScorecard = (scorecardUuid) => scorecardAction(GET, scorecardUuid);
export const deleteScorecard = (scorecardUuid) => scorecardAction(DELETE, scorecardUuid);
export const updateScorecard = (scorecardUuid, scorecard) => scorecardAction(UPDATE, scorecardUuid, scorecard);
export const setSelectedScorecard = (scorecard) => ({
  type: AI_GOVERNANCE_SCORECARD_SET_SELECTED,
  scorecard,
});

/**
 *  Grabs the AIGS Scorecard only and it also sets the selected scorecard to the scorecard returned
 * @returns {obj}
 */
export const getAIGSScorecard = () => {
  return (dispatch, getState) => {
    dispatch({ type: AIGS_GET_REQ });
    const idToken = getState().authTokens.id_token;
    // const user = getState().user;

    const config = {
      // user,
      idToken,
    };

    return client('/ai_governance_scorecard/aigs', config)
      .then((response) => {
        dispatch({ type: AIGS_GET_SUC, scorecard: response.data });
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        dispatch({ type: AIGS_GET_FAIL });
        return null;
      });
  };
};

/* 2. Scorecard Answers ===========================================
_____________________________________________*/
const scorecardScoreAction = (type, scorecardUuid, scorecardScoreUuid = null, scorecardScore = null) => {
  const actionCallback = (data) => {
    return type === GET_ALL
      ? { scoreList: data, scorecardUuid }
      : type === DELETE
      ? { scorecardUuid, scorecardScoreUuid }
      : // Update, Add, Delete
        { score: data, scorecardUuid };
  };

  let actionConfig = {
    typeName: 'AIGS_SCORECARD_SCORE',
    type,
    subdir: `/ai_governance_scorecard/${scorecardUuid}/answers/${type === GET_ALL || type === ADD ? '' : scorecardScoreUuid}`,
    uuid: scorecardScoreUuid,
    obj: scorecardScore,
    options: {
      objectName: 'scorecard',
      callback: actionCallback,
      actionTails: type === ADD || type === GET_ALL ? ACTION_TAILS.regular : ACTION_TAILS.short,
      userInfo: type === ADD ? { email: 'answeredBy' } : null,
      requestFailDispatch: !(type === ADD || type === GET_ALL) ? { scorecardUuid } : null,
    },
  };
  return restfulAction(actionConfig);
};

export const getAllScorecardScores = (scorecardUuid) => scorecardScoreAction(GET_ALL, scorecardUuid);
export const submitScorecardScore = (scorecard, answers, isDraft = false) =>
  scorecardScoreAction(ADD, scorecard.uuid, null, {
    answers: answers,
    draft: isDraft,
  });
export const getScorecardScore = (scorecardUuid) => null;
export const deleteScorecardScore = (scorecardUuid, scorecardScoreUuid) => scorecardScoreAction(DELETE, scorecardUuid, scorecardScoreUuid);
export const updateScorecardScore = (scorecardUuid, scorecardScore) => scorecardScoreAction(UPDATE, scorecardUuid, scorecardScore.uuid, scorecardScore);
export const setSelectedScorecardScore = (scorecardUuid, score) => ({ type: AIGS_SCORECARD_SCORE_SET_SELECTED, scorecardUuid, score });

/* 3. Scorecard Requirement ===========================================
_____________________________________________*/
const scorecardRequirementAction = (type, scorecardUuid, requirementUuid = null, requirement = null) => {
  const actionCallback = (data) => {
    return type === GET_ALL ? { scorecardUuid, requirementList: data } : type === DELETE ? { scorecardUuid, requirementUuid } : { scorecardUuid, requirement: data };
  };

  let actionConfig = {
    typeName: 'AI_GOVERNANCE_SCORECARD_REQUIREMENT',
    type,
    subdir: `/ai_governance_scorecard/${scorecardUuid}/requirement/${type === GET_ALL || type === ADD ? '' : requirementUuid}`,
    uuid: requirementUuid,
    obj: requirement,
    options: {
      objectName: 'scorecard',
      callback: actionCallback,
      actionTails: type === GET_ALL ? ACTION_TAILS.regular : ACTION_TAILS.short,
    },
  };

  return restfulAction(actionConfig);
};
export const getAllScorecardRequirements = (scorecardUuid) => scorecardRequirementAction(GET_ALL, scorecardUuid);
export const addScorecardRequirement = (scorecardUuid, requirement) => scorecardRequirementAction(ADD, scorecardUuid, null, requirement);
export const getScorecardRequirement = (scorecardUuid, requirementUuid) => scorecardRequirementAction(GET, scorecardUuid, requirementUuid);
export const deleteScorecardRequirement = (scorecardUuid, requirementUuid) => scorecardRequirementAction(DELETE, scorecardUuid, requirementUuid);
export const updateScorecardRequirement = (scorecardUuid, requirementUuid, requirement) => scorecardRequirementAction(UPDATE, scorecardUuid, requirementUuid, requirement);

/* 4. Scorecard Question ===========================================
_____________________________________________*/
const scorecardQuestionAction = (type, scorecardUuid, requirementUuid, questionUuid = null, question = null) => {
  const actionCallback = (data) => {
    return type === GET_ALL ? { scorecardUuid, requirementUuid, questionList: data } : type === DELETE ? { scorecardUuid, requirementUuid, questionUuid } : { scorecardUuid, requirementUuid, question: data };
  };

  let actionConfig = {
    typeName: 'AI_GOVERNANCE_SCORECARD_QUESTION',
    type,
    subdir: `/ai_governance_scorecard/${scorecardUuid}/requirement/${requirementUuid}/question/${type === GET_ALL || type === ADD ? '' : questionUuid}`,
    uuid: questionUuid,
    obj: question,
    options: {
      objectName: 'question',
      callback: actionCallback,
    },
  };

  return restfulAction(actionConfig);
};
export const getAllScorecardQuestions = (scorecardUuid, requirementUuid) => scorecardQuestionAction(GET_ALL, scorecardUuid, requirementUuid);
export const addScorecardQuestion = (scorecardUuid, requirementUuid, question) => scorecardQuestionAction(ADD, scorecardUuid, requirementUuid, null, question);
export const getScorecardQuestion = (scorecardUuid, requirementUuid, questionUuid) => scorecardQuestionAction(GET, scorecardUuid, requirementUuid, questionUuid);
export const updateScorecardQuestion = (scorecardUuid, requirementUuid, questionUuid, question) => scorecardQuestionAction(UPDATE, scorecardUuid, requirementUuid, questionUuid, question);
export const deleteScorecardQuestion = (scorecardUuid, requirementUuid, questionUuid) => scorecardQuestionAction(DELETE, scorecardUuid, requirementUuid, questionUuid);

/* 5. AIGSRequirement ===========================================
_____________________________________________*/

const aigsRequirementAction = (type, requirementUuid = null, requirement = null) => {
  const actionCallback = (data) => {
    return type === GET_ALL ? { requirementList: data } : type === DELETE ? { requirementUuid } : { requirement: data };
  };

  let actionConfig = {
    typeName: 'AIGS_REQUIREMENT',
    type,
    subdir: `/ai_governance_scorecard/requirement/${type === GET_ALL || type === ADD ? '' : requirementUuid}`,
    uuid: requirementUuid,
    obj: requirement,
    options: {
      objectName: requirement,
      callback: actionCallback,
    },
  };
  return restfulAction(actionConfig);
};
export const getAllAIGSRequirements = () => aigsRequirementAction(GET_ALL);
export const addAIGSRequirement = (requirement) => aigsRequirementAction(ADD, null, requirement);
export const getAIGSRequirement = (requirementUuid) => aigsRequirementAction(GET, requirementUuid);
export const deleteAIGSRequirement = (requirementUuid) => aigsRequirementAction(DELETE, requirementUuid);
export const updateAIGSRequirement = (requirementUuid, requirement) => aigsRequirementAction(UPDATE, requirementUuid, requirement);

/* 6. AIGSQuestion ===========================================
_____________________________________________*/

const aigsQuestionAction = (type, questionUuid = null, question = null) => {
  const actionCallback = (data) => {
    return type === GET_ALL ? { questionList: data } : type === DELETE ? { questionUuid } : { question: data };
  };

  let actionConfig = {
    typeName: 'AIGS_QUESTION',
    type,
    subdir: `/ai_governance_scorecard/question/${type === GET_ALL || type === ADD ? '' : questionUuid}`,
    uuid: questionUuid,
    obj: question,
    options: {
      objectName: 'question',
      callback: actionCallback,
    },
  };
  return restfulAction(actionConfig);
};
export const getAllAIGSQuestions = () => aigsQuestionAction(GET_ALL);
export const addAIGSQuestion = (question) => aigsQuestionAction(ADD, null, question);
export const getAIGSQuestion = (questionUuid) => aigsQuestionAction(GET, questionUuid);
export const deleteAIGSQuestion = (questionUuid) => aigsQuestionAction(DELETE, questionUuid);
export const updateAIGSQuestion = (questionUuid, question) => aigsQuestionAction(UPDATE, questionUuid, question);

/**
 *  Submit Scorecard AIA
 * @returns response data or Null
 */
export const submitScorecardAIA = (scorecard, assessment) => {
  return (dispatch, getState) => {
    dispatch({
      type: SCORECARD_SUBMIT_ASSESSMENT_REQ,
      scorecardUuid: scorecard.uuid,
    });

    const idToken = getState().authTokens.id_token;
    // const user = getState().user;

    let data_config = {
      idToken,
      // user,
      config: {
        method: 'POST',
      },
      data: assessment,
    };

    return client(`/ai_governance_scorecard/${scorecard.uuid}/aia`, data_config)
      .then((response) => {
        dispatch({
          type: SCORECARD_SUBMIT_ASSESSMENT_SUC,
          aia: response.data,
          scorecardUuid: scorecard.uuid,
        });
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: SCORECARD_SUBMIT_ASSESSMENT_FAIL,
          scorecardUuid: scorecard.uuid,
        });
        return null;
      });
  };
};
