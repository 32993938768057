/*
 * File: AIAQuestionnaire.jsx
 * Project: mtp-webui
 * File Created: Thursday, 22nd April 2021 12:28:19 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Thursday, 26th August 2021 2:57:21 pm
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { submitProjectAIA } from '../../redux/actions';
import config from '../../config.json';
import type { Project, AlgorithmicImpactAssessment } from '../../types';

const MSG = {
  STANDARD: 'standard',
  PROJECT: 'project',
};

type AlgorithmicAssessmentImpactProps = {
  assessment: AlgorithmicImpactAssessment;
  isStandard?: boolean;
  project?: Project;
};

const AlgorithmicAssessmentImpact = ({ assessment, isStandard = false, project = undefined }: AlgorithmicAssessmentImpactProps): JSX.Element => {
  const dispatch = useDispatch();

  let messageCode = isStandard ? MSG.STANDARD : undefined;
  messageCode = project && !messageCode ? MSG.PROJECT : messageCode;

  // TODO: handle different AIA version
  const aiaUri = config.aia_uri;
  const encode = btoa(
    JSON.stringify({
      payload: assessment ? { toolData: JSON.parse(assessment.answers as string) } : null,
      messageCode,
      assessmentUuid: assessment ? assessment.uuid : uuidv4(),
    }),
  );

  const receiveMessage = useCallback(
    (event) => {
      const { message } = event.data;

      switch (message) {
        case MSG.PROJECT:
          dispatch(submitProjectAIA(project, event.data.value));
          break;
        case MSG.STANDARD:
          console.log(event.data.value);
          break;
        default:
          break;
      }
    },
    [dispatch, project],
  );

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);

    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, [dispatch, receiveMessage]);

  return (
    <div className="algorithmic-impact-assessment-container" data-tut="AIA">
      <iframe title="Algorithmic Impact Assessment" id="aia-iframe" src={`${aiaUri}?compliance=${encode}`} />
    </div>
  );
};

AlgorithmicAssessmentImpact.propTypes = {
  assessment: PropTypes.shape({
    uuid: PropTypes.string,
    version: PropTypes.string,
    raw_risk_score: PropTypes.number,
    mitigation_score: PropTypes.number,
    total_score: PropTypes.number,
    level: PropTypes.number,
    answer: PropTypes.string,
    created: PropTypes.string,
    modified: PropTypes.string,
  }).isRequired,
  scorecard: PropTypes.shape({
    uuid: PropTypes.string,
    title: PropTypes.string,
    createdBy: PropTypes.string,
    state: PropTypes.number,
    type: PropTypes.string,
    created: PropTypes.string,
    modified: PropTypes.string,
    // open: PropTypes.bool // Not used
    // requirements: PropTypes.array // TODO: Specify
  }),
  project: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    created: PropTypes.string,
    state: PropTypes.number,
    cycle: PropTypes.number,
    scorecardUuid: PropTypes.string,
    algorithmicImpactAssessment: PropTypes.shape({
      uuid: PropTypes.string,
      version: PropTypes.string,
      raw_risk_score: PropTypes.number,
      mitigation_score: PropTypes.number,
      total_score: PropTypes.number,
      level: PropTypes.number,
      answer: PropTypes.string,
      created: PropTypes.string,
      modified: PropTypes.string,
    }),
  }),
};

AlgorithmicAssessmentImpact.defaultProps = {
  scorecard: undefined,
  project: undefined,
  isStandard: false,
};

export default AlgorithmicAssessmentImpact;
