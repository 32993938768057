/*
 * File: AIGS.NavDrawer.jsk
 * Project: mtp-webui
 * File Created: Monday, 5th April 2021 10:20:41 am
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Tuesday, 31st August 2021 11:52:33 am
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { DrawerLink } from '../../types';
import { RootState } from '../../../../redux/store';
import { Role } from '../../../../types';

interface Props {
  basePath: any;
  linkList: DrawerLink[];
}

const DrawerLinks = ({ basePath, linkList }: Props) => {
  const { t } = useTranslation();
  const location = useLocation();

  const appRoleContext = useSelector<RootState>((state) => state.uiState.appRoleContext) as Role;

  return (
    <ul>
      {linkList.map((link) =>
        (link.roles && link.roles.includes(appRoleContext)) || !link.roles ? (
          <li title={t(link.title)} key={link.path} id={link.id ? link.id : undefined} data-tut={link.dataTut} className={(link.path === location.pathname && location.pathname === basePath) || (location.pathname.includes(link.path) && link.path !== basePath) ? 'selected' : undefined}>
            <Link to={link.path}>
              {link.icon ? <i className={link.icon} /> : <h3>{link.text instanceof Function ? link.text(t) : t(link.text as string)}</h3>}

              <span className="mr-auto">{link.label instanceof Function ? link.label(t) : t(link.label)}</span>
            </Link>
          </li>
        ) : null,
      )}
    </ul>
  );
};

export default DrawerLinks;
