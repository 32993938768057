/**
 * File: index.ts
 * Project: Machine Trust Platform
 * File Created: Tuesday, 27th July 2021 12:55:37 pm
 * Author: Rafal Rybak (rafal.rybak@nuenergy.ai)
 * -----
 * Last Modified: Tuesday, 24th August 2021 11:21:02 am
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
import AIA from './AIA';
import IEEE from './IEEE';
import ISO from './ISO';
import CIOSC from './CIOSC';
import EU from './EU';
import Singapore from './Singapore';

const Standards = {
  AIA,
  IEEE,
  ISO,
  CIOSC,
  EU,
  Singapore,
};

export default Standards;
