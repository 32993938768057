/*
 * File: Tutorial.jsx
 * Project: Machine Trust Platform
 * File Created: Monday, 17th May 2021 4:09:12 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Tuesday, 22nd June 2021 9:17:32 am
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React from 'react';
import PropTypes from 'prop-types';

type TutorialProps = {
  header?: string;
  children: React.ReactNode;
};

const Tutorial = ({ header = undefined, children }: TutorialProps): JSX.Element => {
  return (
    <section className="tutorial-info">
      <h4>{header}</h4>
      <div className="tut-content">{children}</div>
    </section>
  );
};

Tutorial.propTypes = {
  header: PropTypes.string,
  children: PropTypes.element.isRequired,
};
Tutorial.defaultProps = {
  header: undefined,
};

export default Tutorial;
