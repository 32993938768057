/* eslint-disable */
import React, { useEffect } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectProject } from '../../redux/actions';
import * as urlUtils from '../../utils/url';

/**
 * React Component for Selecting Project Context via a dropdown menu
 * The ProjectDropdownSelector component will try to set the the currently selected project as follows ...
 *   1) Get the selected project from the project={uuid} search parameter in the URL if present
 *   2) Get the selected project from the redux state store
 *   3) Default to the first project in the redux state store project list
 * @return {JSX} the react JSX componenet
 */
const ProjectDropdownSelector = (props) => {
  const { disabled = false } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const projects = useSelector((state) => state.projects) || null;
  // const lastFetchTime = projects.lastFetchTime
  const params = urlUtils.searchStr2Obj(location.search);

  // const selectedProjectId = projects.selected ? projects.selected : null;

  const selectedProject = projects.selected ? projects.selected : { uuid: null };

  // Used to determine whether or not we should force a redirect to an equivalent url
  // with the appropriate project id set in its search parameters
  // (null = don't redirect, otherwise = redirect)
  const redirectToProject = params.project ? null : selectedProject.uuid ? selectedProject.uuid : projects.list.length > 0 ? projects.list[0].uuid : null;

  // What our URL search params say the currently selected project is, or should be
  const paramsProjectId = redirectToProject ? redirectToProject : params.project ? params.project : null;

  // Used to determine whether or not we should update the project selection state to be in sync
  // with the url project id search parameter
  // (null = don't update state with project selection, otherwise update the state)
  const updateProjectSelection = selectedProject.uuid !== paramsProjectId ? paramsProjectId : null;
  useEffect(() => {
    if (updateProjectSelection) {
      let updatedProject = projects.list.find((p) => p.uuid === updateProjectSelection);
      dispatch(selectProject(updatedProject));
    }
  }, [updateProjectSelection, projects, dispatch]);

  return redirectToProject ? (
    <Redirect
      to={{
        pathname: location.pathname,
        search: urlUtils.searchObj2Str({
          ...params,
          ...{ project: redirectToProject },
        }),
        push: true,
      }}
    />
  ) : selectedProject ? (
    <div className="btn-group dropdown project-dropdown">
      <button className="btn btn-secondary dropdown-toggle" type="button" id="project-dropdown-button" data-toggle="dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" disabled={disabled}>
        <span>{selectedProject.name}</span>
      </button>
      <div className="dropdown-menu" aria-labelledby="project-dropdown-button">
        {projects.list.map((p) => {
          let newParams = { ...params };
          // delete newParams.model;
          newParams.project = p.uuid;
          return (
            <Link
              key={p.uuid}
              className={`dropdown-item ${p.uuid === selectedProject.uuid ? 'active' : ''}`}
              to={{
                pathname: location.pathname,
                search: urlUtils.searchObj2Str(newParams),
              }}>
              {p.name}
            </Link>
          );
        })}{' '}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default ProjectDropdownSelector;
