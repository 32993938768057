/**
 * File: intex.ts
 * Project: mtp-webui
 * File Created: Thursday, 13th May 2021 9:51:30 am
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Thursday, 13th May 2021 10:28:04 am
 * Modified By: Tarek Sanger (tarek@nuenergy.ai>)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
import Data from './Data';
import Deployment from './Deployment';
import Drift from './Drift';
import Development from './Development';

const Cycle = {
  Data,
  Deployment,
  Drift,
  Development,
};

export default Cycle;
