import { Reducer } from 'redux';
import {
  // Scorecard
  AI_GOVERNANCE_SCORECARD_GET_ALL_SUCCESS,
  AI_GOVERNANCE_SCORECARD_ADD_SUCCESS,
  AI_GOVERNANCE_SCORECARD_GET_SUCCESS,
  AI_GOVERNANCE_SCORECARD_DELETE_SUCCESS,
  AI_GOVERNANCE_SCORECARD_UPDATE_SUCCESS,
  AI_GOVERNANCE_SCORECARD_SET_SELECTED,
  AIGS_GET_SUC,
  PROJECTS_SET_SELECTED,
} from '../actions/action-types';

import type { Scorecard, Project } from '../../types';

interface ScorecardState {
  list: Array<Scorecard>;
  selected?: Scorecard | null;
  userLastModTime: number;
  lastFetchTime: number;
  loading: boolean;
}

interface ScorecardAction {
  type: string;
  scorecardList?: Array<Scorecard>;
  scorecard?: Scorecard;
  scorecardUuid?: string;
  project?: Project;
}

const scorecardInitialState: ScorecardState = {
  list: [],
  selected: undefined,
  userLastModTime: -1,
  lastFetchTime: -1,
  loading: false,
};

const ScorecardReducer: Reducer<ScorecardState, ScorecardAction> = (state = scorecardInitialState, action) => {
  const { scorecardList, scorecard, scorecardUuid, project } = action;

  switch (action.type) {
    /* ------------ Scorecard -------- */

    case AI_GOVERNANCE_SCORECARD_GET_ALL_SUCCESS:
      return {
        ...state,
        list: scorecardList || [],
        lastFetchTime: new Date().getTime(),
      };

    case AIGS_GET_SUC:
    case AI_GOVERNANCE_SCORECARD_ADD_SUCCESS:
      return {
        ...state,
        list: scorecard ? [...state.list, scorecard] : state.list,
        userLastModTime: new Date().getTime(),
        selected: scorecard,
      };

    case AI_GOVERNANCE_SCORECARD_UPDATE_SUCCESS:
    case AI_GOVERNANCE_SCORECARD_GET_SUCCESS:
      return {
        ...state,
        userLastModTime: new Date().getTime(),
        selected: scorecard,
        list: scorecard ? state.list.map((s) => (s.uuid === scorecard.uuid ? scorecard : s)) : state.list,
      };

    case AI_GOVERNANCE_SCORECARD_DELETE_SUCCESS:
      return {
        ...state,
        list: scorecardUuid ? state.list.filter((s) => s.uuid !== scorecardUuid) : state.list,
        userLastModTime: new Date().getTime(),
        selected: null,
      };

    case AI_GOVERNANCE_SCORECARD_SET_SELECTED:
      return {
        ...state,
        selected: typeof scorecard === 'string' ? state.list.find((s) => s.uuid === (scorecard as string)) || null : (scorecard as Scorecard | undefined),
      };

    case PROJECTS_SET_SELECTED:
      return {
        ...state,
        selected: project && project.scorecardUuid ? state.list.find((s) => s.uuid === project.scorecardUuid) || null : null,
      };

    default:
      return state;
  }
};

export type { ScorecardState };
export default ScorecardReducer;
