/*
 * File: DownLoadPDF.jsx
 * Project: mtp-webui
 * File Created: Sunday, 18th April 2021 6:46:25 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Friday, 18th June 2021 1:34:20 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai>)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React from 'react';
import PropTypes from 'prop-types';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
// import Roboto from '../fonts/Roboto.js'
import { getNameOfMonthFromTimeStamp } from '../../utils/timeUtils';

import { Survey, Model, StylesManager } from 'survey-react';
import { SurveyPDF } from 'survey-pdf';

/**
 *
 * @param {String} title - the title of the pdf file
 * @param {String} elementID - the id of the element that contains the html to be converted to a PDF
 * @returns
 */

// type DownloadPDFProps = {
//   title: string;
//   elementID: string;
// };

const DownloadPDF = ({ title, elementID = '' }) => {
//   const handleDownloadPDF = (event) => {
//     pdfMake.fonts = {
//       // TODO: use fonts file
//       Roboto: {
//         normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
//         bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
//         italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
//         bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
//       },
//     };
//     const source = document.getElementById(elementID)?.innerHTML;
//     const val = htmlToPdfmake(source);
//     const dd = {
//       content: val,
//       styles: {
//         'html-h5': {
//           fontSize: 10,
//         },
//       },
//       pageMargins: [40, 5, 40, 40],
//     };
//     // console.log(dd);
//     const date = new Date();
//     pdfMake.createPdf(dd).download(`${title}-${getNameOfMonthFromTimeStamp(date).substring(0, 3)}-${date.getDate()}.pdf`);
//     event.preventDefault();
//   };

//   return (
//     <button type="button" className="download-results" onClick={handleDownloadPDF}>
//       <i className="fas fa-file-download" />
//     </button>
//   );
// };

const handleDownloadPDF = (event) => {
  const pdfWidth = surveyModel.pdfWidth || 210;
  const pdfHeight = surveyModel.pdfHeight || 297;

  const source = document.getElementById(elementID)?.innerHTML;
  const surveyPDF = new SurveyPDF(source, options);
  const options = {
    content: surveyPDF,
    fontSize: 14,
    margins: {
      left: 12,
      right: 12,
      top: 12,
      bot: 12,
    },
    format: [pdfWidth, pdfHeight],
  };

  const date = new Date();
  pdfMake.createPdf(options).download(`${title}-${getNameOfMonthFromTimeStamp(date).substring(0, 3)}-${date.getDate()}.pdf`);
  event.preventDefault();
};

const surveyModel = new Model();
    surveyModel.showNavigationButtons = false;
    surveyModel.isRequired = true;

return (
  <button type="button" className="download-results" onClick={handleDownloadPDF}>
    <i className="fas fa-file-download" />
  </button>
);
};


DownloadPDF.propTypes = {
  title: PropTypes.string.isRequired,
  elementID: PropTypes.string.isRequired,
};

export default DownloadPDF;
