/*
 * File: DemoContainer.tsx
 * Project: Machine Trust Platform
 * File Created: Wednesday, 28th July 2021 3:44:42 pm
 * Author: Rafal Rybak (rafal.rybak@nuenergy.ai)
 * -----
 * Last Modified: Saturday, 31st July 2021 8:26:49 am
 * Modified By: Rafal Rybak (rafal.rybak@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
import React from 'react';
import { NuEnergyBase64Logo } from '../Icons';

const containerStyle = {
  height: '400px',
  padding: '20px 50px',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
};

type DemoContainerProps = {
  children: React.ReactNode;
};

const DemoContainer = ({ children }: DemoContainerProps): JSX.Element => {
  return (
    <div className="survey-container">
      <div>
        <NuEnergyBase64Logo width={102} height={30} />
      </div>
      <div style={containerStyle}>
        <h2>{children}</h2>
      </div>
    </div>
  );
};

export default DemoContainer;
