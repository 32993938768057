import React from 'react';
import { useSelector } from 'react-redux';
// import type {} from '../../types'
import type { RootState } from '../../redux/store';

const LoadingPage = (): JSX.Element => {
  const loading = useSelector<RootState>((state) => state.uiState.loading);

  return (
    <div className="loading-background" style={{ display: loading ? 'flex' : 'none' }}>
      <div className="spinner-animated" />
    </div>
  );
};

export default LoadingPage;
