/*
 * File: Dahsboard.tutorial.jsx
 * Project: Machine Trust Platform
 * File Created: Monday, 14th June 2021 3:23:14 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Tuesday, 22nd June 2021 3:32:32 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomTour from '../CustomTour';
import Tutorial from '../TutorialContainer';

const DashboardTutorial = (): JSX.Element => {
  const STEPS = [
    {
      selector: '#aigs-questionnaire-drawer-btn',
      content: () => <StepOne />,
    },
  ];

  return <CustomTour steps={STEPS} />;
};

export default DashboardTutorial;

function StepOne() {
  const { t } = useTranslation();

  return <Tutorial header="Populating the AI Governance Scorecard (AIGS) Dashboard">{t('aigs.dashboard.tutorial.step.1')}</Tutorial>;
}

// const STEPS = [

//   {
//     selector: '#nuenergy-questionnaire',
//     content: "Try selecting an answer to one of the questions and press the save on the bottom right hand corner. (The save will only appear once there is something to be saved)"
//   // position: ['left', 'right', 'center'],

//   },

//   {
//     selector: '[data-tut="control-buttons"]',
//     content: () => (<Tutorial
//       header="Questionnaire Controls"
//     >
//       Notice you can select the draft you just saved and you can clear your answers to start fresh.

//     </Tutorial>),
//     action: () => {
//     },
//   },
//   {
//     selector: '#aigs-scores-drawer-btn',
//     content: () => (<Tutorial
//       header="Approving and Managing your Scores"
//     >
//       The final step to populating the dashboard is approving your completed AIGS (AI Governance Scorecard) Results.

//     </Tutorial>),
//     action: () => {
//     },
//   },

//   {
//     selector: '[data-tut="scores-table"]',
//     content: () => (<Tutorial
//       header="Score Management Table"
//     >
//       Here you can review and manage your results - If you have any. This is also where you or your Admin will be able to review and approve scores to then presented on the Dashboard.

//     </Tutorial>),
//     action: () => {
//       if (!location.pathname.includes('/aigs/scores')) history.push('/aigs/scores')
//     },
//   },
// ]
