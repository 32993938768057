/*
 * File: ServiceNavButton.tsx
 * Project: Machine Trust Platform
 * File Created: Saturday, 28th August 2021 4:36:37 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Saturday, 28th August 2021 5:55:12 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ServiceLink } from '../../types';

interface Props {
  service: ServiceLink;
}

const ServiceNavButton = ({ service }: Props) => {
  const location = useLocation();
  const { t } = useTranslation();

  const { title, path, dataTut, label, icon } = service;

  return (
    <Link title={`${t(title)}`} to={path} data-tut={dataTut} className={`service-nav-btn ${location.pathname.includes(path) ? 'selected' : ''}`}>
      <div className="icon-container">
        <i className={`${icon}`} />
      </div>
      <div className="service-nav-btn__title">{label instanceof Function ? label(t) : t(label)}</div>
    </Link>
  );
};

export default ServiceNavButton;
