/*
 * File: Data.tsx
 * Project: Machine Trust Platform
 * File Created: Tuesday, 22nd June 2021 9:18:46 am
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Tuesday, 22nd June 2021 9:26:09 am
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React from 'react';
import PropTypes from 'prop-types';
import type { CycleProps } from './CycleTypes';

const Data = ({ color = '#20A8D8', size = 50 }: CycleProps): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox={`0 0 ${size} ${size}`}>
      <path fill={color} d="M12.5 6.25v6.25H6.25V6.25h6.25zM3.125 3.125v12.5h12.5v-12.5h-12.5zM28.125 10.938v6.25h-6.25v-6.25h6.25zM18.75 7.812v12.5h12.5v-12.5h-12.5zM12.5 25v6.25H6.25V25h6.25zm-9.375-3.125v12.5h12.5v-12.5h-12.5z" />
      <path fill={color} d="M34.375 15.625V25H25v9.375h-9.375v12.5h31.25v-31.25h-12.5zm-6.25 12.5h6.25v6.25h-6.25v-6.25zM25 43.75h-6.25V37.5H25v6.25zm9.375 0h-6.25V37.5h6.25v6.25zm9.375 0H37.5V37.5h6.25v6.25zm0-9.375H37.5v-6.25h6.25v6.25zM37.5 25v-6.25h6.25V25H37.5z" />
    </svg>
  );
};

Data.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
Data.defaultProps = {
  color: '#20A8D8',
  size: 50,
};

export default Data;
