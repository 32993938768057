/*
 * File: AIA.jsx
 * Project: mtp-webui
 * File Created: Wednesday, 12th May 2021 12:19:44 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Wednesday, 12th May 2021 12:36:34 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai>)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { AlgorithmicAssessmentImpact } from '../../components/container';

const AIA = () => {
  const project = useSelector((state) => state.projects.selected) || null;
  const aia = project && project.aia && project.aia > 0 ? project.aia[0] : null;

  return <AlgorithmicAssessmentImpact project={project} assessment={aia} />;
};

export default AIA;
