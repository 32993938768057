/**
 * File: index.js
 * Project: Machine Trust Platform
 * File Created: Monday, 14th June 2021 3:21:55 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Monday, 14th June 2021 3:21:56 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai>)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import DashboardTutorial from './Dahsboard.tutorial';
import QuestionnaireTutorial from './Questionnaire.tutorial';
import ScoresTutorial from './Scores.tutorial';
import CanvasTutorial from './Canvas.tutorial';
import QuestionnaireResultsTutorial from './QuestionnaireResults.tutorial';

const AIGSTutorial = (): JSX.Element => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`} exact component={DashboardTutorial} />
      <Route path={`${path}/questionnaire/results`} component={QuestionnaireResultsTutorial} />
      <Route path={`${path}/questionnaire`} component={QuestionnaireTutorial} />
      <Route path={`${path}/scores`} component={ScoresTutorial} />
      <Route path={`${path}/canvas`} component={CanvasTutorial} />
    </Switch>
  );
};

export default AIGSTutorial;

// {
//   selector: '#nuenergy-questionnaire',
//   content: "Try selecting an answer to one of the questions and press the save on the bottom right hand corner. (The save will only appear once there is something to be saved)"
// // position: ['left', 'right', 'center'],

// },

// {
//   selector: '[data-tut="control-buttons"]',
//   content: () => (<Tutorial
//     header="Questionnaire Controls"
//   >
//     Notice you can select the draft you just saved and you can clear your answers to start fresh.

//   </Tutorial>),
//   action: () => {
//   },
// },
// {
//   selector: '#aigs-scores-drawer-btn',
//   content: () => (<Tutorial
//     header="Approving and Managing your Scores"
//   >
//     The final step to populating the dashboard is approving your completed AIGS (AI Governance Scorecard) Results.

//   </Tutorial>),
//   action: () => {
//   },
// },

// {
//   selector: '[data-tut="scores-table"]',
//   content: () => (<Tutorial
//     header="Score Management Table"
//   >
//     Here you can review and manage your results - If you have any. This is also where you or your Admin will be able to review and approve scores to then presented on the Dashboard.

//   </Tutorial>),
//   action: () => {
//     if (!location.pathname.includes('/aigs/scores')) history.push('/aigs/scores')
//   },
// },
