/**
 * File: BenchmarkReducer.ts
 * Project: Machine Trust Platform
 * File Created: Tuesday, 31st August 2021 4:23:28 pm
 * Author: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Last Modified: Wednesday, 8th September 2021 12:51:30 pm
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import { Reducer } from 'redux';
import { BENCHMARK_GET_REQUEST, BENCHMARK_GET_REQUEST_SUCCESS, BENCHMARK_GET_REQUEST_FAILED, BENCHMARK_CLEAR } from '../actions/action-types';
import type { ScorecardType } from '../../types';

//benchmark state types
interface BenchmarkState {
  score?: number;
  lastFetchTime: number;
  loading: boolean;
}

//benchmark action types
interface BenchmarkAction {
  type: string;
  benchmark?: {
    score: number;
  };
}

//initial state of benchmark
const benchmarkInitalState: BenchmarkState = {
  lastFetchTime: -1,
  loading: false,
};

const BenchmarkReducer: Reducer<BenchmarkState, BenchmarkAction> = (state: BenchmarkState = benchmarkInitalState, action: BenchmarkAction) => {
  const { benchmark } = action;
  switch (action.type) {
    case BENCHMARK_GET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case BENCHMARK_GET_REQUEST_SUCCESS:
      return {
        ...state,
        score: benchmark ? benchmark.score : undefined,
        loading: false,
        lastFetchTime: new Date().getTime(),
      };
    case BENCHMARK_GET_REQUEST_FAILED:
      return {
        ...state,
        loading: false,
        lastFetchTime: new Date().getTime(),
      };
    case BENCHMARK_CLEAR:
      return {
        lastFetchTime: -1,
        loading: false,
      };
    default:
      return state;
  }
};

export default BenchmarkReducer;
