/*
 * File: ComingSoon.tsx
 * Project: Machine Trust Platform
 * File Created: Thursday, 24th June 2021 12:37:09 pm
 * Author: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Last Modified: Friday, 27th August 2021 4:29:01 pm
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React from 'react';
import PropTypes from 'prop-types';

interface Props {
  description: string;
}

const contactSupport = {
  fontSize: '18px',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  padding: '100px',
};

const ComingSoon = ({ description = '' }: Props): JSX.Element => {
  return (
    <div className="coming-soon">
      <div>
        <h3>Configurable on request.</h3>
        <a style={contactSupport} href="https://www.nuenergy.ai/contact/" className="btn btn-md align-self-end">
          <p>Contact support@nuenergy.ai</p>
        </a>
      </div>

      <div>
        <p>{description}</p>
      </div>
    </div>
  );
};

ComingSoon.propTypes = {
  description: PropTypes.string,
};

ComingSoon.defaultProps = {
  description: '',
};

export default ComingSoon;
