/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * File: surveyBuilder.ts
 * Project: Machine Trust Platform
 * File Created: Tuesday, 22nd June 2021 11:53:11 am
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Tuesday, 22nd June 2021 5:28:20 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
import type { Scorecard, Requirement, Question, RequirementInstance, QuestionInstance, Choice, Options, SubQuestion } from '../types';

interface ParsedQuestion extends Question, QuestionInstance, SubQuestion {}

interface ParsedRequirement extends Requirement {
  weight: number;
  questions: ParsedQuestion[];
}
interface Explanation {
  type: 'comment';
  name: string;
  title: 'please explain your answer';
  indent: 3;
  hideNumber: true;
  visibleIf?: string;
}

/**
 *
 * @param scorecard
 * @param requirements
 * @param questions
 * @returns
 */
const buildScorecardSurvey = async (scorecard: Scorecard, requirements: Requirement[], questions: Question[]): Promise<any> => {
  const requirementsList = await parseScorecardRequirements(scorecard.requirements, requirements, questions);
  const pages = [] as any[];

  await requirementsList.forEach(async (r) => {
    pages.push(await buildPageForRequirement(r));
  });

  return {
    title: 'Scorecard Questionnaire', // scorecard.title,
    description: scorecard.description,
    pages,
    showProgressBar: 'top',
  };
};

export default buildScorecardSurvey;

/**
 * Parse Scorecard Requirements
 * @param scorecardRequirementList
 * @param requirements
 * @returns
 */
async function parseScorecardRequirements(scorecardRequirementList: RequirementInstance[], requirements: Requirement[], questions: Question[]): Promise<ParsedRequirement[]> {
  const requirementList = [] as ParsedRequirement[];

  await scorecardRequirementList.forEach(async (reqInstance) => {
    // Only add requirements they have question
    if (reqInstance.questions.length > 0) {
      const requirement = (await requirements.find((rq) => rq.uuid === reqInstance.requirementUuid)) as Requirement;

      // parse the requirement questions
      const reqQuestionList = await findRequirmentQuestionsFromQuestionInstances(reqInstance.questions, questions);

      // Build the requirement object
      if (requirement) {
        requirementList.push({
          ...requirement,
          weight: reqInstance.weight,
          questions: reqQuestionList,
        });
      }
    }
  });
  return requirementList;
}

/**
 *
 * @param reqQuestions
 * @param questions
 * @returns
 */
async function findRequirmentQuestionsFromQuestionInstances(reqQuestions: QuestionInstance[], questions: Question[]) {
  return reqQuestions.map((qWeightForReqInstance) => {
    const question = questions.find((q) => q.uuid === qWeightForReqInstance.questionUuid);

    return {
      ...qWeightForReqInstance,
      ...question,
    };
  }) as ParsedQuestion[];
}

/**
 *
 * @param requirement
 * @returns
 */
async function buildPageForRequirement(requirement: ParsedRequirement) {
  return {
    name: requirement.uuid,
    title: {
      default: requirement.name,
    },
    questions: await buildQuestionList(requirement.questions),
  };
}

/**
 *
 * @param questions
 * @returns
 */
async function buildQuestionList(questions: ParsedQuestion[]) {
  const questionList = [] as any[];
  await questions.forEach(async (question) => {
    // Add Question to Questions Lists
    const jsonQuestion = buildQuestion(question);
    questionList.push(jsonQuestion);

    // Add Question Explanation
    if (question.explanation) {
      const explanation = buildQuestionExplanation(question, jsonQuestion);
      questionList.push(explanation);
    }

    // Add Sub Questions
    if (question.subQuestions && question.subQuestions.length > 0) {
      await question.subQuestions.forEach(async (subQ) => {
        const subQJson = buildQuestion(subQ, true, subQ.visibleIf ? conditional(jsonQuestion, subQ.visibleIf) : undefined);
        questionList.push(subQJson);

        // Add Sub Question Explanation
        if (subQ.explanation) {
          const subQExplanation = buildQuestionExplanation(subQ, subQJson, true);
          questionList.push(subQExplanation);
        }
      });
    }
  });

  return questionList;
}

/**
 *
 * @param question
 * @param isSubQuestion
 * @param visibleIf
 * @returns
 */
function buildQuestion(question: ParsedQuestion | SubQuestion, isSubQuestion = false, visibleIf: string | undefined = undefined) {
  const questionData = determineQuestionType(question.options);

  const json = {
    name: isSubQuestion ? `subQ_${question.uuid}` : `Q_${(question as ParsedQuestion).questionWeightUuid}`,
    hideNumber: isSubQuestion,
    title: question.value,
    visibleIf,
    indent: isSubQuestion ? 3 : 2,
    ...questionData,
  };
  return json;
}

/**
 *
 * @param question
 * @param jsonQuestion
 * @param isSubQuestion
 * @returns
 */
function buildQuestionExplanation(question: ParsedQuestion | SubQuestion, jsonQuestion: any, isSubQuestion = false) {
  const explanation: Explanation = {
    type: 'comment',
    name: `exp_${isSubQuestion ? question.uuid : (question as ParsedQuestion).questionWeightUuid}`,
    title: 'please explain your answer',
    indent: 3,
    hideNumber: true,
  };
  if (question.explanationCondition) {
    // explanation.requiredIf = conditional(jsonQuestion, q.explanationCondition)
    explanation.visibleIf = conditional(jsonQuestion, question.explanationCondition);
  }

  return explanation;
}

/**
 *
 * @param options
 * @returns
 */
const determineQuestionType = (options: Options) => {
  let choices = options
    ? options.choices
    : [
        { value: 1, text: 'yes' },
        { value: 0, text: 'no' },
        { value: -1, text: 'N/A' },
      ];
  const inputType = options ? options.inputType : 'boolean';

  switch (inputType) {
    case 'matrix':
      return {
        type: options.inputType,
        columns: choices,
        rows: [options.name],
      };

    case 'text':
      return { type: options.inputType };

    case 'multi-select':
      return { type: options.inputType };

    case 'checkbox':
      return { type: options.inputType, choices };

    case 'rating':
      return { type: options.inputType, rateValues: choices };

    case 'boolean':
      choices = choices.sort((a: Choice, b: Choice) => yesNoOrder(a) - yesNoOrder(b));
      return { type: 'radiogroup', choices, colCount: choices.length };
    // eslint-disable-next-line
    case 'radiogroup':
    default:
      return { type: 'radiogroup', choices };
  }
};

/**
 *
 * @param questionJson
 * @param condition
 * @returns
 */
const conditional = (questionJson: any, condition: any) => {
  switch (questionJson.type) {
    case 'matrix':
      return `{${questionJson.name}.${questionJson.rows[0]}} ${condition}`;
    default:
      return `{${questionJson.name}} ${condition}`;
  }
};

/**
 *
 * @param choice
 * @returns
 */
const yesNoOrder = (choice: Choice) => {
  switch (choice.text.toLowerCase()) {
    case 'yes':
      return 0;
    case 'no':
      return 1;
    case 'n/a':
      return 3;
    default:
      return 2;
  }
};
