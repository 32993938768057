/*
 * File: Fairness360.jsx
 * Project: Machine Trust Platform
 * File Created: Friday, 20th August 2021 11:51:46 am
 * Author: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Last Modified: Wednesday, 25th August 2021 2:28:58 pm
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
import React from 'react';
import ConfigureMessage from '../../components/container/ConfigureMessage';

const Whatif = () => {
  return <ConfigureMessage />;
};

export default Whatif;
