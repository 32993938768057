/*
 * File: AIMSResults
 * Project: Machine Trust Platform
 * File Created: Thursday, 26th August 2021 4:11:15 pm
 * Author: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Last Modified: Friday, 3rd September 2021 12:38:30 pm
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomTour from '../CustomTour';
import Tutorial from '../TutorialContainer';

const QuestionnaireResultsTutorial = (): JSX.Element => {
  const STEPS = [
    {
      selector: '[data-tut="questionnaire-results"]',
      content: () => <StepOne />,
    },
    {
      selector: '[data-tut="questionnaire-results-score"]',
      content: () => <Tutorial header="AIGS Score">This is the overall score obtained from the AIGS questionnaire</Tutorial>,
      action: () => {},
    },
    {
      selector: '[data-tut="questionnaire-results-requirement-score"]',
      content: <Tutorial header="Score breakdown">Here you'll be able to view a breakdown of the result for the Scorecard based off each section of the AIGS questionnaire</Tutorial>,
      action: () => {},
    },
    {
      selector: '[data-tut="questionnaire-results-requirement-question-score"]',
      content: <Tutorial header="Recommendations">This is where you can find a detail breakdown of each question from the AIGS questionnaire, along with recommendations to improve your score </Tutorial>,
      action: () => {},
    },
  ];
  return <CustomTour steps={STEPS} update={document.getElementById('main')?.scrollTop} />;
};

export default QuestionnaireResultsTutorial;

const StepOne = () => {
  const { t } = useTranslation();

  return <Tutorial header="AI Governance Scorecard (AIGS) Questionnaire Results">{t('aigs.questionnaire.results.tutorial.step.1')}</Tutorial>;
};
