/*
 * File: Standards.route.jsx
 * Project: Machine Trust Platform
 * File Created: Tuesday, 27th July 2021 12:49:41 pm
 * Author: Rafal Rybak (rafal.rybak@nuenergy.ai)
 * -----
 * Last Modified: Friday, 20th August 2021 12:16:21 pm
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Standards } from '../pages';

const StandardsRoute = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`} exact component={Standards.AIA} />
      <Route path={`${path}/IEEE`} exact component={Standards.IEEE} />
      <Route path={`${path}/ISO`} exact component={Standards.ISO} />
      <Route path={`${path}/CIOSC`} exact component={Standards.CIOSC} />
      <Route path={`${path}/EU`} exact component={Standards.EU} />
      <Route path={`${path}/Singapore`} exact component={Standards.Singapore} />
    </Switch>
  );
};

export default StandardsRoute;
