import { OrganizationTier } from './types';

export const APP_CONFIG_URI = '/config.json';
export const JWT_REFRESH_BUFFER_MILLISECONDS = 5 * 60 * 1000; // 5 minutes
export const INACTIVITY_TIMEOUT = 15 * 60 * 1000; // 15 minutes
export const PENDING_LOGOUT_TIMEOUT = 2 * 60 * 1000; // 2 minutes

export const SUPPORTED_ROLES = ['admin', 'developer', 'executive', 'scientist'];

export const CACHED_DATA_EXPIRY_MILLISECONDS = 5 * 60 * 1000; // 5 minutes

export const USER = {
  SUPPORTED_ROLES: ['admin', 'developer', 'executive', 'scientist'],
  CONSENT_FORM: {
    NOT_COMPLETE: 0,
    ACCEPTED: 1,
    DECLINED: 2,
  },
};

export const SCORECARD = {
  /* ------ Scorecard Score ------ */
  SCORE: {
    STATE: {
      DRAFT: 1,
      REVIEW: 2,
      APPROVED: 3,
    },
    getState: (int: number): string => {
      switch (int) {
        case SCORECARD.SCORE.STATE.REVIEW:
          return 'pending.label';

        case SCORECARD.SCORE.STATE.APPROVED:
          return 'approved.label';

        case SCORECARD.SCORE.STATE.DRAFT:
        default:
          return 'draft.label';
      }
    },
  },
};

export const PROJECT = {
  CYCLE: {
    DEVELOPMENT: 0,
    DATA: 1,
    DRIFT: 2,
    DEPLOY: 3,
  },
  getCycle: (int: number): string => {
    switch (int) {
      case 0:
        return 'development.label';
      case 1:
        return 'data.label';
      case 2:
        return 'drift.label';
      case 3:
      default:
        return 'deploy.labal';
    }
  },
};

export const ORGANIZATION = {
  TIER: {
    ALL: 1 as OrganizationTier, // The lowest tier which every organization has access too
    GOLD: 2 as OrganizationTier,
    PLATINUM: 3 as OrganizationTier,
  },
};
