/*
 * File: TutorialContainer.tsx
 * Project: Machine Trust Platform
 * File Created: Monday, 14th June 2021 12:06:31 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Tuesday, 22nd June 2021 5:10:16 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
import React from 'react';
import PropTypes from 'prop-types';

interface Props {
  header: string;
  children: React.ReactNode;
}

const TutorialContainer = ({ header, children }: Props): JSX.Element => {
  return (
    <section className="tutorial-info">
      <h4>{header}</h4>
      <div className="tut-content">{children}</div>
    </section>
  );
};

TutorialContainer.propTypes = {
  header: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

export default TutorialContainer;
