/*
 * File: benchmark-actions.tsx
 * Project: Machine Trust Platform
 * File Created: Tuesday, 31st August 2021 10:32:11 am
 * Author: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Last Modified: Wednesday, 8th September 2021 1:33:32 pm
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import { BENCHMARK_GET_REQUEST, BENCHMARK_GET_REQUEST_SUCCESS, BENCHMARK_GET_REQUEST_FAILED, BENCHMARK_CLEAR } from './action-types';
import client from '../../utils/api/client-helper';
import { AppDispatch, RootState } from '../store';
import type { ScorecardType } from '../../types';
import axios from 'axios';
import settings from '../../config.json';

//gets the benchmark scores from the scorecard based on the scorecard type (AIGS|AIMS)
export const getScorecardBenchmark = (scorecardType: ScorecardType) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    // dispatch Request to get scores
    dispatch({ type: BENCHMARK_GET_REQUEST });

    // Get the idToken from the auth tokens
    let configer = {
      params: {
        type: scorecardType,
      },
      headers: {
        Authorization: `Bearer ${getState().authTokens.id_token}`,
        Accept: 'application/json',
        'content-type': 'application/json',
      },
    };
    // make the api request
    return axios
      .get(`${settings.django_basepath}/ai_governance_scorecard/benchmark`, configer)
      .then((response) => {
        //204 status occurs when there aren't enough scores, if there aren't enough scores then nothing is sent as the benchmark score
        if (response.status === 204) {
          dispatch({ type: BENCHMARK_GET_REQUEST_SUCCESS });
        } else {
          dispatch({ type: BENCHMARK_GET_REQUEST_SUCCESS, benchmark: response.data.benchmark });
        }
      })
      .catch((error) => {
        dispatch({ type: BENCHMARK_GET_REQUEST_FAILED });
        console.error(error);
      });
  };
};

//clearns the benchmark score
export const clearBenchmark = () => {
  return {
    type: BENCHMARK_CLEAR,
  };
};
