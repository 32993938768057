/**
 * File: Navigation.js
 * Project: Machine Trust Platform
 * File Created: Thursday, 26th August 2021 2:08:25 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Friday, 10th September 2021 9:16:15 am
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
import { ORGANIZATION } from '../../constants';
import NavDrawer from './components/NavDrawer';
import ServiceNavigation from './components/ServiceNavigation';
import type { OrganizationTier } from '../../types';
import { MTPPath as Path, ServiceLink, DrawerLink, DrawerMap } from './types';
import NavBar from './components/NavBar';

const { TIER } = ORGANIZATION;
/** Navigation
 *
 *
 *
 *
 */

export default class Navigation {
  static Path = Path;

  public drawerMap: DrawerMap;

  public services: ServiceLink[];

  private tier: OrganizationTier;

  /**
   *  Add New Service Links to this list please review the type interface for ServiceLink
   *  found in ./types.ts.
   *  When adding a new Service you will need to add to the Path enum see the enum for more information.
   */
  private SERVICES_LINK_LIST: ServiceLink[] = [
    {
      label: 'servicedrawer.aigs.label',
      title: 'servicedrawer.aigs.label.full',
      path: Path.AIGS,
      icon: 'fas fa-hands-helping',
      tier: TIER.ALL,
    },
    {
      label: 'servicedrawer.aims.label',
      title: 'servicedrawer.aims.label.full',
      path: Path.PROJECTS,
      icon: 'fas fa-folder-open',
      tier: TIER.ALL,
    },
    {
      label: 'servicedrawer.tools.label',
      title: 'servicedrawer.tools.label.full',
      path: Path.TOOLS,
      icon: 'fas fa-toolbox',
      tier: TIER.PLATINUM,
    },
    {
      label: 'servicedrawer.standards.and.frameworks.label',
      title: 'servicedrawer.standards.and.frameworks.label.full',
      path: Path.STANDARDS,
      icon: 'fas fa-folder-open',
      tier: TIER.ALL,
    },
    {
      label: 'servicedrawer.mti.and.wikis.label',
      title: 'servicedrawer.mti.and.wikis.label.full',
      path: Path.MTI,
      icon: 'fas fa-brain',
      tier: TIER.ALL,
    },
  ];

  /**
   * To add to the drawer locate the Path (Path.AIGS) and add the new drawer item within that list.
   * Review the DrawerLink Type in the types folder to understand how to create new tabs.
   */
  private DRAWER_LINK_LIST: DrawerMap = {
    [Path.AIGS]: [
      {
        path: Path.AIGS,
        title: 'dashboard.label',
        icon: 'fas fa-tachometer-alt',
        id: 'aigs-dashboard-drawer-btn',
        label: 'dashboard.label',
        roles: undefined,
        tier: TIER.ALL,
      },
      {
        path: `${Path.AIGS}/canvas`,
        icon: 'fas fa-comments',
        id: 'aigs-canvas-drawer-btn',
        label: 'canvas.label',
        title: 'canvas.title',
        roles: undefined,
        tier: TIER.GOLD,
      },
      {
        path: `${Path.AIGS}/questionnaire`,
        icon: 'fas fa-clipboard-list',
        id: 'aigs-questionnaire-drawer-btn',
        label: 'questionnaire.label',
        title: 'aigs.questionnaire.label',
        roles: undefined,
        tier: TIER.ALL,
      },
      {
        path: `${Path.AIGS}/scores`,
        icon: 'fas fa-star-half-alt',
        id: 'aigs-scores-drawer-btn',
        label: 'scores.label',
        title: 'aigs.scores.label',
        roles: undefined,
        tier: TIER.ALL,
      },
      {
        path: `${Path.AIGS}/measurement`,
        icon: 'fas fa-list',
        id: 'aigs-measurement-drawer-btn',
        label: 'aigs.measurement.label',
        title: 'aigs.measurement.label',
        roles: undefined,
        tier: TIER.ALL,
      },
    ],
    [Path.PROJECTS]: [
      {
        path: Path.PROJECTS,
        icon: 'fas fa-tachometer-alt',
        id: 'projects-dashboard-drawer-btn',
        label: 'dashboard.label',
        title: 'dashboard.label',
        roles: undefined,
        tier: TIER.ALL,
      },
      {
        path: `${Path.PROJECTS}/aims/questionnaire`,
        icon: 'fas fa-clipboard-list',
        id: 'projects-aims-questionnaire-drawer-btn',
        label: 'aims.label',
        title: 'aims.label',
        roles: undefined,
        tier: TIER.ALL,
      },
      {
        path: `${Path.PROJECTS}/algorithmic-impact-assessment`,
        icon: undefined,
        id: 'projects-algorithmic-impact-assessment-drawer-btn',
        text: 'AIA',
        label: 'aia.label',
        title: 'aia.label',
        roles: undefined,
        tier: TIER.ALL,
      },
      {
        path: `${Path.PROJECTS}/trust-tools`,
        icon: 'fas fa-toolbox',
        id: 'projects-tools-drawer-btn',
        label: 'aims.tools.label',
        title: 'aims.tools.label',
        roles: undefined,
        tier: TIER.PLATINUM,
      },
      {
        path: `${Path.PROJECTS}/aims/scores`,
        icon: 'fas fa-star-half-alt',
        id: 'projects-aims-scores-drawer-btn',
        label: 'scores.label',
        title: 'scores.label',
        roles: undefined,
        tier: TIER.ALL,
      },
    ],
    [Path.TOOLS]: [
      {
        path: Path.TOOLS,
        icon: undefined,
        text: 'NuEnergy',
        label: 'tools.nuenergy.label',
        title: 'tools.nuenergy.label',
        roles: undefined,
        tier: TIER.ALL,
      },
      {
        path: `${Path.TOOLS}/Fairness360`,
        text: 'IBM',
        label: 'tools.001.label',
        title: 'tools.001.label',
        roles: undefined,
        tier: TIER.ALL,
      },
      {
        path: `${Path.TOOLS}/Aequitas`,
        icon: 'fas fa-wrench',
        label: 'tools.002.label',
        title: 'tools.002.label',
        roles: undefined,
        tier: TIER.ALL,
      },
      {
        path: `${Path.TOOLS}/Fairlearn`,
        icon: 'fas fa-wrench',
        label: 'tools.003.label',
        title: 'tools.003.label',
        roles: undefined,
        tier: TIER.ALL,
      },
      {
        path: `${Path.TOOLS}/Lime`,
        icon: 'fas fa-wrench',
        label: 'tools.004.label',
        title: 'tools.004.label',
        roles: undefined,
        tier: TIER.ALL,
      },
      {
        path: `${Path.TOOLS}/Whatif`,
        icon: 'fas fa-wrench',
        label: 'tools.005.label',
        title: 'tools.005.label',
        roles: undefined,
        tier: TIER.ALL,
      },
    ],
    [Path.STANDARDS]: [
      {
        path: Path.STANDARDS,
        icon: undefined,
        text: 'AIA',
        label: 'standards.aia.label',
        title: 'standards.aia.label',
        roles: undefined,
        tier: TIER.ALL,
      },
      {
        path: `${Path.STANDARDS}/IEEE`,
        icon: 'fas fa-clipboard-list',
        label: 'standards.001.label',
        title: 'standards.001.label',
        roles: undefined,
        tier: TIER.ALL,
      },
      {
        path: `${Path.STANDARDS}/ISO`,
        icon: 'fas fa-clipboard-list',
        label: 'standards.002.label',
        title: 'standards.002.label',
        roles: undefined,
        tier: TIER.ALL,
      },
      {
        path: `${Path.STANDARDS}/CIOSC`,
        icon: 'fas fa-clipboard-list',
        label: 'standards.003.label',
        title: 'standards.003.label',
        roles: undefined,
        tier: TIER.ALL,
      },
      {
        path: `${Path.STANDARDS}/EU`,
        icon: 'fas fa-clipboard-list',
        label: 'standards.004.label',
        title: 'standards.004.label',
        roles: undefined,
        tier: TIER.ALL,
      },
      {
        path: `${Path.STANDARDS}/Singapore`,
        icon: 'fas fa-clipboard-list',
        label: 'standards.005.label',
        title: 'standards.005.label',
        roles: undefined,
        tier: TIER.ALL,
      },
    ],
    [Path.MTI]: [
      {
        path: Path.MTI,
        icon: 'fas fa-server',
        label: 'mti.demo.label',
        title: 'mti.demo.label',
        roles: undefined,
        tier: TIER.ALL,
      },
      {
        path: `${Path.MTI}/wiki`,
        icon: 'fas fa-database',
        label: 'mti.wiki.demo.label',
        title: 'mti.wiki.demo.label',
        roles: undefined,
        tier: TIER.ALL,
      },
    ],
  };

  constructor(tier: OrganizationTier) {
    this.tier = tier;
    // Filter the services links and remove any of the services that do no belong to the organizations tier
    this.services = this.SERVICES_LINK_LIST.filter((link: ServiceLink) => link.tier <= tier);

    /**
     * Create the appropriate links for each drawer based on the tier
     */
    const tempDrawerMap = {} as { [path in Path]: DrawerLink[] };
    for (const [key, value] of Object.entries(this.DRAWER_LINK_LIST)) {
      tempDrawerMap[key as Path] = value.filter((link: DrawerLink) => link.tier <= tier);
    }
    this.drawerMap = tempDrawerMap;
  }

  // Navigation Components
  Drawer = () => NavDrawer({ routes: this.drawerMap });
  Services = () => ServiceNavigation({ services: this.services });
  Bar = () => NavBar();
}
