/*
 * File: SurveyControlButton.jsx
 * Project: mtp-webui
 * File Created: Sunday, 18th April 2021 6:54:46 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Wednesday, 25th August 2021 1:55:15 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

import React from 'react';
import { DropdownButton, Dropdown, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  // getAnswers,
  cacheAnswers,
} from '../../../utils/localStorage/surveyLocalStorage';
import { createDateTimeLabel } from '../../../utils/timeUtils';
// import { useSurvey } from './context';
import { setSelectedScorecardScore } from '../../../redux/actions';
import { SCORECARD } from '../../../constants';
import type { RootState } from '../../../redux/store';
import type { ScoreReducerState, ScoreState } from '../../../redux/reducers/ScorecardScoresReducer';
import type { Scorecard, Score } from '../../../types';

interface SurveyControlButtonsProps {
  scorecard: Scorecard;
  hasAnswers: boolean;
  clearSurvey: any;
}

const SurveyControlButtons = ({ scorecard, hasAnswers, clearSurvey }: SurveyControlButtonsProps) => {
  /* eslint-disable */
  // const { scorecard, selectedDraft, scorecardDraftList, saveDraft, hasAnswers, clearSurvey, }: SurveyControlButtonsType = useSurvey();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // The list of all the drafts for the draft dropdown
  const scorecardScoreRecoredsState: ScoreReducerState | undefined = (useSelector<RootState>((state) => state.scorecardScores) as ScoreReducerState) || undefined;
  const scorecardScoreState: ScoreState | undefined = scorecardScoreRecoredsState && scorecardScoreRecoredsState[scorecard.uuid] ? scorecardScoreRecoredsState[scorecard.uuid] : undefined;
  const scorecardDraftList: Score[] | undefined = scorecardScoreState && scorecardScoreState.list ? scorecardScoreState.list.filter((s) => s.state === SCORECARD.SCORE.STATE.DRAFT) : undefined;

  // Get the selected score only if it is in the draft state
  const selectedDraft = scorecardScoreState && scorecardScoreState.selected && scorecardScoreState.selected.state === SCORECARD.SCORE.STATE.DRAFT ? scorecardScoreState.selected : undefined;

  // Boolean used to disable the draft list dropdown
  const noDraftsToDisplay = !scorecardDraftList || scorecardDraftList.length < 1;

  const handleSelectDraftItem = (key: string | null, e: any) => {
    const draft = (scorecardDraftList as Score[]).find((d) => d.uuid === key);
    const answers = draft?.answers;
    cacheAnswers(scorecard.uuid, answers);
    dispatch(setSelectedScorecardScore(scorecard.uuid, draft));
    e.preventDefault();
  };

  return (
    <div className="survey-control-btns" role="group">
      <div className="control-btn-container" data-tut="control-buttons" role="group">
        {/* <Button variant="secondary" disabled={!_hasAnswers} onClick={saveDraft}>
          {t('save.label')}
        </Button> */}

        <DropdownButton className="draft-dropdown-list" id="dropdown-split-variants-secondary" variant="secondary" title={t('drafts.label')} disabled={noDraftsToDisplay}>
          {scorecardDraftList
            ? scorecardDraftList.map((d) => (
                <Dropdown.Item className="draft-drop-down-item" eventKey={d.uuid} active={selectedDraft?.uuid === d.uuid} onSelect={handleSelectDraftItem}>
                  <div className="draft-drop-down-text">
                    {d.createdBy}
                    <span>{createDateTimeLabel(new Date(d.modified))}</span>
                  </div>
                </Dropdown.Item>
              ))
            : null}
        </DropdownButton>

        {hasAnswers ? (
          <Button variant="secondary" onClick={clearSurvey}>
            {t('clear.label')}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default SurveyControlButtons;
