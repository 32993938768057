/*
 * File: CustomTour.jsx
 * Project: Machine Trust Platform
 * File Created: Monday, 14th June 2021 12:23:53 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Monday, 21st June 2021 11:00:23 am
 * Modified By: Tarek Sanger (tarek@nuenergy.ai>)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
import React, { useState, useEffect } from 'react';
import Tour from 'reactour';
import { useDispatch, useSelector } from 'react-redux';
import { setTutorialState } from '../../redux/actions';

const CustomTour = (props) => {
  const dispatch = useDispatch();
  const welcomed = useSelector((state) => state.user.welcomed);
  const isTutorialOpen = useSelector((state) => state.uiState.isTutorialOpen);

  const [isOpen, setIsOpen] = useState(isTutorialOpen && welcomed);

  const completeIntroTour = () => {
    dispatch(setTutorialState(false));
  };

  useEffect(() => {
    setIsOpen(isTutorialOpen && welcomed);
  }, [welcomed, isTutorialOpen]);

  return (
    <Tour
      rounded={5}
      onRequestClose={completeIntroTour}
      isOpen={isOpen}
      showNavigationNumber={false}
      showNumber={false}
      /* eslint-disable react/jsx-props-no-spreading */
      {...props}
    />
  );
};

export default CustomTour;
