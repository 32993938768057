// Config --------------------------------------------
export const CONFIG_GET_REQUEST = 'CONFIG_GET_REQUEST';
export const CONFIG_GET_SUCCESS = 'CONFIG_GET_SUCCESS';
export const CONFIG_GET_FAILED = 'CONFIG_GET_FAILED';

// Display --------------------------------------------
export const DISPLAY_ERROR_MESSAGE = 'DISPLAY_ERROR_MESSAGE';
export const TOGGLE_NAV_DRAWER = 'TOGGLE_NAV_DRAWER';

export const CUSTOM_POPUP_MESSAGE = 'CUSTOM_POPUP_MESSAGE';

// Auth --------------------------------------------
export const AUTH_EXCHANGE_CODE_FOR_TOKENS_REQUEST = 'AUTH_EXCHANGE_CODE_FOR_TOKENS_REQUEST';
export const AUTH_EXCHANGE_CODE_FOR_TOKENS_SUCCESS = 'AUTH_EXCHANGE_CODE_FOR_TOKENS_SUCCESS';
export const AUTH_EXCHANGE_CODE_FOR_TOKENS_FAILED = 'AUTH_EXCHANGE_CODE_FOR_TOKENS_FAILED';
export const AUTH_AUTHENTICATE_USER_REQUEST = 'AUTH_AUTHENTICATE_USER_REQUEST';
export const AUTH_AUTHENTICATE_USER_SUCCESS = 'AUTH_AUTHENTICATE_USER_SUCCESS';
export const AUTH_AUTHENTICATE_USER_FAILED = 'AUTH_AUTHENTICATE_USER_FAILED';
export const AUTH_AUTHENTICATE_USER_SET_CALLBACK = 'AUTH_AUTHENTICATE_USER_SET_CALLBACK';
export const AUTH_RETRIEVE_CACHED_TOKENS_REQUEST = 'AUTH_RETRIEVE_CACHED_TOKENS_REQUEST';
export const AUTH_RETRIEVE_CACHED_TOKENS_SUCCESS = 'AUTH_RETRIEVE_CACHED_TOKENS_SUCCESS';
export const AUTH_RETRIEVE_CACHED_TOKENS_FAILED = 'AUTH_RETRIEVE_CACHED_TOKENS_FAILED';
export const AUTH_REFRESH_TOKENS_REQUEST = 'AUTH_REFRESH_TOKENS_REQUEST';
export const AUTH_REFRESH_TOKENS_SUCCESS = 'AUTH_REFRESH_TOKENS_SUCCESS';
export const AUTH_REFRESH_TOKENS_FAILED = 'AUTH_REFRESH_TOKENS_FAILED';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_SCHEDULE_FORCED_LOGOUT = 'AUTH_SCHEDULE_FORCED_LOGOUT';
export const AUTH_CANCEL_SCHEDULED_FORCED_LOGOUT = 'AUTH_CANCEL_SCHEDULED_FORCED_LOGOUT';

export const AUTH_GET_USER_ORGANIZATION_REQUEST = 'AUTH_GET_USER_ORGANIZATION_REQUEST';
export const AUTH_GET_USER_ORGANIZATION_SUCCESS = 'AUTH_GET_USER_ORGANIZATION_SUCCESS';
export const AUTH_GET_USER_ORGANIZATION_FAILED = 'AUTH_GET_USER_ORGANIZATION_FAILED';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';

// Loading --------------------------------------------
export const LOADING_CONTENT = 'LOADING_CONTENT';

// App --------------------------------------------
export const APP_SET_ROLE_CONTEXT = 'APP_SET_ROLE_CONTEXT';
export const APP_SHOW_ERROR_MESSAGES = 'APP_SHOW_ERROR_MESSAGES';

// Projects --------------------------------------------
export const PROJECTS_GET_ALL_REQUEST = 'PROJECTS_GET_ALL_REQUEST';
export const PROJECTS_GET_ALL_SUCCESS = 'PROJECTS_GET_ALL_SUCCESS';
export const PROJECTS_GET_ALL_FAILED = 'PROJECTS_GET_ALL_FAILED';

export const PROJECTS_ADD_REQUEST = 'PROJECTS_ADD_REQUEST';
export const PROJECTS_ADD_SUCCESS = 'PROJECTS_ADD_SUCCESS';
export const PROJECTS_ADD_FAILED = 'PROJECTS_ADD_FAILED';

export const PROJECTS_DELETE_REQUEST = 'PROJECTS_DELETE_REQUEST';
export const PROJECTS_DELETE_SUCCESS = 'PROJECTS_DELETE_SUCCESS';
export const PROJECTS_DELETE_FAILED = 'PROJECTS_DELETE_FAILED';

export const PROJECTS_SET_SELECTED = 'PROJECTS_SET_SELECTED';

export const PROJECTS_UPDATE_REQUEST = 'PROJECTS_UPDATE_REQUEST';
export const PROJECTS_UPDATE_SUCCESS = 'PROJECTS_UPDATE_SUCCESS';
export const PROJECTS_UPDATE_FAILED = 'PROJECTS_UPDATE_FAILED';

export const PROJECT_SUBMIT_ASSESSMENT_REQ = 'PROJECT_SUBMIT_ASSESSMENT_REQ';
export const PROJECT_SUBMIT_ASSESSMENT_SUC = 'PROJECT_SUBMIT_ASSESSMENT_SUC';
export const PROJECT_SUBMIT_ASSESSMENT_FAIL = 'PROJECT_SUBMIT_ASSESSMENT_FAIL';

// Project Trust Category --------------------------------------------
export const PROJECT_TRUST_CATEGORY_GET_ALL_REQUEST = 'PROJECT_TRUST_CATEGORY_GET_ALL_REQUEST';
export const PROJECT_TRUST_CATEGORY_GET_ALL_SUCCESS = 'PROJECT_TRUST_CATEGORY_GET_ALL_SUCCESS';
export const PROJECT_TRUST_CATEGORY_GET_ALL_FAILED = 'PROJECT_TRUST_CATEGORY_GET_ALL_FAILED';

export const PROJECT_TRUST_CATEGORY_SET_SELECTION = 'PROJECT_TRUST_CATEGORY_SET_SELECTION';

export const PROJECT_TRUST_CATEGORY_ADD_REQUEST = 'PROJECT_TRUST_CATEGORY_ADD_REQUEST';
export const PROJECT_TRUST_CATEGORY_ADD_SUCCESS = 'PROJECT_TRUST_CATEGORY_ADD_SUCCESS';
export const PROJECT_TRUST_CATEGORY_ADD_FAILED = 'PROJECT_TRUST_CATEGORY_ADD_FAILED';

export const PROJECT_TRUST_CATEGORY_DELETE_REQUEST = 'PROJECT_TRUST_CATEGORY_DELETE_REQUEST';
export const PROJECT_TRUST_CATEGORY_DELETE_SUCCESS = 'PROJECT_TRUST_CATEGORY_DELETE_SUCCESS';
export const PROJECT_TRUST_CATEGORY_DELETE_FAILED = 'PROJECT_TRUST_CATEGORY_DELETE_FAILED';

export const PROJECT_TRUST_CATEGORY_UPDATE_REQUEST = 'PROJECT_TRUST_CATEGORY_UPDATE_REQUEST';
export const PROJECT_TRUST_CATEGORY_UPDATE_SUCCESS = 'PROJECT_TRUST_CATEGORY_UPDATE_SUCCESS';
export const PROJECT_TRUST_CATEGORY_UPDATE_FAILED = 'PROJECT_TRUST_CATEGORY_UPDATE_FAILED';

// Project Trust Questions --------------------------------------------
export const PROJECT_TRUST_QUESTIONS_GET_ALL_REQUEST = 'PROJECT_TRUST_QUESTIONS_GET_ALL_REQUEST';
export const PROJECT_TRUST_QUESTIONS_GET_ALL_SUCCESS = 'PROJECT_TRUST_QUESTIONS_GET_ALL_SUCCESS';
export const PROJECT_TRUST_QUESTIONS_GET_ALL_FAILED = 'PROJECT_TRUST_QUESTIONS_GET_ALL_FAILED';

export const PROJECT_TRUST_QUESTIONS_SET_SELECTION = 'PROJECT_TRUST_QUESTIONS_SET_SELECTION';

export const PROJECT_TRUST_QUESTIONS_ADD_REQUEST = 'PROJECT_TRUST_QUESTIONS_ADD_REQUEST';
export const PROJECT_TRUST_QUESTIONS_ADD_SUCCESS = 'PROJECT_TRUST_QUESTIONS_ADD_SUCCESS';
export const PROJECT_TRUST_QUESTIONS_ADD_FAILED = 'PROJECT_TRUST_QUESTIONS_ADD_FAILED';

export const PROJECT_TRUST_QUESTIONS_DELETE_REQUEST = 'PROJECT_TRUST_QUESTIONS_DELETE_REQUEST';
export const PROJECT_TRUST_QUESTIONS_DELETE_SUCCESS = 'PROJECT_TRUST_QUESTIONS_DELETE_SUCCESS';
export const PROJECT_TRUST_QUESTIONS_DELETE_FAILED = 'PROJECT_TRUST_QUESTIONS_DELETE_FAILED';

export const PROJECT_TRUST_QUESTIONS_UPDATE_REQUEST = 'PROJECT_TRUST_QUESTIONS_UPDATE_REQUEST';
export const PROJECT_TRUST_QUESTIONS_UPDATE_SUCCESS = 'PROJECT_TRUST_QUESTIONS_UPDATE_SUCCESS';
export const PROJECT_TRUST_QUESTIONS_UPDATE_FAILED = 'PROJECT_TRUST_QUESTIONS_UPDATE_FAILED';

// Project Model --------------------------------------------
export const MODELS_GET_ALL_REQUEST = 'MODELS_GET_ALL_REQUEST';
export const MODELS_GET_ALL_SUCCESS = 'MODELS_GET_ALL_SUCCESS';
export const MODELS_GET_ALL_FAILED = 'MODELS_GET_ALL_FAILED';

export const MODELS_ADD_REQUEST = 'MODELS_ADD_REQUEST';
export const MODELS_ADD_SUCCESS = 'MODELS_ADD_SUCCESS';
export const MODELS_ADD_FAILED = 'MODELS_ADD_FAILED';

export const MODELS_DELETE_REQUEST = 'MODELS_DELETE_REQUEST';
export const MODELS_DELETE_SUCCESS = 'MODELS_DELETE_SUCCESS';
export const MODELS_DELETE_FAILED = 'MODELS_DELETE_FAILED';

export const MODELS_UPDATE_REQUEST = 'MODELS_UPDATE_REQUEST';
export const MODELS_UPDATE_SUCCESS = 'MODELS_UPDATE_SUCCESS';
export const MODELS_UPDATE_FAILED = 'MODELS_UPDATE_FAILED';

export const MODELS_SET_SELECTION = 'MODELS_SET_SELECTION';

export const MODEL_ONLY_ONE_ERROR = 'MODELS_ONLY_ONE_ERROR';
export const MODEL_SELECT_ONE_ERROR = 'MODEL_SELECT_ONE_ERROR';

//  Project Trust Questions Tool Analysis --------------------------------------------
export const PROJECT_TRUST_QUESTION_TOOL_ANALYSIS_GET_REQUEST = 'PROJECT_TRUST_QUESTION_TOOL_ANALYSIS_GET_REQUEST';
export const PROJECT_TRUST_QUESTION_TOOL_ANALYSIS_GET_FAILED = 'PROJECT_TRUST_QUESTION_TOOL_ANALYSIS_GET_FAILED';
export const PROJECT_TRUST_QUESTION_TOOL_ANALYSIS_GET_SUCCESS = 'PROJECT_TRUST_QUESTION_TOOL_ANALYSIS_GET_SUCCESS';

export const PROJECT_TRUST_QUESTION_TOOL_ANALYSIS_UPDATE_REQUEST = 'PROJECT_TRUST_QUESTION_TOOL_ANALYSIS_UPDATE_REQUEST';
export const PROJECT_TRUST_QUESTION_TOOL_ANALYSIS_UPDATE_FAILED = 'PROJECT_TRUST_QUESTION_TOOL_ANALYSIS_UPDATE_FAILED';
export const PROJECT_TRUST_QUESTION_TOOL_ANALYSIS_UPDATE_SUCCESS = 'PROJECT_TRUST_QUESTION_TOOL_ANALYSIS_UPDATE_SUCCESS';

//  Project Trust Questions Alert --------------------------------------------
export const PROJECT_TRUST_QUESTION_ALERT_GET_REQUEST = 'PROJECT_TRUST_QUESTION_ALERT_GET_REQUEST';
export const PROJECT_TRUST_QUESTION_ALERT_GET_FAILED = 'PROJECT_TRUST_QUESTION_ALERT_GET_FAILED';
export const PROJECT_TRUST_QUESTION_ALERT_GET_SUCCESS = 'PROJECT_TRUST_QUESTION_ALERT_GET_SUCCESS';

export const PROJECT_TRUST_QUESTION_ALERT_UPDATE_REQUEST = 'PROJECT_TRUST_QUESTION_ALERT_UPDATE_REQUEST';
export const PROJECT_TRUST_QUESTION_ALERT_UPDATE_FAILED = 'PROJECT_TRUST_QUESTION_ALERT_UPDATE_FAILED';
export const PROJECT_TRUST_QUESTION_ALERT_UPDATE_SUCCESS = 'PROJECT_TRUST_QUESTION_ALERT_UPDATE_SUCCESS';

// MTI Score --------------------------------------------
export const MTI_SCORES_GET_REQUEST = 'MTI_SCORES_GET_REQUEST';
export const MTI_SCORES_GET_SUCCESS = 'MTI_SCORES_GET_SUCCESS';
export const MTI_SCORES_GET_FAILED = 'MTI_SCORES_GET_FAILED';

// Inactivity Timer --------------------------------------------
export const RESET_INACTIVITY_TIMER = 'RESET_INACTIVITY_TIMER';

// Trust Questions --------------------------------------------
export const TRUST_QUESTIONS_GET_REQUEST = 'TRUST_QUESTIONS_GET_REQUEST';
export const TRUST_QUESTIONS_GET_SUCCESS = 'TRUST_QUESTIONS_GET_SUCCESS';
export const TRUST_QUESTIONS_GET_FAILED = 'TRUST_QUESTIONS_GET_FAILED';

export const TRUST_QUESTIONS_PUBLISH_REQUEST = 'TRUST_QUESTIONS_PUBLISH_REQUEST';
export const TRUST_QUESTIONS_PUBLISH_SUCCESS = 'TRUST_QUESTIONS_PUBLISH_SUCCESS';
export const TRUST_QUESTIONS_PUBLISH_FAILED = 'TRUST_QUESTIONS_PUBLISH_FAILED';

// Trust Categories --------------------------------------------
export const TRUST_CATEGORIES_GET_REQUEST = 'TRUST_CATEGORIES_GET_REQUEST';
export const TRUST_CATEGORIES_GET_SUCCESS = 'TRUST_CATEGORIES_GET_SUCCESS';
export const TRUST_CATEGORIES_GET_FAILED = 'TRUST_CATEGORIES_GET_FAILED';

export const TRUST_CATEGORIES_ADD_REQUEST = 'TRUST_CATEGORIES_ADD_REQUEST';
export const TRUST_CATEGORIES_ADD_SUCCESS = 'TRUST_CATEGORIES_ADD_SUCCESS';
export const TRUST_CATEGORIES_ADD_FAILED = 'TRUST_CATEGORIES_ADD_FAILED';

export const TRUST_CATEGORIES_DELETE_REQUEST = 'TRUST_CATEGORIES_DELETE_REQUEST';
export const TRUST_CATEGORIES_DELETE_SUCCESS = 'TRUST_CATEGORIES_DELETE_SUCCESS';
export const TRUST_CATEGORIES_DELETE_FAILED = 'TRUST_CATEGORIES_DELETE_FAILED';

export const TRUST_CATEGORIES_SET_SELECTED = 'TRUST_CATEGORIES_SET_SELECTED';
export const TRUST_CATEGORY_DELETE_REQUEST = 'TRUST_CATEGORY_DELETE_REQUEST';
export const TRUST_CATEGORY_DELETE_SUCCESS = 'TRUST_CATEGORY_DELETE_SUCCESS';
export const TRUST_CATEGORY_DELETE_FAILED = 'TRUST_CATEGORY_DELETE_FAILED';

// Project Compliance
export const COMPLIANCE_ASSESSMENT_ADD_REQUEST = 'COMPLIANCE_ASSESSMENT_ADD_REQUEST';
export const COMPLIANCE_ASSESSMENT_ADD_SUCCESS = 'COMPLIANCE_ASSESSMENT_ADD_SUCCESS';
export const COMPLIANCE_ASSESSMENT_ADD_FAILED = 'COMPLIANCE_ASSESSMENT_ADD_FAILED';

export const COMPLIANCE_ASSESSMENT_GET_REQUEST = 'COMPLIANCE_ASSESSMENT_GET_REQUEST';
export const COMPLIANCE_ASSESSMENT_GET_SUCCESS = 'COMPLIANCE_ASSESSMENT_GET_SUCCESS';
export const COMPLIANCE_ASSESSMENT_GET_FAILED = 'COMPLIANCE_ASSESSMENT_GET_FAILED';

// Project Model Trust Questions Survey
export const MODEL_TRUST_QUESTIONS_SURVEY_COMPLETE_REQUEST = 'MODEL_TRUST_QUESTIONS_SURVEY_COMPLETE_REQUEST';
export const MODEL_TRUST_QUESTIONS_SURVEY_COMPLETE_SUCCESS = 'MODEL_TRUST_QUESTIONS_SURVEY_COMPLETE_SUCCESS';
export const MODEL_TRUST_QUESTIONS_SURVEY_COMPLETE_FAILED = 'MODEL_TRUST_QUESTIONS_SURVEY_COMPLETE_FAILED';

// Trust Tool Sandbox
export const TRUST_TOOL_SANDBOX_TYPES_GET_REQUEST = 'TRUST_TOOL_SANDBOX_TYPES_GET_REQUEST';
export const TRUST_TOOL_SANDBOX_TYPES_GET_SUCCESS = 'TRUST_TOOL_SANDBOX_TYPES_GET_SUCCESS';
export const TRUST_TOOL_SANDBOX_TYPES_GET_FAILED = 'TRUST_TOOL_SANDBOX_TYPES_GET_FAILED';

export const TRUST_TOOL_SANDBOX_SET_SELECTED_COMPONENT_TYPE = 'TRUST_TOOL_SANDBOX_SET_SELECTED_COMPONENT_TYPE';

export const TRUST_TOOL_SANDBOX_COMPONENT_INSTANCE_SYNOPSIS_GET_REQUEST = 'TRUST_TOOL_SANDBOX_COMPONENT_INSTANCE_SYNOPSIS_GET_REQUEST';
export const TRUST_TOOL_SANDBOX_COMPONENT_INSTANCE_SYNOPSIS_GET_SUCCESS = 'TRUST_TOOL_SANDBOX_COMPONENT_INSTANCE_SYNOPSIS_GET_SUCCESS';
export const TRUST_TOOL_SANDBOX_COMPONENT_INSTANCE_SYNOPSIS_GET_FAILED = 'TRUST_TOOL_SANDBOX_COMPONENT_INSTANCE_SYNOPSIS_GET_FAILED';

export const TRUST_TOOL_SANDBOX_COMPONENT_INSTANCE_GET_REQUEST = 'TRUST_TOOL_SANDBOX_COMPONENT_INSTANCE_GET_REQUEST';
export const TRUST_TOOL_SANDBOX_COMPONENT_INSTANCE_GET_SUCCESS = 'TRUST_TOOL_SANDBOX_COMPONENT_INSTANCE_GET_SUCCESS';
export const TRUST_TOOL_SANDBOX_COMPONENT_INSTANCE_GET_FAILED = 'TRUST_TOOL_SANDBOX_COMPONENT_INSTANCE_GET_FAILED';

export const TRUST_TOOL_SANDBOX_CREATE_INSTANCE_REQUEST = 'TRUST_TOOL_SANDBOX_CREATE_INSTANCE_REQUEST';
export const TRUST_TOOL_SANDBOX_CREATE_INSTANCE_SUCCESS = 'TRUST_TOOL_SANDBOX_CREATE_INSTANCE_SUCCESS';
export const TRUST_TOOL_SANDBOX_CREATE_INSTANCE_FAILED = 'TRUST_TOOL_SANDBOX_CREATE_INSTANCE_FAILED';

export const TRUST_TOOL_SANDBOX_UPDATE_INSTANCE_REQUEST = 'TRUST_TOOL_SANDBOX_UPDATE_INSTANCE_REQUEST';
export const TRUST_TOOL_SANDBOX_UPDATE_INSTANCE_SUCCESS = 'TRUST_TOOL_SANDBOX_UPDATE_INSTANCE_SUCCESS';
export const TRUST_TOOL_SANDBOX_UPDATE_INSTANCE_FAILED = 'TRUST_TOOL_SANDBOX_UPDATE_INSTANCE_FAILED';

export const TRUST_TOOL_SANDBOX_DELETE_INSTANCE_REQUEST = 'TRUST_TOOL_SANDBOX_DELETE_INSTANCE_REQUEST';
export const TRUST_TOOL_SANDBOX_DELETE_INSTANCE_SUCCESS = 'TRUST_TOOL_SANDBOX_DELETE_INSTANCE_SUCCESS';
export const TRUST_TOOL_SANDBOX_DELETE_INSTANCE_FAILED = 'TRUST_TOOL_SANDBOX_DELETE_INSTANCE_FAILED';

export const TRUST_TOOL_SANDBOX_NO_CONFIGURATION_NAME_ERROR = 'TRUST_TOOL_SANDBOX_NO_CONFIGURATION_NAME_ERROR';

export const TRUST_TOOL_SANDBOX_CLEAN_UP = 'TRUST_TOOL_SANDBOX_CLEAN_UP';

// Compliance Assessment
export const COMPLIANCE_ASSESSMENT_UPDATE_REQUEST = 'COMPLIANCE_ASSESSMENT_UPDATE_REQUEST';
export const COMPLIANCE_ASSESSMENT_UPDATE_SUCCESS = 'COMPLIANCE_ASSESSMENT_UPDATE_SUCCESS';
export const COMPLIANCE_ASSESSMENT_UPDATE_FAILED = 'COMPLIANCE_ASSESSMENT_UPDATE_FAILED';

export const COMPLIANCE_ASSESSMENT_ALREADY_PUBLISHED_ERROR = 'COMPLIANCE_ASSESSMENT_ALREADY_PUBLISHED_ERROR';

// Project questions background loading testing
export const INIT_LOAD_FOR_PROJECT_MODEL_TRUST_QUESTIONS_ALERT_AND_TOOL_SETUP = 'INIT_LOAD_FOR_PROJECT_MODEL_TRUST_QUESTIONS_ALERT_AND_TOOL_SETUP';

export const LOAD_PROJECT_MODEL_TRUST_QUESTION_BEGIN = 'LOAD_PROJECT_MODEL_TRUST_QUESTION_BEGIN';
export const LOAD_PROJECT_MODEL_TRUST_QUESTION_COMPLETE = 'LOAD_PROJECT_MODEL_TRUST_QUESTION_COMPLETE';
export const LOAD_PROJECT_MODEL_TRUST_QUESTION_FAIL = 'LOAD_PROJECT_MODEL_TRUST_QUESTION_FAIL';

// AI Governance Scorecard
export const AI_GOVERNANCE_SCORECARD_SET_SELECTED = 'AI_GOVERNANCE_SCORECARD_SET_SELECTED';

export const AI_GOVERNANCE_SCORECARD_GET_ALL_REQUEST = 'AI_GOVERNANCE_SCORECARD_GET_ALL_REQUEST';
export const AI_GOVERNANCE_SCORECARD_GET_ALL_SUCCESS = 'AI_GOVERNANCE_SCORECARD_GET_ALL_SUCCESS';
export const AI_GOVERNANCE_SCORECARD_GET_ALL_FAILED = 'AI_GOVERNANCE_SCORECARD_GET_ALL_FAILED';

export const AI_GOVERNANCE_SCORECARD_GET_REQUEST = 'AI_GOVERNANCE_SCORECARD_GET_REQUEST';
export const AI_GOVERNANCE_SCORECARD_GET_SUCCESS = 'AI_GOVERNANCE_SCORECARD_GET_SUCCESS';
export const AI_GOVERNANCE_SCORECARD_GET_FAILED = 'AI_GOVERNANCE_SCORECARD_GET_FAILED';

export const AI_GOVERNANCE_SCORECARD_ADD_REQUEST = 'AI_GOVERNANCE_SCORECARD_ADD_REQUEST';
export const AI_GOVERNANCE_SCORECARD_ADD_SUCCESS = 'AI_GOVERNANCE_SCORECARD_ADD_SUCCESS';
export const AI_GOVERNANCE_SCORECARD_ADD_FAILED = 'AI_GOVERNANCE_SCORECARD_ADD_FAILED';

export const AI_GOVERNANCE_SCORECARD_DELETE_REQUEST = 'AI_GOVERNANCE_SCORECARD_DELETE_REQUEST';
export const AI_GOVERNANCE_SCORECARD_DELETE_SUCCESS = 'AI_GOVERNANCE_SCORECARD_DELETE_SUCCESS';
export const AI_GOVERNANCE_SCORECARD_DELETE_FAILED = 'AI_GOVERNANCE_SCORECARD_DELETE_FAILED';

export const AI_GOVERNANCE_SCORECARD_UPDATE_REQUEST = 'AI_GOVERNANCE_SCORECARD_UPDATE_REQUEST';
export const AI_GOVERNANCE_SCORECARD_UPDATE_SUCCESS = 'AI_GOVERNANCE_SCORECARD_UPDATE_SUCCESS';
export const AI_GOVERNANCE_SCORECARD_UPDATE_FAILED = 'AI_GOVERNANCE_SCORECARD_UPDATE_FAILED';

export const AIGS_GET_REQ = 'AIGS_GET_REQ';
export const AIGS_GET_SUC = 'AIGS_GET_SUC';
export const AIGS_GET_FAIL = 'AIGS_GET_FAIL';

export const SCORECARD_SUBMIT_ASSESSMENT_REQ = 'SCORECARD_SUBMIT_ASSESSMENT_REQ';
export const SCORECARD_SUBMIT_ASSESSMENT_SUC = 'SCORECARD_SUBMIT_ASSESSMENT_SUC';
export const SCORECARD_SUBMIT_ASSESSMENT_FAIL = 'SCORECARD_SUBMIT_ASSESSMENT_FAIL';

export const AI_SCORECARD_DRAFT_UPDATE_SUCCESS = 'AI_SCORECARD_DRAFT_UPDATE_SUCCESS';

// AI Governance Scorecard Requirement
export const AI_GOVERNANCE_SCORECARD_REQUIREMENT_SET_SELECTED = 'AI_GOVERNANCE_SCORECARD_REQUIREMENT_SET_SELECTED';

export const AI_GOVERNANCE_SCORECARD_REQUIREMENT_GET_ALL_REQUEST = 'AI_GOVERNANCE_SCORECARD_REQUIREMENT_GET_ALL_REQUEST';
export const AI_GOVERNANCE_SCORECARD_REQUIREMENT_GET_ALL_SUCCESS = 'AI_GOVERNANCE_SCORECARD_REQUIREMENT_GET_ALL_SUCCESS';
export const AI_GOVERNANCE_SCORECARD_REQUIREMENT_GET_ALL_FAILED = 'AI_GOVERNANCE_SCORECARD_REQUIREMENT_GET_ALL_FAILED';

export const AI_GOVERNANCE_SCORECARD_REQUIREMENT_GET_REQUEST = 'AI_GOVERNANCE_SCORECARD_REQUIREMENT_GET_REQUEST';
export const AI_GOVERNANCE_SCORECARD_REQUIREMENT_GET_SUCCESS = 'AI_GOVERNANCE_SCORECARD_REQUIREMENT_GET_SUCCESS';
export const AI_GOVERNANCE_SCORECARD_REQUIREMENT_GET_FAILED = 'AI_GOVERNANCE_SCORECARD_REQUIREMENT_GET_FAILED';

export const AI_GOVERNANCE_SCORECARD_REQUIREMENT_ADD_REQUEST = 'AI_GOVERNANCE_SCORECARD_REQUIREMENT_ADD_REQUEST';
export const AI_GOVERNANCE_SCORECARD_REQUIREMENT_ADD_SUCCESS = 'AI_GOVERNANCE_SCORECARD_REQUIREMENT_ADD_SUCCESS';
export const AI_GOVERNANCE_SCORECARD_REQUIREMENT_ADD_FAILED = 'AI_GOVERNANCE_SCORECARD_REQUIREMENT_ADD_FAILED';

export const AI_GOVERNANCE_SCORECARD_REQUIREMENT_DELETE_REQUEST = 'AI_GOVERNANCE_SCORECARD_REQUIREMENT_DELETE_REQUEST';
export const AI_GOVERNANCE_SCORECARD_REQUIREMENT_DELETE_SUCCESS = 'AI_GOVERNANCE_SCORECARD_REQUIREMENT_DELETE_SUCCESS';
export const AI_GOVERNANCE_SCORECARD_REQUIREMENT_DELETE_FAILED = 'AI_GOVERNANCE_SCORECARD_REQUIREMENT_DELETE_FAILED';

export const AI_GOVERNANCE_SCORECARD_REQUIREMENT_UPDATE_REQUEST = 'AI_GOVERNANCE_SCORECARD_REQUIREMENT_UPDATE_REQUEST';
export const AI_GOVERNANCE_SCORECARD_REQUIREMENT_UPDATE_SUCCESS = 'AI_GOVERNANCE_SCORECARD_REQUIREMENT_UPDATE_SUCCESS';
export const AI_GOVERNANCE_SCORECARD_REQUIREMENT_UPDATE_FAILED = 'AI_GOVERNANCE_SCORECARD_REQUIREMENT_UPDATE_FAILED';

// AI Governance Scorecard Question
export const AI_GOVERNANCE_SCORECARD_QUESTION_SET_SELECTED = 'AI_GOVERNANCE_SCORECARD_QUESTION_SET_SELECTED';

export const AI_GOVERNANCE_SCORECARD_QUESTION_GET_ALL_REQUEST = 'AI_GOVERNANCE_SCORECARD_QUESTION_GET_ALL_REQUEST';
export const AI_GOVERNANCE_SCORECARD_QUESTION_GET_ALL_SUCCESS = 'AI_GOVERNANCE_SCORECARD_QUESTION_GET_ALL_SUCCESS';
export const AI_GOVERNANCE_SCORECARD_QUESTION_GET_ALL_FAILED = 'AI_GOVERNANCE_SCORECARD_QUESTION_GET_ALL_FAILED';

export const AI_GOVERNANCE_SCORECARD_QUESTION_GET_REQUEST = 'AI_GOVERNANCE_SCORECARD_QUESTION_GET_REQUEST';
export const AI_GOVERNANCE_SCORECARD_QUESTION_GET_SUCCESS = 'AI_GOVERNANCE_SCORECARD_QUESTION_GET_SUCCESS';
export const AI_GOVERNANCE_SCORECARD_QUESTION_GET_FAILED = 'AI_GOVERNANCE_SCORECARD_QUESTION_GET_FAILED';

export const AI_GOVERNANCE_SCORECARD_QUESTION_ADD_REQUEST = 'AI_GOVERNANCE_SCORECARD_QUESTION_ADD_REQUEST';
export const AI_GOVERNANCE_SCORECARD_QUESTION_ADD_SUCCESS = 'AI_GOVERNANCE_SCORECARD_QUESTION_ADD_SUCCESS';
export const AI_GOVERNANCE_SCORECARD_QUESTION_ADD_FAILED = 'AI_GOVERNANCE_SCORECARD_QUESTION_ADD_FAILED';

export const AI_GOVERNANCE_SCORECARD_QUESTION_DELETE_REQUEST = 'AI_GOVERNANCE_SCORECARD_QUESTION_DELETE_REQUEST';
export const AI_GOVERNANCE_SCORECARD_QUESTION_DELETE_SUCCESS = 'AI_GOVERNANCE_SCORECARD_QUESTION_DELETE_SUCCESS';
export const AI_GOVERNANCE_SCORECARD_QUESTION_DELETE_FAILED = 'AI_GOVERNANCE_SCORECARD_QUESTION_DELETE_FAILED';

export const AI_GOVERNANCE_SCORECARD_QUESTION_UPDATE_REQUEST = 'AI_GOVERNANCE_SCORECARD_QUESTION_UPDATE_REQUEST';
export const AI_GOVERNANCE_SCORECARD_QUESTION_UPDATE_SUCCESS = 'AI_GOVERNANCE_SCORECARD_QUESTION_UPDATE_SUCCESS';
export const AI_GOVERNANCE_SCORECARD_QUESTION_UPDATE_FAILED = 'AI_GOVERNANCE_SCORECARD_QUESTION_UPDATE_FAILED';

// AIGS Requirement
export const AIGS_REQUIREMENT_SET_SELECTED = 'AIGS_REQUIREMENT_SET_SELECTED';

export const AIGS_REQUIREMENT_GET_ALL_REQUEST = 'AIGS_REQUIREMENT_GET_ALL_REQUEST';
export const AIGS_REQUIREMENT_GET_ALL_SUCCESS = 'AIGS_REQUIREMENT_GET_ALL_SUCCESS';
export const AIGS_REQUIREMENT_GET_ALL_FAILED = 'AIGS_REQUIREMENT_GET_ALL_FAILED';

export const AIGS_REQUIREMENT_GET_REQUEST = 'AIGS_REQUIREMENT_GET_REQUEST';
export const AIGS_REQUIREMENT_GET_SUCCESS = 'AIGS_REQUIREMENT_GET_SUCCESS';
export const AIGS_REQUIREMENT_GET_FAILED = 'AIGS_REQUIREMENT_GET_FAILED';

export const AIGS_REQUIREMENT_ADD_REQUEST = 'AIGS_REQUIREMENT_ADD_REQUEST';
export const AIGS_REQUIREMENT_ADD_SUCCESS = 'AIGS_REQUIREMENT_ADD_SUCCESS';
export const AIGS_REQUIREMENT_ADD_FAILED = 'AIGS_REQUIREMENT_ADD_FAILED';

export const AIGS_REQUIREMENT_DELETE_REQUEST = 'AIGS_REQUIREMENT_DELETE_REQUEST';
export const AIGS_REQUIREMENT_DELETE_SUCCESS = 'AIGS_REQUIREMENT_DELETE_SUCCESS';
export const AIGS_REQUIREMENT_DELETE_FAILED = 'AIGS_REQUIREMENT_DELETE_FAILED';

export const AIGS_REQUIREMENT_UPDATE_REQUEST = 'AIGS_REQUIREMENT_UPDATE_REQUEST';
export const AIGS_REQUIREMENT_UPDATE_SUCCESS = 'AIGS_REQUIREMENT_UPDATE_SUCCESS';
export const AIGS_REQUIREMENT_UPDATE_FAILED = 'AIGS_REQUIREMENT_UPDATE_FAILED';

// AIGS Question
export const AIGS_QUESTION_SET_SELECTED = 'AIGS_QUESTION_SET_SELECTED';

export const AIGS_QUESTION_GET_ALL_REQUEST = 'AIGS_QUESTION_GET_ALL_REQUEST';
export const AIGS_QUESTION_GET_ALL_SUCCESS = 'AIGS_QUESTION_GET_ALL_SUCCESS';
export const AIGS_QUESTION_GET_ALL_FAILED = 'AIGS_QUESTION_GET_ALL_FAILED';

export const AIGS_QUESTION_GET_REQUEST = 'AIGS_QUESTION_GET_REQUEST';
export const AIGS_QUESTION_GET_SUCCESS = 'AIGS_QUESTION_GET_SUCCESS';
export const AIGS_QUESTION_GET_FAILED = 'AIGS_QUESTION_GET_FAILED';

export const AIGS_QUESTION_ADD_REQUEST = 'AIGS_QUESTION_ADD_REQUEST';
export const AIGS_QUESTION_ADD_SUCCESS = 'AIGS_QUESTION_ADD_SUCCESS';
export const AIGS_QUESTION_ADD_FAILED = 'AIGS_QUESTION_ADD_FAILED';

export const AIGS_QUESTION_DELETE_REQUEST = 'AIGS_QUESTION_DELETE_REQUEST';
export const AIGS_QUESTION_DELETE_SUCCESS = 'AIGS_QUESTION_DELETE_SUCCESS';
export const AIGS_QUESTION_DELETE_FAILED = 'AIGS_QUESTION_DELETE_FAILED';

export const AIGS_QUESTION_UPDATE_REQUEST = 'AIGS_QUESTION_UPDATE_REQUEST';
export const AIGS_QUESTION_UPDATE_SUCCESS = 'AIGS_QUESTION_UPDATE_SUCCESS';
export const AIGS_QUESTION_UPDATE_FAILED = 'AIGS_QUESTION_UPDATE_FAILED';

export const AIGS_SCORECARD_SCORE_SUBMIT_REQUEST = 'AIGS_SCORECARD_SCORE_ADD_REQUEST';
export const AIGS_SCORECARD_SCORE_SUBMIT_SUCCESS = 'AIGS_SCORECARD_SCORE_ADD_SUCCESS';
export const AIGS_SCORECARD_SCORE_SUBMIT_FAILED = 'AIGS_SCORECARD_SCORE_ADD_FAILED';

export const AIGS_SCORECARD_SCORE_GET_ALL_REQUEST = 'AIGS_SCORECARD_SCORE_GET_ALL_REQUEST';
export const AIGS_SCORECARD_SCORE_GET_ALL_SUCCESS = 'AIGS_SCORECARD_SCORE_GET_ALL_SUCCESS';
export const AIGS_SCORECARD_SCORE_GET_ALL_FAILED = 'AIGS_SCORECARD_SCORE_GET_ALL_FAILED';

export const AIGS_SCORECARD_SCORE_UPDATE_REQ = 'AIGS_SCORECARD_SCORE_UPDATE_REQ';
export const AIGS_SCORECARD_SCORE_UPDATE_SUC = 'AIGS_SCORECARD_SCORE_UPDATE_SUC';
export const AIGS_SCORECARD_SCORE_UPDATE_FAIL = 'AIGS_SCORECARD_SCORE_UPDATE_FAIL';

export const AIGS_SCORECARD_SCORE_DELETE_REQ = 'AIGS_SCORECARD_SCORE_DELETE_REQ';
export const AIGS_SCORECARD_SCORE_DELETE_SUC = 'AIGS_SCORECARD_SCORE_DELETE_SUC';
export const AIGS_SCORECARD_SCORE_DELETE_FAIL = 'AIGS_SCORECARD_SCORE_DELETE_FAIL';

export const AIGS_SCORECARD_SCORE_SET_SELECTED = 'AIGS_SCORECARD_SCORE_SET_SELECTED';

// Tutorial Actions
export const SET_TUTORIAL_STATE = 'SET_TUTORIAL_STATE';

// User
export const UPDATE_USER_CONSENT_REQUEST = 'UPDATE_USER_CONSENT_REQUEST';
export const UPDATE_USER_CONSENT_SUCCESS = 'UPDATE_USER_CONSENT_SUCCESS';
export const UPDATE_USER_CONSENT_FAILED = 'UPDATE_USER_CONSENT_FAILED';

//benchmark
export const BENCHMARK_GET_REQUEST = 'GET_BENCHMARK_REQUEST';
export const BENCHMARK_GET_REQUEST_SUCCESS = 'GET_BENCHMARK_REQUEST_SUCCESS';
export const BENCHMARK_GET_REQUEST_FAILED = 'GET_BENCHMARK_REQUEST_FAILED';
export const BENCHMARK_CLEAR = 'BENCHMARK_CLEAR';
