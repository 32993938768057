import React, { useEffect, useState } from 'react';
import { Route, Switch, HashRouter as Router } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import MainLayout from '../layout';
import Error from '../components/error/Error';
import { LoadingPage, ConfirmAction } from '../components/overlays';
import { authenticateUser, logout, getUserFromAuth } from '../redux/actions';
import ConsentForm from './ConsentForm';

const Auth = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [actionConfig, setActionConfig] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      dispatch(
        authenticateUser((getState) => {
          // Make sure the user has been assigned at least one role
          const { user } = getState();
          const { roles } = user || { roles: [] };

          if (user && (!roles || roles.length === 0)) {
            // This case should no longer happen
            // TODO: Add default Role instead
            setActionConfig({
              messageCodes: [],
              actionPrincipal: t('user.no.roles.error'),
              actionButtonLabelCode: 'Logout',
              actionButtonClass: 'btn-delete',
              action: () => dispatch(logout()),
            });
          } else if (user && !user.organization) {
            dispatch(
              getUserFromAuth(user, () => {
                setActionConfig({
                  messageCodes: [],
                  actionPrincipal: t('user.no.organization.error'),
                  actionButtonLabelCode: 'Logout',
                  actionButtonClass: 'btn-delete',
                  action: () => dispatch(logout()),
                });
              }),
            );
          }
          // Clear up the query string parameters in the browser history and load the application
          window.history.pushState(null, null, '/');
        }),
      );
    }, 300);
  }, [dispatch, t]);

  const user = useSelector((state) => state.user);
  const { roles } = user || { roles: [] };

  return (
    <>
      {user && roles.length > 0 && user.organization && user.organization.uuid ? (
        <Router>
          <Switch>
            <Route path="/error" exact component={Error} />
            <Route path="/privacy_terms_and_conditions" exact component={ConsentForm} />
            <Route path="/" component={MainLayout} />
          </Switch>
        </Router>
      ) : (
        <>
          <ConfirmAction config={actionConfig} hideCancel />
          <div className=" auth-background">
            {/* <NuenergyNueralLines width='100%' height='100%'/> */}
            <LoadingPage />
          </div>
        </>
      )}
    </>
  );
};

export default Auth;
