/*
 * File: Questionnaire.tutorial.tsx
 * Project: Machine Trust Platform
 * File Created: Thursday, 22nd July 2021 11:33:10 am
 * Author: Rafal Rybak (rafal.rybak@nuenergy.ai)
 * -----
 * Last Modified: Thursday, 26th August 2021 3:53:15 pm
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomTour from '../CustomTour';
import Tutorial from '../TutorialContainer';

const CanvasTutorial = (): JSX.Element => {
  const STEPS = [
    {
      selector: '[data-tut="canvas"]',
      content: () => <StepOne />,
    },
    {
      selector: '[data-tut="save-canvas"]',
      content: () => <Tutorial header="Save PDF">Once all required questions have been filled out you may save the Canvas as a PDF </Tutorial>,
      action: () => {},
    },
  ];
  return <CustomTour steps={STEPS} update={document.getElementById('main')?.scrollTop} />;
};

export default CanvasTutorial;

const StepOne = () => {
  const { t } = useTranslation();

  return <Tutorial header={t('canvas.questionnaire.tutorial.label')}>{t('canvas.questionnaire.tutorial.step.1')}</Tutorial>;
};
