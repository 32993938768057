/* eslint-disable */
import { batch } from 'react-redux';
import {
  AUTH_AUTHENTICATE_USER_REQUEST,
  AUTH_RETRIEVE_CACHED_TOKENS_REQUEST,
  AUTH_RETRIEVE_CACHED_TOKENS_SUCCESS,
  AUTH_RETRIEVE_CACHED_TOKENS_FAILED,
  AUTH_EXCHANGE_CODE_FOR_TOKENS_REQUEST,
  AUTH_EXCHANGE_CODE_FOR_TOKENS_SUCCESS,
  AUTH_EXCHANGE_CODE_FOR_TOKENS_FAILED,
  AUTH_REFRESH_TOKENS_REQUEST,
  AUTH_REFRESH_TOKENS_SUCCESS,
  AUTH_REFRESH_TOKENS_FAILED,
  AUTH_SCHEDULE_FORCED_LOGOUT,
  AUTH_LOGOUT,
  AUTH_GET_USER_ORGANIZATION_REQUEST,
  AUTH_GET_USER_ORGANIZATION_SUCCESS,
  AUTH_GET_USER_ORGANIZATION_FAILED,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILED,
  UPDATE_USER_CONSENT_SUCCESS,
  SET_TUTORIAL_STATE,
} from './action-types';
import * as auth from '../../utils/localStorage/authLocalStorage';
import { PENDING_LOGOUT_TIMEOUT } from '../../constants';
import config from '../../config.json';

import client from '../../utils/api/client-helper';

// import { reportNamespaces } from 'i18next';

export const authenticateUser = (authCallback) => {
  return { type: AUTH_AUTHENTICATE_USER_REQUEST, authCallback };
};

export const getUserFromAuth = (user, authCallback = null) => {
  return async (dispatch, getState) => {
    dispatch({ type: AUTH_GET_USER_ORGANIZATION_REQUEST });

    if (user && !user.username) throw Error('User must contain valid username.');
    const idToken = getState().authTokens.id_token;

    const config = {
      idToken,
      user,
    };

    return client('/user', config)
      .then((response) => {
        // console.log(response.data)
        batch(() => {
          dispatch({
            type: AUTH_GET_USER_ORGANIZATION_SUCCESS,
            user: response.data,
          });
          // When the user signs in for the first time the tutorial will be set to true
          if (response.data.tutorial)
            dispatch({
              type: SET_TUTORIAL_STATE,
              isTutorialOpen: response.data.tutorial,
            });
        });
      })
      .catch((error) => {
        console.error(error);
        if (authCallback) authCallback();
        dispatch({ type: AUTH_GET_USER_ORGANIZATION_FAILED });
      });
  };
};

export const getUserWithUsername = (username, authCallback = null) => {
  return getUserFromAuth({ username }, authCallback);
};

export const updateUser = (userData) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_USER_REQUEST });
    // const { user } = getState();

    const idToken = getState().authTokens.id_token;
    const config = {
      idToken,
      // user,
      data: userData,
    };

    return client('/user', config)
      .then((response) => {
        dispatch({ type: UPDATE_USER_SUCCESS, user: response.data });
      })
      .catch((error) => {
        console.error(error);
        dispatch({ type: UPDATE_USER_FAILED });
      });
  };
};

export const updateUserConsent = (state) => {
  // console.log(2)
  return async (dispatch, getState) => {
    // dispatch({ type: UPDATE_USER_CONSENT_REQUEST })
    // const { user } = getState();

    const idToken = getState().authTokens.id_token;
    const config = {
      idToken,
      // user,
      data: { state },
    };

    return client('/privacy_terms_and_conditions', config)
      .then((response) => {
        dispatch({
          type: UPDATE_USER_CONSENT_SUCCESS,
          privacyTermsConditions: response.data,
        });
      })
      .catch((error) => {
        console.error(error);
        // dispatch({ type: UPDATE_USER_CONSENT_FAILED })
      });
  };
};

export const retrieveCachedTokens = () => {
  return (dispatch) => {
    dispatch({ type: AUTH_RETRIEVE_CACHED_TOKENS_REQUEST });

    const authTokens = auth.getCachedTokens();

    if (authTokens && authTokens.id_token && authTokens.access_token && authTokens.refresh_token) {
      dispatch({ type: AUTH_RETRIEVE_CACHED_TOKENS_SUCCESS, authTokens });
    } else {
      const error = 'No Cached Tokens Found';
      dispatch({ type: AUTH_RETRIEVE_CACHED_TOKENS_FAILED, error });
    }
  };
};

export const exchangeCodeForTokens = (code) => {
  return (dispatch, getState) => {
    dispatch({ type: AUTH_EXCHANGE_CODE_FOR_TOKENS_REQUEST, code });

    return fetch(`https://${config.auth_domain}/oauth2/token`, {
      method: 'POST',
      body: `grant_type=authorization_code&` + `client_id=${process.env.REACT_APP_CLIENT_ID}&` + `code=${code}&` + `redirect_uri=${window.location.protocol}//${window.location.hostname}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then(
        (response) => response.json(),
        (error) => {
          throw error;
        },
      )
      .then((authTokens) => {
        if (authTokens.error) {
          throw authTokens.error;
        }
        dispatch({ type: AUTH_EXCHANGE_CODE_FOR_TOKENS_SUCCESS, authTokens });
      })
      .catch((error) => {
        dispatch({ type: AUTH_EXCHANGE_CODE_FOR_TOKENS_FAILED, error });
        throw error;
      });
  };
};

export const refreshTokens = () => {
  return (dispatch, getState) => {
    dispatch({ type: AUTH_REFRESH_TOKENS_REQUEST });

    const { authTokens } = getState();

    return fetch(`https://${config.auth_domain}/oauth2/token`, {
      method: 'POST',
      body: `grant_type=refresh_token&` + `client_id=${process.env.REACT_APP_CLIENT_ID}&` + `refresh_token=${authTokens.refresh_token}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then(
        (response) => response.json(),
        (error) => {
          throw error;
        },
      )
      .then((responseAuthTokens) => {
        if (responseAuthTokens.error) {
          throw responseAuthTokens.error;
        }
        dispatch({
          type: AUTH_REFRESH_TOKENS_SUCCESS,
          authTokens: responseAuthTokens,
        });
      })
      .catch((error) => {
        dispatch({ type: AUTH_REFRESH_TOKENS_FAILED, error });
        throw error;
      });
  };
};

export const logout = () => {
  return { type: AUTH_LOGOUT };
};

export const scheduleForcedLogout = (logoutTime) => {
  const forcedLogoutTime = logoutTime ? logoutTime : new Date().getTime() + PENDING_LOGOUT_TIMEOUT;
  return { type: AUTH_SCHEDULE_FORCED_LOGOUT, forcedLogoutTime };
};

export const cancelForcedLogout = () => {
  const forcedLogoutTime = null;
  return { type: AUTH_SCHEDULE_FORCED_LOGOUT, forcedLogoutTime };
};
